import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreate,
  useNotify,
  SimpleForm,
  Create,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
  minLength,
  DateInput,
  TextInput,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import OptionReferer from "../common/OptionReferer";
import PriceItemForm from "./components/PriceItemForm";
import CodePickers from "../common/CodePickers";

const greaterThan = (attr) => (value, allValues) => {
  if (!allValues[attr] || allValues[attr] === "0001-01-01T00:00:00Z")
    return undefined;
  if (parseFloat(value) < parseFloat(allValues[attr])) {
    return "Must be greater than " + allValues[attr];
  }
  return undefined;
};
const lessThan = (attr) => (value, allValues) => {
  if (!allValues[attr] || allValues[attr] === "0001-01-01T00:00:00Z")
    return undefined;
  if (parseFloat(value) > parseFloat(allValues[attr])) {
    return "Must be less than " + allValues[attr];
  }
  return undefined;
};

const validateCode = [maxLength(10)];
const validateStartDate = [
  required(),
  minLength(10),
  maxLength(10),
  lessThan("end_date"),
];
const validateEndDate = [
  required(),
  minLength(10),
  maxLength(10),
  greaterThan("start_date"),
];

export default function PriceAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const t = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        } else if (error.body.message) {
          notify(error.body.message, {
            type: "error",
          });
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{t("mine.dialog.create_price")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput
              variant="outlined"
              source="code"
              label="mine.label.code"
              fullWidth
              resettable
              validate={validateCode}
              helperText={t("mine.label.10_chars")}
            />

            <CodePickers dependOn="code" />

            {/* <OptionReferer
              source="kind_id"
              reference="kinds"
              label="mine.label.kind"
              keyAttr="created_at"
            /> */}

            <Grid
              container
              spacing={2}
              sx={{
                "& .MuiGrid-item": { pt: 0 },
                mt: 0,
              }}
            >
              <Grid item xs={12} lg={6}>
                <DateInput
                  variant="outlined"
                  source="start_date"
                  label="mine.label.start_date"
                  fullWidth
                  validate={validateStartDate}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DateInput
                  variant="outlined"
                  source="end_date"
                  label="mine.label.end_date"
                  fullWidth
                  validate={validateEndDate}
                />
              </Grid>
            </Grid>

            <PriceItemForm />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
