import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BooleanField,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import SizeAddDialog from "../../components/sizes/create/SizeAddDialog";
import SizeEditDialog from "../../components/sizes/edit/SizeEditDialog";
import sizeFilterList from "../../components/sizes/filter/sizeFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import SizeUploadDialog from "../../components/sizes/upload/SizeUploadDialog";

const SizeList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={sizeFilterList}
        queryOptions={{ meta: { sort: ["code ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          <TextField source="name" label="mine.label.name" />
          <TextField source="code" label="mine.label.code" />
          <NumberField source="d1" />
          <NumberField source="d2" />
          <BooleanField
            source="active"
            label="mine.label.active"
            textAlign="center"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <SizeAddDialog open={openAdd} setOpen={setOpenAdd} />
      <SizeEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      <SizeUploadDialog open={openUpload} setOpen={setOpenUpload} />
    </>
  );
};

export default SizeList;
