import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  FunctionField,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import PriceLimitAddDialog from "../../components/priceLimits/create/PriceLimitAddDialog";
import PriceLimitEditDialog from "../../components/priceLimits/edit/PriceLimitEditDialog";
import priceLimitFilterList from "../../components/priceLimits/filter/priceLimitFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";

const PriceLimitList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={priceLimitFilterList}
        queryOptions={{ meta: { sort: ["price_limits.created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          <FunctionField
            label="resources.customer_type.name"
            render={(record) =>
              `${record.customer_type.name} (${record.customer_type.code})`
            }
          />
          <NumberField source="price" label="Limit" />
          <TextField source="note" label="mine.label.note" />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      {openAdd ? (
        <PriceLimitAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <PriceLimitEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default PriceLimitList;
