import {
  ChipField,
  RecordContextProvider,
  useRecordContext,
  useTranslate,
} from "react-admin";

export const SaleOptionRenderer = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <span
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span>{record.code}</span>
      <RecordContextProvider
        value={{
          is_settled:
            // cek dulu customer_debt nya ada atau tidak
            // karena SJ gratis customer_debt nya null
            record.customer_debt && record.customer_debt.settled_date
              ? t("mine.label.settled")
              : t("mine.label.unsettled"),
        }}
      >
        <ChipField
          source="is_settled"
          color={
            record.customer_debt && record.customer_debt.settled_date
              ? "success"
              : "error"
          }
          size="small"
        />
      </RecordContextProvider>
    </span>
  );
};
export const saleInputText = (choice) => choice.code;
export const saleMatchSuggestion = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return choice.code.toLowerCase().includes(filter.toLowerCase());
};
export const saleFilterToQuery = (searchText) => ({ all: searchText });
