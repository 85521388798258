import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useCreate,
  useNotify,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  NumberInput,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  useDataProvider,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import CityInput from "../../common/CityInput";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const validateCode = [required(), maxLength(5)];
const validateName = [required(), maxLength(125)];
const validateProvince = [required(), number()];
const validateAddress = [required(), maxLength(125)];
const validatePostalCode = [number()];
const validatePhone = [maxLength(20)];
const validateNPWP = [maxLength(30)];
const validatePIC = [required(), maxLength(50)];
const validateNote = [maxLength(255)];

export default function SupplierAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const dataProvider = useDataProvider();

  // GET PROVINCES LISTS
  const {
    data: provinces,
    isLoading: isLoadingProv,
    error: provinceError,
  } = useQuery(["provinces", "provinceList"], () =>
    dataProvider.provinceList("provinces")
  );
  // GET PROVINCES LISTS

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        } else {
          notify("mine.notification." + error.message.split(" ").join("_"), {
            type: "error",
          });
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>Create Supplier</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput
              variant="outlined"
              source="code"
              fullWidth
              isRequired
              validate={validateCode}
            />

            <TextInput
              variant="outlined"
              source="name"
              fullWidth
              isRequired
              validate={validateName}
            />

            <SelectInput
              variant="outlined"
              source="province_id"
              isRequired
              choices={isLoadingProv ? [] : provinces}
              fullWidth
              validate={validateProvince}
            />

            <CityInput
              variant="outlined"
              source="city_id"
              isRequired
              fullWidth
              validate={validateProvince}
            />

            <TextInput
              variant="outlined"
              source="address"
              fullWidth
              isRequired
              validate={validateAddress}
              multiline
              minRows={3}
              maxRows={3}
            />

            <NumberInput
              variant="outlined"
              source="postal_code"
              fullWidth
              validate={validatePostalCode}
            />

            <TextInput
              variant="outlined"
              label="Phone 1"
              source="phone1"
              fullWidth
              validate={validatePhone}
            />
            <TextInput
              variant="outlined"
              label="Phone 2"
              source="phone2"
              fullWidth
              validate={validatePhone}
            />
            <TextInput
              variant="outlined"
              label="Phone 3"
              source="phone3"
              fullWidth
              validate={validatePhone}
            />
            <TextInput
              variant="outlined"
              source="fax"
              fullWidth
              validate={validatePhone}
            />

            <TextInput
              variant="outlined"
              label="NPWP"
              source="npwp"
              fullWidth
              validate={validateNPWP}
            />

            <TextInput
              variant="outlined"
              label="Person in Charge"
              source="pic"
              fullWidth
              isRequired
              validate={validatePIC}
            />

            <TextInput
              variant="outlined"
              source="credit_limit"
              fullWidth
              validate={validatePostalCode}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              {...parserFormatNumInput}
            />
            <NumberInput
              variant="outlined"
              source="time_period"
              fullWidth
              validate={validatePostalCode}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">day(s)</InputAdornment>
                ),
              }}
            />

            <TextInput
              variant="outlined"
              source="initial_balance"
              fullWidth
              validate={validatePostalCode}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              {...parserFormatNumInput}
            />

            <TextInput
              variant="outlined"
              source="note"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              validate={validateNote}
            />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
