import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  DateField,
  NumberField,
  useTheme,
  usePermissions,
  FunctionField,
  RecordContextProvider,
} from "react-admin";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import SaleNetCardExpandList from "../../components/saleNetCards/expand/SaleNetCardExpandList";
import SaleReturnCardList from "../saleReturnCards/SaleReturnCardList";
import SaleNetCardAsideList from "../../components/saleNetCards/aside/SaleNetCardAsideList";
import saleNetCardFilterList from "../../components/saleNetCards/filter/saleNetCardFilterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const SaleNetCardList = (props) => {
  const { permissions } = usePermissions();
  const [theme] = useTheme();

  const list = useMemo(() => {
    return (
      <>
        <List
          storeKey={false}
          title={props.title ? props.title : undefined}
          empty={false}
          aside={
            <SaleNetCardAsideList
              name="TOTAL PENJUALAN"
              filter={{ approved: 1 }}
            />
          }
          actions={<DownloadAction />}
          filters={saleNetCardFilterList(permissions)}
          filter={{
            approved: 1,
          }}
          filterDefaultValues={{
            start_date: firstDate,
            end_date: lastDate,
            approved: 1,
          }}
          queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
          sx={{
            flexDirection: "column",
          }}
        >
          <DatagridConfigurable
            bulkActionButtons={false}
            expand={<SaleNetCardExpandList />}
            sx={{
              "& .RaDatagrid-expandedPanel > td": {
                p: 0,
                bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
              },
            }}
          >
            {permissions && permissions.update_company ? (
              <TextField label="mine.label.company" source="company.alias" />
            ) : null}
            <TextField source="code" label="mine.label.code" />
            <FunctionField
              label="mine.label.order_no"
              render={(record) =>
                record.sale_items[0].sale_order_item.sale_order.code
              }
            />
            <DateField source="date" label="mine.label.date" />
            <TextField
              source="sale_items[0].sale_order_item.sale_order.customer.name"
              label="mine.label.customer"
              emptyText="-"
            />
            <TextField
              source="sale_items[0].sale_order_item.sale_order.salesman.name"
              label="Salesman"
              emptyText="-"
            />
            <FunctionField
              label="Transport"
              render={(record) => `${record.transport.code}`}
            />
            <TextField
              source="promotion.name"
              label="mine.label.promotion"
              emptyText="-"
            />
            <FunctionField
              textAlign="right"
              label="Box"
              render={(record) => {
                let rec = { total_sent: 0 };
                for (let i = 0; i < record.sale_items.length; i++) {
                  rec.total_sent += +record.sale_items[i].sent_quantity;
                }
                return (
                  <RecordContextProvider value={rec}>
                    <NumberField source="total_sent" />
                  </RecordContextProvider>
                );
              }}
            />
            <NumberField source="total" label="mine.label.value" />
            <TextField source="note" label="mine.label.note" emptyText="-" />
          </DatagridConfigurable>
        </List>
        <div style={{ margin: "22px 0 8px 0" }} /> {/* ================ */}
        <SaleReturnCardList
          withActions={false}
          withFilters={false}
          grandTotalResource="sale-net-cards"
          title=" "
        />
      </>
    );
  }, [theme]);
  return list;
};

export default SaleNetCardList;
