import {
  BooleanInput,
  DateInput,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const userFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput source="first_name" resettable />,
  <TextInput source="last_name" resettable />,
  <SelectInput
    source="gender"
    choices={[
      { id: "M", name: "Male" },
      { id: "F", name: "Female" },
    ]}
  />,
  <TextInput source="username" resettable />,
  <TextInput source="email" resettable />,
  <NumberInput source="phone" />,
  <BooleanInput source="active" />,
  <DateInput source="created_at" />,
];

export default userFilterList;
