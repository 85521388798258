import { useState } from "react";
import {
  Button,
  Confirm,
  useNotify,
  useRefresh,
  useTranslate,
  useUpdateMany,
  useUnselectAll,
  useResourceContext,
} from "react-admin";

const BulkConfirmBtn = ({
  title,
  content,
  dataBody,
  urlPath,
  selectedIds,
  hide,
  confirmProps = {},
  ...props
}) => {
  const resource = useResourceContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const unselectAll = useUnselectAll(resource);
  const [updateMany, { isLoading }] = useUpdateMany();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany(
      resource + urlPath,
      { data: { ids: selectedIds } },
      {
        onSuccess: () => {
          notify("ra.notification.updated", {
            type: "info",
            messageArgs: { smart_count: 1 },
          });
          unselectAll();
          refresh();
        },
        onError: (error) => {
          if (
            error.message.startsWith("server") ||
            error.message.startsWith("ra.notification")
          ) {
            notify(error.message, {
              type: "error",
            });
          } else {
            if (error.message.includes("*")) {
              const messArg = error.message.split("*");
              notify("server." + messArg[0].split(" ").join("_"), {
                messageArgs: { code: messArg[1] },
                type: "error",
              });
            } else {
              notify(
                "mine.notification." + error.message.split(" ").join("_"),
                {
                  type: "error",
                }
              );
            }
          }
          refresh();
        },
        onSettled: () => {
          setOpen(false);
        },
      }
    );
  };

  return (
    hide && (
      <>
        <Button onClick={handleClick} disabled={isLoading} {...props} />
        <Confirm
          isOpen={open}
          loading={isLoading}
          title={translate(title)}
          content={content}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
          {...confirmProps}
        />
      </>
    )
  );
};

export default BulkConfirmBtn;
