import { memo, useEffect } from "react";
import { TextInput, useDataProvider, useResourceContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useMutation } from "react-query";

const CodeNumberInput = ({ open, dependOn, ...props }) => {
  const dataProvider = useDataProvider();
  const date = useWatch({ name: dependOn });
  const resource = useResourceContext();
  const { setValue, getValues } = useFormContext();
  // GET NEXT NUMBER
  const { data, mutate, isLoading, error } = useMutation((dt) => {
    let type = getValues("type");
    if (type) {
      type = "&type=" + type;
    } else {
      type = "";
    }
    return dataProvider.getListNoParams(
      resource + "/next-number?date=" + dt + type
    );
  });
  // GET NEXT NUMBER

  useEffect(() => {
    if (open) {
      mutate(date);
    }
  }, [open, date]);
  useEffect(() => {
    if (!isLoading) {
      setValue(props.source, data);
    }
  }, [data]);
  return <TextInput {...props} />;
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.open === nextProps.open &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(CodeNumberInput, propsAreEqual);
