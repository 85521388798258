import {
  RecordContextProvider,
  TextInput,
  number,
  required,
} from "react-admin";
import { toCurrency } from "../../../utils/formatter";
import InputAdornment from "@mui/material/InputAdornment";
import { useWatch, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const equalOrGreaterThanCorrectionItem = (value, allValues, elm) => {
  let count = 0;
  for (let i = 0; i < allValues.bad_debt_correction_items.length; i++) {
    const elm = allValues.bad_debt_correction_items[i];
    count += elm.amount;
  }
  if (count > value) {
    return (
      "Not enough amount to accommodate all correction items (" +
      toCurrency(true).format(count) +
      ")"
    );
  }
  return undefined;
};

const validatePrice = [required(), number(), equalOrGreaterThanCorrectionItem];

const TotalAmount = ({ amountSources }) => {
  const correctionAmounts = useWatch({ name: JSON.parse(amountSources) }); // array
  const totalAmount = correctionAmounts.reduce(
    (total, num) => (num ? total + num : total),
    0
  );
  const { setValue } = useFormContext();
  useEffect(() => {
    setValue("total", totalAmount);
  }, [totalAmount]);
  return (
    <RecordContextProvider
      value={{ total: totalAmount ? totalAmount : undefined }}
    >
      <TextInput
        variant="outlined"
        source="total"
        label="mine.label.total_correction"
        validate={validatePrice}
        fullWidth
        disabled
        InputProps={{
          startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
          // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
        }}
        {...parserFormatNumInput}
      />
    </RecordContextProvider>
  );
};
export default TotalAmount;
