import { toCurrency } from "./formatter";

const parserFormatNumInput = {
  parse: (v) => {
    if (v === "") return null;
    const asStr = String(v).replaceAll(",", "").trim();
    return asStr;
  },
  format: (v) => {
    if (v === "") return "";
    if (v === undefined || v === null) return undefined;
    const asStr = String(v).replaceAll(",", "").trim();
    const part = asStr.split(".", 2);
    if (+part[1] > 0) {
      // berarti sudah bisa di format pake toCurrency
      // krna di belakang koma ada nilainya buka empty str atau nol
      return toCurrency(false).format(asStr);
    }
    part[0] = toCurrency(false).format(part[0]);
    return part.join(".");
  },
};
export default parserFormatNumInput;
