import * as React from "react";
import Button from "@mui/material/Button";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useListSortContext } from "react-admin";
import styles from "./GeneralLedgerHeaderSort.module.css";

const inverseOrder = (sort) => (sort === "ASC" ? "DESC" : "ASC");

const GeneralLedgerHeaderSort = ({ source, label, align }) => {
  // sort is an object { field, order } containing the current sort
  // setSort is a callback ({ field, order }) => void allowing to change the sort field and order
  const { sort, setSort } = useListSortContext();

  const handleChangeSort = (event) => {
    const field = event.currentTarget.dataset.sort;
    if (field) {
      setSort({
        field,
        order: field === sort.field ? inverseOrder(sort.order) : "ASC",
      });
    }
  };

  let iconAlignment = {};
  if (source === sort.field) {
    let orderClass = styles.sortArrow + " " + styles.arrowUp;
    if (sort.order === "DESC") {
      orderClass = styles.sortArrow + " " + styles.arrowDown;
    }
    if (align === "right") {
      iconAlignment.startIcon = (
        <ArrowUpwardIcon
          className={orderClass}
          fontSize="small"
          sx={{ color: "rgba(255, 255, 255, 0.7)" }}
        />
      );
      iconAlignment.endIcon = null;
    } else {
      iconAlignment.startIcon = null;
      iconAlignment.endIcon = (
        <ArrowUpwardIcon
          className={orderClass}
          fontSize="small"
          sx={{ color: "rgba(255, 255, 255, 0.7)" }}
        />
      );
    }
  }

  return (
    <Button
      variant="text"
      disableElevation
      disableRipple
      disableFocusRipple
      onClick={handleChangeSort}
      data-sort={source}
      sx={{
        textTransform: "capitalize",
        p: 0,
        justifyContent: align ? align : "left",
        bgcolor: "transparent !important",
      }}
      {...iconAlignment}
    >
      {label}
    </Button>
  );
};

export default React.memo(GeneralLedgerHeaderSort, () => true);
