import { useMemo, useState } from "react";
import {
  BooleanInput,
  DateInput,
  List,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  WrapperField,
  DatagridConfigurable,
  FunctionField,
  WithRecord,
  NumberField,
  BulkDeleteWithConfirmButton,
  useTheme,
} from "react-admin";
import CompanyAddDialog from "../../components/companies/create/CompanyAddDialog";
import CompanyEditDialog from "../../components/companies/edit/CompanyEditDialog";
import companyFilterList from "../../components/companies/filter/companyFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";
import CompanyExpandList from "../../components/companies/expand/CompanyExpandList";

const CompanyList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={companyFilterList}
        sort={{ field: "created_at", order: "ASC" }}
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<CompanyExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <FunctionField
            label="mine.label.name"
            render={(record) => `${record.alias}, ${record.name}`}
          />
          <FunctionField
            label="mine.label.address"
            render={(record) =>
              `${record.detail}, ${record.city.type} ${record.city.name}, ${record.province.name}, ${record.postal_code}`
            }
          />
          <TextField source="phone" />
          <TextField source="fax" />
          <FunctionField
            label="Bank"
            render={(record) =>
              `${record.bank}, ${record.bank_acc_name}, ${record.bank_acc_number}`
            }
          />
          <FunctionField
            textAlign="right"
            label="mine.label.debt"
            render={(record) => {
              let src = { debt: 0 };
              if (record.company_debt_items.length) {
                src = {
                  debt: record.company_debt_items[0].remain,
                };
              }
              return <NumberField record={src} source="debt" />;
            }}
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      {openAdd ? (
        <CompanyAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <CompanyEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default CompanyList;
