import Grid from "@mui/material/Grid";
import { TextInput, useDataProvider } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import { useWatch, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const CodeDetailInput = ({
  nameSource,
  qualitySource,
  priceSource,
  quantitySource,
  dependOn,
}) => {
  const [productID, date] = useWatch({ name: [dependOn, "date"] });
  const { setValue, getValues } = useFormContext();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (productID && date) {
      if (productID.length === 36) {
        (async () => {
          try {
            const { brand, size, motif, density, color, quality, price } =
              // see: https://stackoverflow.com/a/332888
              await dataProvider.getListNoParams(
                "products/code-detail?id=" +
                  encodeURIComponent(productID) +
                  "&date=" +
                  encodeURIComponent(getValues("date"))
              );
            setValue(
              nameSource,
              motif.name +
                " " +
                density.name +
                " " +
                color.name +
                " " +
                quality.name
            );
            setValue(qualitySource, quality.name);
            setValue(priceSource, price.buy_price);
          } catch (error) {
            console.error(error);
          }
        })();
      }
    } else {
      setValue(nameSource, undefined);
      setValue(qualitySource, undefined);
      setValue(priceSource, 0);
      setValue(quantitySource, undefined);
    }
  }, [productID, date]);

  return (
    <>
      <Grid item xs={12} lg={4}>
        <TextInput
          variant="outlined"
          source={nameSource}
          label="mine.label.name"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <TextInput
          variant="outlined"
          source={qualitySource}
          label="mine.label.quality"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <TextInput
          variant="outlined"
          source={priceSource}
          label="mine.label.cogs"
          helperText={false}
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          {...parserFormatNumInput}
        />
      </Grid>
    </>
  );
};
export default CodeDetailInput;
