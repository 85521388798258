import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function CustomerAddressForm() {
  return (
    <ArrayInput
      source="customer_addresses"
      defaultValue={[
        {
          location_name: undefined,
          province_id: undefined,
          city_id: undefined,
          postal_code: undefined,
          detail: undefined,
          phone: undefined,
          fax: undefined,
        },
      ]}
    >
      <SimpleFormIterator getItemLabel={(index) => `#${index + 1}`}>
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
