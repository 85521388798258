import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BadDebtCorrectionItemForm from "../create/components/BadDebtCorrectionItemForm";
import BadDebtCorrectionJournalForm from "../create/components/BadDebtCorrectionJournalForm";
import BadDebtCorrectionItemEditForm from "../edit/components/BadDebtCorrectionItemForm";
import BadDebtCorrectionJournalEditForm from "../edit/components/BadDebtCorrectionJournalForm";
import { useState } from "react";
import { useTranslate } from "react-admin";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const TabForm = ({ schema, ...props }) => {
  const [value, setValue] = useState(0);
  const t = useTranslate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
          mb: 1,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={t("mine.label.correction")}
            sx={value === 0 ? { bgcolor: "grey.200" } : {}}
          />
          <Tab
            label={t("mine.label.journal")}
            sx={value === 1 ? { bgcolor: "grey.200" } : {}}
          />
        </Tabs>
      </Box>
      {schema === "edit" ? (
        <>
          <TabPanel value={value} index={0}>
            <BadDebtCorrectionItemEditForm
              setAmountSource={props.setAmountSource}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BadDebtCorrectionJournalEditForm />
          </TabPanel>
        </>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <BadDebtCorrectionItemForm
              setAmountSource={props.setAmountSource}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BadDebtCorrectionJournalForm />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default TabForm;
