import { useEffect, useState } from "react";
import { AutocompleteInput, useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";

// FIXME semua yg pake komponent ini musti diganti pke ReferenceInput yg bener,
// contoh yg tadinya pke ini E: \Sugeng\react - admin\src\components\prices\common\MotifReference.js
export default function DependOnAutocompleteInput({
  dependOn,
  resource,
  queryName, // company_id
  ...props
}) {
  const [choices, setChoices] = useState([]);
  const dataProvider = useDataProvider();
  const dependOnVal = useWatch({ name: dependOn });
  useEffect(() => {
    if (dependOnVal === null) {
      setChoices([]);
    } else if (dependOnVal) {
      (async () => {
        let endpoint = resource;
        if (!resource.includes("/")) {
          endpoint = resource + "/select";
        }
        const data = await dataProvider.getListNoParams(
          endpoint + "?" + queryName + "=" + dependOnVal
        );
        setChoices(data);
      })();
    }
  }, [dependOnVal]);
  return (
    <AutocompleteInput
      // onCreate={() => {
      //   const newCategoryName = prompt("Contoh penulisan: 2 - Liabilities");
      //   const split = newCategoryName.split("-");
      //   const num = +split[0].replace(/\D/g, "");
      //   const newCategory = {
      //     code: num,
      //     name: newCategoryName,
      //   };
      //   setChoices((prev) => [...prev, newCategory]);
      //   return newCategory;
      // }}
      choices={choices}
      {...props}
    />
  );
}
