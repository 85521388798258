import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import { useGetIdentity, useResourceContext } from "react-admin";

const UploadProgress = ({ isCreateLoading }) => {
  const [progress, setProgress] = useState(0);
  const resource = useResourceContext(); // cuman nama resource
  const { identity } = useGetIdentity();

  useEffect(() => {
    if (isCreateLoading) {
      if (typeof EventSource !== "undefined") {
        var source = new EventSource(
          "http://127.0.0.1:8098/api/admin/v1/" +
            resource +
            "/upload-progress?cid=" +
            identity.companyID
        );
        source.onmessage = function (event) {
          const persentage = +event.data.replace("Message: ", "");
          setProgress(persentage);
          if (persentage === 100) {
            source.close();
          }
        };
      } else {
        document.getElementById("result").innerHTML =
          "Sorry, your browser does not support server-sent events...";
      }

      return () => source.close();
    }
  }, [isCreateLoading, resource, identity]);

  return <LinearProgress variant="determinate" value={progress} />;
};

export default UploadProgress;
