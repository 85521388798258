import { memo, useEffect } from "react";
import { TextInput, useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";

function DependOnTextInput({ dependOn, resource, ...props }) {
  // const [value, setValue] = useState("");
  const dataProvider = useDataProvider();
  const dependOnVal = useWatch({ name: dependOn });
  const { setValue } = useFormContext();
  useEffect(() => {
    if (dependOnVal === null || dependOnVal === undefined) {
      setValue("max_payment_days", "");
    } else if (dependOnVal) {
      (async () => {
        const data = await dataProvider.getListNoParams(
          resource + "/" + dependOnVal
        );
        setValue("max_payment_days", data.time_period);
      })();
    }
  }, [dependOnVal]);
  return (
    <TextInput
      variant="outlined"
      fullWidth
      disabled
      source="max_payment_days"
      {...props}
    />
  );
}

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.dependOn === nextProps.dependOn &&
    prevProps.resource === nextProps.resource
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
export default memo(DependOnTextInput, propsAreEqual);
