import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function PurchaseOrderItemForm() {
  return (
    <ArrayInput
      source="purchase_order_items"
      defaultValue={[
        {
          code: "",
          quantity: "",
          name: "", // disabled
          quality: "", // disabled
          price: "", // disabled
        },
      ]}
    >
      <SimpleFormIterator
        disableReordering
        getItemLabel={(index) => `#${index + 1}`}
        fullWidth={true}
        sx={{
          "& .RaSimpleFormIterator-line": {
            border: "none",
          },
        }}
      >
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
