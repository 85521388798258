import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  FunctionField,
  WithRecord,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import SupplierAddDialog from "../../components/suppliers/create/SupplierAddDialog";
import SupplierEditDialog from "../../components/suppliers/edit/SupplierEditDialog";
import supplierFilterList from "../../components/suppliers/filter/supplierFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";

const SupplierList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={supplierFilterList}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          sx={{
            "& th": {
              textTransform: "capitalize",
            },
          }}
        >
          <TextField source="code" label="mine.label.code" />
          <TextField source="name" label="mine.label.name" />
          <FunctionField
            label="mine.label.address"
            render={(record) =>
              `${record.address}, ${record.city.name}, ${record.province.name}`
            }
          />
          <FunctionField
            label="Phone"
            render={(record) =>
              `${record.phone1}, ${record.phone2}, ${record.phone3}`
            }
          />
          <TextField source="fax" />
          <TextField source="pic" label="mine.label.pic" />
          <NumberField source="credit_limit" />
          <NumberField source="time_period" />
          <TextField source="note" label="mine.label.note" emptyText="-" />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      {openAdd ? (
        <SupplierAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <SupplierEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default SupplierList;
