import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function PriceItemForm() {
  return (
    <ArrayInput
      source="price_items"
      defaultValue={[
        {
          quality_id: "",
          buy_price: "",
          sell_price: "",
        },
      ]}
    >
      <SimpleFormIterator
        getItemLabel={(index) => `#${index + 1}`}
        fullWidth
        sx={{
          "& .RaSimpleFormIterator-action, & .RaSimpleFormIterator-action > .button-reorder":
            {
              display: "flex",
              flexDirection: "column",
            },
          "& .RaSimpleFormIterator-line": {
            mt: 2,
          },
        }}
      >
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
