import {
  DateField,
  useRecordContext,
  RecordContextProvider,
  TabbedShowLayout,
  NumberField,
} from "react-admin";
import Box from "@mui/material/Box";

const CompanyExpandList = () => {
  const record = useRecordContext();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <TabbedShowLayout.Tab label="Debt">
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>No.</th>
              <th>SJ Number</th>
              <th>Supplier</th>
              <th>Total</th>
              <th>Remaining</th>
              <th>Created At</th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {record.company_debts.map((item, idx) => {
              return (
                <tr key={item.id}>
                  <td>{idx + 1}</td>
                  <td>{item.purchase_order.code}</td>
                  <td>{item.supplier.name}</td>
                  <td>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="total"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="remain"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="created_at"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="due_date"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
};

export default CompanyExpandList;
