import { memo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  AutocompleteInput,
  maxLength,
  minLength,
  number,
  ReferenceInput,
  required,
  useDataProvider,
  useSimpleFormIteratorItem,
} from "react-admin";
import { toCurrency } from "../../../../utils/formatter";
import InputAdornment from "@mui/material/InputAdornment";
import { useQuery } from "react-query";
import {
  filterToQuery,
  inputText,
  matchSuggestion,
  OptionRenderer,
} from "../../../customerDebtPayments/common/CustomSelectOption";
import JournalAmount from "../../../customerDebtPayments/common/JournalAmount";
import { useFormContext } from "react-hook-form";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";

const equalWithTotal = (value, allValues, elm) => {
  let totalCount = { debit: 0, credit: 0 };
  let journalType;
  const splite = elm.source.split(".");
  for (let i = 0; i < allValues.bad_debt_installment_journals.length; i++) {
    const elmt = allValues.bad_debt_installment_journals[i];
    if (!isNaN(parseFloat(elmt.debit))) {
      totalCount.debit += +elmt.debit;
    }
    if (!isNaN(parseFloat(elmt.credit))) {
      totalCount.credit += +elmt.credit;
    }

    if (!journalType) {
      if (+splite[1] === i) {
        // jika sama indexnya berarti inilah komponen yg mau di validasi
        if (+elmt.debit !== 0) {
          journalType = "debit";
        } else if (+elmt.credit !== 0) {
          journalType = "credit";
        } else {
          // mengikuti default nya atau nilai awal sebelom ada inputan apapun
          journalType = "debit";
        }
        // skipProcessLast = +elmt.debit + +elmt.credit;
      }
    }
  }

  if (totalCount[journalType] !== +allValues.total) {
    return (
      "All " +
      journalType +
      " must be equal with " +
      toCurrency(false).format(allValues.total) +
      " (total payment)"
    );
  }
  return undefined;
};

const validateCode = [required(), minLength(36), maxLength(36)];
const validateAmount = [required(), number(), equalWithTotal];

const JournalInputs = ({ idSource, debitSource, creditSource, ...props }) => {
  const { index } = useSimpleFormIteratorItem();

  const { setValue } = useFormContext();
  const dataProvider = useDataProvider();

  // GET COA ID
  const { data, isError } = useQuery(
    ["coa/get-id-by-code/1112", "getListNoParams"],
    () => dataProvider.getListNoParams("coa/get-id-by-code/1112")
  );
  // GET COA ID

  useEffect(() => {
    if (index === 0 && data && !isError) {
      setValue(idSource, data);
    }
  }, [data, isError, index]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        "& .MuiGrid-item": { py: 1 },
        mt: 0,
      }}
    >
      <Grid item xs={6}>
        <ReferenceInput
          source={idSource}
          reference="coa"
          perPage={10}
          sort={{ field: "code", order: "ASC" }}
          // fungsinya untuk membatasi pilihan coa dengan kode
          // yg mengandung angka 1103
          // filter={
          // transaction typenya giro dan
          // jurnal typenya debit
          // trType === 3 && journalType === 1 ? { code: 1103 } : undefined
          // }
        >
          <AutocompleteInput
            label="mine.label.account"
            variant="outlined"
            fullWidth
            validate={validateCode}
            optionValue="id"
            // optionText="code"
            optionText={<OptionRenderer />}
            inputText={inputText}
            matchSuggestion={matchSuggestion}
            filterToQuery={filterToQuery}
            disabled={index === 0}
            helperText={false}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <JournalAmount
          helperText={false}
          label="mine.label.debit"
          variant="outlined"
          source={debitSource}
          validate={validateAmount}
          disabled={true}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
          dependOn={["total", index === 1 ? idSource : "", creditSource]}
        />
      </Grid>
      <Grid item xs={3}>
        <JournalAmount
          helperText={false}
          label="mine.label.credit"
          variant="outlined"
          source={creditSource}
          validate={validateAmount}
          disabled={true}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
          dependOn={["total", index === 0 ? idSource : "", debitSource]}
        />
      </Grid>
    </Grid>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.chart_of_account_id === nextProps.chart_of_account_id &&
    prevProps.debit === nextProps.debit &&
    prevProps.credit === nextProps.credit &&
    prevProps.code === nextProps.code
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
const Beruk = memo(JournalInputs, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <Beruk
      {...scopedFormData}
      idSource={getSource("chart_of_account_id")}
      debitSource={getSource("debit")}
      creditSource={getSource("credit")}
    />
  );
};
export default Inputs;
