import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BadDebtInstallmentItemForm from "../create/components/BadDebtInstallmentItemForm";
import BadDebtInstallmentJournalForm from "../create/components/BadDebtInstallmentJournalForm";
import BadDebtInstallmentItemEditForm from "../edit/components/BadDebtInstallmentItemForm";
import BadDebtInstallmentJournalEditForm from "../edit/components/BadDebtInstallmentJournalForm";
import { useState } from "react";
import { useTranslate, useTheme } from "react-admin";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const TabForm = ({ schema, ...props }) => {
  const [value, setValue] = useState(0);

  const t = useTranslate();
  const [theme] = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const bgcolor = theme !== "dark" ? "grey.200" : "grey.800";

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
          mb: 1,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={t("mine.label.payment")}
            sx={value === 0 ? { bgcolor } : {}}
          />
          <Tab
            label={t("mine.label.journal")}
            sx={value === 1 ? { bgcolor } : {}}
          />
        </Tabs>
      </Box>
      {schema === "edit" ? (
        <>
          <TabPanel value={value} index={0}>
            <BadDebtInstallmentItemEditForm />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BadDebtInstallmentJournalEditForm />
          </TabPanel>
        </>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <BadDebtInstallmentItemForm />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BadDebtInstallmentJournalForm />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default TabForm;
