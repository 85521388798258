import {
  Button,
  ExportButton,
  FilterButton,
  SelectColumnsButton,
  TopToolbar,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";

const TableListActions = ({ createHandler, exporter, filter }) => {
  return (
    <TopToolbar
      sx={{
        minHeight: "54px !important",
        mb: 1,
        "& .MuiButtonBase-root": {
          border: "0.1rem solid",
        },
      }}
    >
      <SelectColumnsButton />
      {filter === false ? null : <FilterButton />}
      {createHandler ? (
        <Button
          startIcon={<AddIcon />}
          label="ra.action.create"
          sx={{ width: "fit-content" }}
          onClick={createHandler}
        />
      ) : null}
      {exporter === false ? null : <ExportButton />}
    </TopToolbar>
  );
};

export default TableListActions;
