import {
  useResourceContext,
  useDataProvider,
  Error,
  Labeled,
  RecordContextProvider,
  NumberField,
  useListController,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";

const IncomeStatementAsideList = ({ defaultFilter }) => {
  const resource = useResourceContext();
  const cntx = useListController({ storeKey: resource });

  let ifCtx = defaultFilter;
  if (Object.keys(cntx.filterValues).length) {
    ifCtx = cntx.filterValues;
  }

  const resourceFilter =
    resource + "/summary?filter=" + encodeURIComponent(JSON.stringify(ifCtx));

  const dataProvider = useDataProvider();
  const { data, isLoading, error } = useQuery(
    [resourceFilter, "getListNoParams"],
    () => dataProvider.getListNoParams(resourceFilter)
  );

  if (error) return <Error />;

  return (
    <Card
      sx={{
        order: -1,
        mr: 2,
        mt: 6,
        width: 240,
        position: "sticky",
        top: 0,
        alignSelf: "flex-start",
      }}
    >
      <CardContent>
        <RecordContextProvider value={data}>
          <Stack>
            {Object.keys(!isLoading && data ? data : {}).map((source) => {
              let label = "";
              if (source) {
                label = source.replaceAll("_", " ");
              }
              return source.endsWith("percentage") ? null : (
                <Box sx={{ py: 1, textTransform: "uppercase" }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      lineHeight: 1.5,
                      color: "#89868D",
                      fontSize: "0.75em",
                    }}
                  >
                    {label}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <NumberField source={source} fontSize={16} />
                    <NumberField
                      source={source + "_percentage"}
                      fontSize={12}
                    />
                  </Box>
                </Box>
              );
            })}
          </Stack>
        </RecordContextProvider>
      </CardContent>
    </Card>
  );
};
export default IncomeStatementAsideList;
