import { useRecordContext } from "react-admin";

export const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.code ? ("(" + record.code + ") ") : ""}
      {record.name}
    </span>
  );
};
export const inputText = (choice) => choice.name;
export const matchSuggestion = (filter, choice) => {
  if (choice.name === undefined) {
    return false;
  }
  return choice.name.toLowerCase().includes(filter.toLowerCase());
};
export const filterToQuery = (searchText) => ({ all: searchText });

export const OptionRendererKinds = () => {
  const record = useRecordContext();
  return <span>{record.name}</span>;
};
export const inputTextKinds = (choice) => choice.name;
export const matchSuggestionKinds = (filter, choice) => {
  if (choice.name === undefined) {
    return false;
  }
  return choice.name.toLowerCase().includes(filter.toLowerCase());
};
