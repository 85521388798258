import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  FunctionField,
  WithRecord,
  DateField,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import PriceAddDialog from "../../components/prices/create/PriceAddDialog";
import PriceEditDialog from "../../components/prices/edit/PriceEditDialog";
import priceFilterList from "../../components/prices/filter/priceFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import PriceUploadDialog from "../../components/prices/upload/PriceUploadDialog";

const Aside = () => {
  // const t = useTranslate();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5>
        Harga yang diinputkan/ditampilkan disini harus harga dasar sebelum
        ditambah harga brand atau harga ukuran.
        <br />
        <br />
        Jika ada harga ukuran maka harga brand tidak dipakai. Contoh: harga
        brand Mustika depo ini 3.000 dan harga ukuran 60x60cm 4.320, maka untuk
        produk M60BLC harga dasar akan ditambah 4.320
        <br />
        <br />
        Harga brand dipakai hanya jika tidak ada harga ukuran. Contoh harga beli
        50.000 dan harga jual 70.000, maka saat beli dan jual system akan
        menambahkan masing-masing harga menjadi 53.000 dan 73.000
        <br />
        <br />
        WARNING: untuk kasus transaksi via Surabaya
        <br />
        <br />
        Kita ambil contoh harga seperti diatas, brand 3.000, beli 50.000, jual
        70.000. Jika harga brand depo Surabaya 1.000, maka harga beli yang
        diinputkan disini tidak boleh 50.000 tetapi harusnya 48.000. yg didapat
        dengan rumus: 50.000 + 1.000 - 3.000 = 48.000, dan harga jualnya adalah
        68.000 dengan rumus 70.000 + 1.000 - 3.000 = 68.000
        <br />
        <br />
        Demikian juga untuk kasus via Jakarta, jika harga brand jakarta 0 maka
        rumus harga beli adalah: 50.000 + 0 - 3.000 = 47.000, sedangkan harga
        jual adalah: 70.000 + 0 - 3.000 = 67.000
      </h5>
    </div>
  );
};

const PriceList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showAside, setShowAside] = useState(false);

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => (e) => {
    e.stopPropagation();
    setRecordId(recId);
    setOpenEdit(true);
  };
  const rowClickHandler = (id, basePath, record) => {
    // console.log("Lets", id, basePath, record);
    setShowAside((prev) => !prev);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={priceFilterList}
        queryOptions={{ meta: { sort: ["prices.created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          rowClick={rowClickHandler}
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          <FunctionField
            label="mine.label.code"
            render={(record) => {
              return record.code;
            }}
          />
          <TextField source="quality.code" label="KW" />
          <NumberField source="buy_price" label="mine.label.buy_price" />
          <NumberField source="sell_price" label="mine.label.sell_price" />
          <WrapperField label="mine.label.start_date">
            <DateField source="start_date" label="mine.label.start_date" />
            {" ("}
            <TextField
              source="start_date_humanize"
              label="mine.label.start_date"
            />
            {")"}
          </WrapperField>
          <WrapperField label="mine.label.end_date">
            <DateField source="end_date" label="mine.label.end_date" />
            {" ("}
            <TextField source="end_date_humanize" label="mine.label.end_date" />
            {")"}
          </WrapperField>
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [showAside]
  );
  return (
    <>
      {list}
      {openAdd ? <PriceAddDialog open={openAdd} setOpen={setOpenAdd} /> : null}
      {openUpload ? (
        <PriceUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
      {openEdit ? (
        <PriceEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default PriceList;
