import Grid from "@mui/material/Grid";
import { TextInput, useDataProvider } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import { useEffect } from "react";

const CodeDetailInput = ({ nameSource, qualitySource, dependOn }) => {
  const productID = useWatch({ name: dependOn });
  const { setValue } = useFormContext();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (productID) {
      if (productID.length === 36) {
        (async () => {
          try {
            const { motif, density, color, quality } =
              // see: https://stackoverflow.com/a/332888
              await dataProvider.getListNoParams(
                "products/code-detail?id=" + encodeURIComponent(productID)
              );
            setValue(
              nameSource,
              motif.name +
                " " +
                density.name +
                " " +
                color.name +
                " " +
                quality.name
            );
            setValue(qualitySource, quality.name);
          } catch (error) {
            console.error(error);
          }
        })();
      }
    } else {
      setValue(nameSource, undefined);
      setValue(qualitySource, undefined);
    }
  }, [productID]);

  return (
    <>
      <Grid item xs={12} lg={5.4}>
        <TextInput
          variant="outlined"
          source={nameSource}
          label="mine.label.name"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <TextInput
          variant="outlined"
          source={qualitySource}
          label="mine.label.quality"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
    </>
  );
};
export default CodeDetailInput;
