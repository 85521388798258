import { useRecordContext } from "react-admin";

export const OptionRendererPermission = () => {
  const record = useRecordContext();
  return <span>{record.v1}</span>;
};
export const inputTextPermission = (choice) => choice.v1;
export const matchSuggestionPermission = (filter, choice) => {
  if (choice.id === undefined) {
    return false;
  }
  return choice.v1.toLowerCase().includes(filter.toLowerCase());
};
export const filterToQueryPermission = (searchText) => ({ all: searchText });
