import {
  TextInput,
  maxLength,
  minLength,
  required,
  useDataProvider,
} from "react-admin";
import MotifReference from "./MotifReference";
import OptionReferer from "./OptionReferer";
import { useWatch, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useMutation } from "react-query";
import Grid from "@mui/material/Grid";

const validateBrand = [required(), minLength(36), maxLength(36)];
const validateSymbol = [required(), maxLength(1)];

const CodePickers = ({ dependOn }) => {
  const code = useWatch({ name: dependOn });
  const { setValue } = useFormContext();
  const dataProvider = useDataProvider();
  const { data, mutate } = useMutation((prm) => {
    return dataProvider.getListNoParams("prices/composer-id/" + prm);
  });

  useEffect(() => {
    if (code && code.length === 10) {
      mutate(encodeURIComponent(code));
    } else {
      setValue("brand_id", "");
      setValue("size_id", "");
      setValue("motif_id", "");
      setValue("symbol", "");
      setValue("density_id", "");
      setValue("color_id", "");
    }
  }, [code]);

  useEffect(() => {
    if (data) {
      setValue("brand_id", data.brand_id);
      setValue("size_id", data.size_id);
      setValue("motif_id", data.motif_id);
      setValue("symbol", data.symbol);
      setValue("density_id", data.density_id);
      setValue("color_id", data.color_id);
    }
  }, [data]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        "& .MuiGrid-item": { pt: 0 },
        mt: 0,
        // display: "none",
        // pt: 0,
      }}
    >
      <Grid item xs={12} lg={6}>
        <OptionReferer
          source="brand_id"
          reference="brands"
          label="mine.label.brand"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <OptionReferer
          source="size_id"
          reference="sizes"
          label="mine.label.size"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <MotifReference
          variant="outlined"
          source="motif_id"
          isRequired
          fullWidth
          validate={validateBrand}
          dependOn="brand_id"
          queryName="brand_id"
          reference="motifs"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextInput
          variant="outlined"
          source="symbol"
          label="mine.label.symbol"
          fullWidth
          resettable
          validate={validateSymbol}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <OptionReferer
          source="density_id"
          reference="densities"
          label="mine.label.density"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <OptionReferer
          source="color_id"
          reference="colors"
          label="mine.label.color"
        />
      </Grid>
    </Grid>
  );
};

export default CodePickers;
