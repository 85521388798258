import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function MutationInventoryWriteOffItemForm() {
  return (
    <ArrayInput
      source="mutation_inventory_write_off_items"
      defaultValue={[
        {
          product_id: "",
          quantity: "",
          name: "", // disabled
          quality: "", // disabled
          price: "", // disabled
          warehouse_id: "",
        },
      ]}
    >
      <SimpleFormIterator
        fullWidth={true}
        inline={true}
        disableReordering
        sx={{
          "& .RaSimpleFormIterator-form": {
            width: "100%",
          },
          "& .RaSimpleFormIterator-line": {
            borderBottom: "none",
          },
        }}
        getItemLabel={(index) => `#${index + 1}`}
      >
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
