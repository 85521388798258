import {
  DateField,
  Labeled,
  NumberField,
  RecordContextProvider,
  useRecordContext,
  useTranslate,
} from "react-admin";
import Chip from "@mui/material/Chip";

function StockPendingRemain({ value, date, money, label }) {
  if (date) {
    return (
      <RecordContextProvider value={{ value }}>
        <Labeled
          direction="row"
          sx={{
            alignItems: "center",
            "& .RaLabeled-label": {
              mb: 0,
              mr: "2px",
            },
          }}
        >
          <DateField source="value" label={label} fontSize="12px" />
        </Labeled>
      </RecordContextProvider>
    );
  } else if (money) {
    return (
      <RecordContextProvider value={{ value }}>
        <Labeled
          direction="row"
          sx={{
            alignItems: "center",
            "& .RaLabeled-label": {
              mb: 0,
              mr: "2px",
            },
          }}
        >
          <NumberField source="value" label={label} fontSize="12px" />
        </Labeled>
      </RecordContextProvider>
    );
  }
  return null;
}

export const OptionRenderer = () => {
  const record = useRecordContext();
  const t = useTranslate();
  // const amount = record.inventories.length ? record.inventories[0].amount : 0;
  let blur = {};
  if (record.remain == 0) {
    blur = { opacity: 0.6 }; // { color: "rgba(0,0,0,.4)" };
  }
  return (
    <span
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        ...blur,
      }}
    >
      <span>{record.code}</span>
      <Chip
        label={
          <StockPendingRemain
            value={record.date}
            date={true}
            label={t("mine.label.transaction")}
          />
        }
        size="small"
        color="warning"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // fontSize: "11px",
        }}
      />
      <Chip
        label={
          <StockPendingRemain
            value={record.customer_debt.due_date}
            date={true}
            label={t("mine.label.due")}
          />
        }
        size="small"
        color="warning"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // fontSize: "11px",
        }}
      />
      <Chip
        label={
          <StockPendingRemain
            value={record.customer_debt.total}
            money={true}
            label={t("mine.label.debt")}
          />
        }
        size="small"
        color="warning"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // fontSize: "11px",
        }}
      />
      <Chip
        label={
          <StockPendingRemain
            value={record.customer_debt.remain}
            money={true}
            label={t("mine.label.remain")}
          />
        }
        size="small"
        color="warning"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // fontSize: "11px",
        }}
      />
    </span>
  );
};
export const inputText = (choice) => {
  return choice.code;
};
export const matchSuggestion = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return (
    // choice.code.toLowerCase().includes(filter.toLowerCase()) ||
    choice.code.toLowerCase().includes(filter.toLowerCase())
  );
};

export const filterToQuery = (searchText) => ({ all: searchText });
/*
TODO semua yg pake fungsi ini perlu ganti pake cara


seperti di E:\Sugeng\react-admin\src\components\purchaseOrders\common\ProductInput.js
 */
// export const filterToQueryProduct = (searchText) => ({ all: searchText });
