import { useMemo, useState } from "react";
import {
  BooleanField,
  DateField,
  EmailField,
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  SelectColumnsButton,
  TopToolbar,
  FilterButton,
  ExportButton,
  Button,
  FunctionField,
  WithRecord,
  BulkDeleteWithConfirmButton,
  usePermissions,
} from "react-admin";
import UserAddDialog from "../../components/users/create/UserAddDialog";
import AddIcon from "@mui/icons-material/Add";
import UserEditDialog from "../../components/users/edit/UserEditDialog";
import EditBtn from "../../components/editBtn/EditBtn";
import userFilterList from "../../components/users/filter/userFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";

const UserList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={<TableListActions createHandler={handleClickOpenAdd} />}
        filters={userFilterList}
        queryOptions={{ meta: { sort: ["active DESC", "created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          <TextField source="id" />
          {/* <ImageField
            source="image.src"
            title="username"
            label="Image"
            emptyText="-"
            sx={{
              width: 45,
              height: 45,
              borderRadius: 20,
              overflow: "hidden",
              "& .RaImageField-image": {
                maxWidth: "100%",
                width: "100%",
                height: "100%",
                objectFit: "contain",
                margin: 0,
              },
            }}
          /> */}
          <FunctionField
            label="Name"
            render={(record) => `${record.first_name} ${record.last_name}`}
          />
          <TextField source="username" />
          {permissions && permissions.update_company ? (
            <TextField label="Company" source="company.alias" />
          ) : null}
          <EmailField source="email" />
          <TextField source="role" />
          <BooleanField source="active" textAlign="center" />
          <DateField source="created_at" showTime />
          <WrapperField label="Actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      {openAdd ? <UserAddDialog open={openAdd} setOpen={setOpenAdd} /> : null}
      {openEdit ? (
        <UserEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default UserList;
