import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {
  useNotify,
  SimpleForm,
  TextInput,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
  useUpdate,
  Edit,
  DateInput,
  minLength,
  useTranslate,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";

const greaterThan = (attr) => (value, allValues) => {
  if (!allValues[attr] || allValues[attr] === "0001-01-01T00:00:00Z")
    return undefined;
  if (value < allValues[attr]) {
    return "Must be greater than " + allValues[attr];
  }
  return undefined;
};
const lessThan = (attr) => (value, allValues) => {
  if (!allValues[attr] || allValues[attr] === "0001-01-01T00:00:00Z")
    return undefined;
  if (value > allValues[attr]) {
    return "Must be less than " + allValues[attr];
  }
  return undefined;
};

const decimalNumber = (value, allValues) => {
  if (/^\d+(\.\d{1,2})?$/.test(value)) {
    return undefined;
  }
  return "Must be a valid number or decimal value";
};

const validateName = [required(), maxLength(50)];
const validatePercentage = [required(), decimalNumber];
const validateStartDate = [
  required(),
  minLength(10),
  maxLength(10),
  lessThan("end_date"),
];
const validateEndDate = [
  required(),
  minLength(10),
  maxLength(10),
  greaterThan("start_date"),
];

export default function PromotionEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const resource = useResourceContext(); // cuman nama resource

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{translate("mine.dialog.edit_promotion")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <TextInput
              variant="outlined"
              source="name"
              fullWidth
              isRequired
              resettable
              validate={validateName}
              label="mine.label.name"
            />
            <TextInput
              variant="outlined"
              source="percentage"
              label="mine.label.persentage"
              fullWidth
              isRequired
              resettable
              helperText={translate("mine.message.percentage_value")}
              validate={validatePercentage}
              inputProps={{
                type: "text",
                inputMode: "numeric",
                // pattern: "^\d+(\.\d{1,2})?$",
              }}
            />

            <DateInput
              variant="outlined"
              source="start_date"
              fullWidth
              isRequired
              validate={validateStartDate}
              label="mine.label.start_date"
            />
            <DateInput
              variant="outlined"
              source="end_date"
              fullWidth
              isRequired
              validate={validateEndDate}
              label="mine.label.end_date"
            />

            <ReferenceArrayInput
              source="promotion_items"
              reference="prices"
              filter={{ group_by: "code" }}
              perPage={100}
              sort={{ field: "code", order: "ASC" }}
            >
              <AutocompleteArrayInput
                variant="outlined"
                source="product_code"
                fullWidth
                isRequired
                optionValue="code"
                optionText="code"
                label="mine.label.product_code"
                format={(value) => {
                  return value == null
                    ? "not defined"
                    : value.map((val) => val.product_code);
                }}
                parse={(value) => {
                  if (value === "not defined") {
                    return null;
                  } else {
                    const yet = value.map((val) => ({ product_code: val }));
                    return yet;
                  } //
                }}
              />
            </ReferenceArrayInput>
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
