import { DateInput, NumberInput, TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const promotionFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput source="company" resettable />,
  <TextInput source="name" resettable />,
  <NumberInput source="percentage" />,
  <DateInput source="start_date" />,
  <DateInput source="end_date" />,
];

export default promotionFilterList;
