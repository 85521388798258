import { useEffect, useState } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";

// detailType defaultnya all, atau setting jadi noZeroStock, atau noZeroRemain
const ProductInput = ({
  dependOn,
  source,
  reset,
  defaultFilters = {},
  detailType = "all",
  exclude = {},
  ...props
}) => {
  const depend = useWatch({ name: dependOn });
  const { setValue, getValues } = useFormContext();
  const [first, setFirst] = useState(false);

  useEffect(() => {
    if (first) {
      setFirst(true);
      setValue(source, undefined);
      for (let i = 0; i < reset.length; i++) {
        const sourceName = reset[i];
        setValue(sourceName, undefined);
      }
    }
  }, [depend, first]);

  return (
    <ReferenceInput
      source={source}
      reference="products/dropdown"
      perPage={50}
      sort={{ field: "products.code", order: "ASC" }}
      filter={{ ...defaultFilters, ...exclude }}
      // TODO semua ReferenceInput musti dihandle ketika searchText nya undefined seperti ini
      enableGetChoices={(q) => {
        return q && !!q.code;
      }}
    >
      <AutocompleteInput
        {...props}
        filterToQuery={(searchText) => {
          return { code: searchText, detail_type: detailType };
        }}
      />
    </ReferenceInput>
  );
};
// const propsAreEqual = (prevProps, nextProps) => {
//   if (
//     // prevProps.dependOn === nextProps.dependOn &&
//     prevProps.source === nextProps.source
//   ) {
//     // tidak perlu render ulang
//     return true;
//   }
//   // perlu dirender ulang
//   return false;
// };
export default ProductInput; // memo(ProductInput, propsAreEqual);
