import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  useTranslate,
  DateField,
  FunctionField,
} from "react-admin";
import Box from "@mui/material/Box";
import { Fragment } from "react";

const nullElm = () => (
  <Fragment key={Math.random().toString(16).slice(2)}>
    <td style={{ textAlign: "right", color: "rgba(0,0,0,.3)" }}>0</td>
    <td style={{ textAlign: "right", color: "rgba(0,0,0,.3)" }}>0</td>
  </Fragment>
);

const valuedElm = (item) => {
  const amountColor = {};
  if (item.amount == 0) {
    amountColor.color = "rgba(0,0,0,.3)";
  }
  return (
    <Fragment key={Math.random().toString(16).slice(2)}>
      <td style={{ textAlign: "right" }}>
        <RecordContextProvider value={item}>
          <NumberField
            source="amount"
            sx={{
              fontSize: "inherit",
              ...amountColor,
            }}
          />
        </RecordContextProvider>
      </td>
      <td style={{ textAlign: "right" }}>
        <FunctionField
          textAlign="right"
          sx={{ fontSize: "inherit" }}
          render={() => {
            let data = { total_hpp: item.amount * item.cogs };
            const color = {};
            if (data.total_hpp == 0) {
              color.color = "rgba(0,0,0,.3)";
            }
            return (
              <RecordContextProvider value={data}>
                <NumberField
                  source="total_hpp"
                  sx={{
                    fontSize: "inherit",
                    ...color,
                  }}
                />
              </RecordContextProvider>
            );
          }}
        />
      </td>
    </Fragment>
  );
};

const InventoryCardExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();

  let inventoryCounter = 0;
  for (let i = 0; i < record.prices.length; i++) {
    if (record.prices[i].inventories.length) {
      inventoryCounter += 1;
    }
  }
  if (inventoryCounter === 0) {
    return undefined;
  }

  return record.prices.length ? (
    <Box
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        borderRadius: "4px",
      }}
    >
      {record.prices.map((priceItem, index) => {
        if (!priceItem.inventories.length) {
          return null;
        }

        const totalPerHPP = {
          totalInitial: 0,
          totalMoneyInitial: 0,
          totalIn: 0,
          totalMoneyIn: 0,
          totalOut: 0,
          totalMoneyOut: 0,
          totalOther: 0,
          totalMoneyOther: 0,
          totalFinal: 0,
          totalMoneyFinal: 0,
        };

        return (
          <Box
            key={priceItem.buy_price + index}
            sx={{
              position: "relative",
              mb: 3,
            }}
          >
            <Box
              component="span"
              sx={{
                py: "2px",
                px: "10px",
                backgroundImage:
                  "linear-gradient(98deg, rgb(166, 119, 253), rgb(100, 59, 177) 94%)",
                color: "#fff",
                position: "absolute",
                top: "-24px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "18px",
              }}
            >
              <RecordContextProvider value={priceItem.inventories[0]}>
                HPP{" "}
                <NumberField
                  source="cogs"
                  sx={{
                    fontSize: "12px",
                  }}
                />
              </RecordContextProvider>
            </Box>
            <Box
              sx={{
                overflow: "hidden",
                borderRadius: "8px",
                borderTopLeftRadius: 0,
                boxShadow: (theme) => {
                  return theme.components.MuiPaper.styleOverrides.elevation1
                    .boxShadow;
                },
                bgcolor: "background.paper",
              }}
            >
              <Box
                component="table"
                sx={{
                  width: "100%",
                  // bgcolor: "rgba(0,0,0,.04)",
                  fontSize: "smaller",
                  borderCollapse: "collapse",
                  "& th, td": {
                    padding: "6px 8px",
                    border: "1px solid #dddddd",
                  },
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "8.3%" }}>{t("mine.label.code")}</th>
                    <th style={{ width: "8.3%" }}>{t("mine.label.date")}</th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.initial")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.initial_value")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.tbl_header.total_in")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.value_in")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.tbl_header.total_out")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.value_out")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.other")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.other_value")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.final")}
                    </th>
                    <th style={{ textAlign: "right", width: "8.3%" }}>
                      {t("mine.label.final_value")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {priceItem.inventories.map((item, idx) => {
                    let inOutOtherElm = [];

                    if (item.code === "Awal") {
                      // KOLOM AWAL
                      inOutOtherElm = [
                        valuedElm(item),
                        nullElm(),
                        nullElm(),
                        nullElm(),
                        nullElm(),
                      ];
                      totalPerHPP.totalInitial += item.amount;
                      totalPerHPP.totalMoneyInitial += item.amount * item.cogs;
                      totalPerHPP.totalFinal += item.amount;
                      totalPerHPP.totalMoneyFinal += item.amount * item.cogs;
                    } else if (
                      item.code.startsWith("RK-") ||
                      item.code.startsWith("M-") ||
                      item.code.startsWith("RB-") ||
                      item.code.startsWith("RT-")
                    ) {
                      // KOLOM LAINNYA
                      inOutOtherElm = [
                        nullElm(),
                        nullElm(),
                        nullElm(),
                        valuedElm(item),
                        nullElm(),
                      ];
                      totalPerHPP.totalOther += item.amount;
                      totalPerHPP.totalMoneyOther += item.amount * item.cogs;
                      totalPerHPP.totalFinal += item.amount;
                      totalPerHPP.totalMoneyFinal += item.amount * item.cogs;
                    } else if (item.amount < 0 || item.code.startsWith("SJ-")) {
                      // KOLOM KELUAR
                      inOutOtherElm = [
                        nullElm(),
                        nullElm(),
                        valuedElm(item),
                        nullElm(),
                        nullElm(),
                      ];
                      totalPerHPP.totalOut += item.amount;
                      totalPerHPP.totalMoneyOut += item.amount * item.cogs;
                      totalPerHPP.totalFinal -= item.amount;
                      totalPerHPP.totalMoneyFinal -= item.amount * item.cogs;
                    } else if (item.amount > 0) {
                      // KOLOM MASUK
                      inOutOtherElm = [
                        nullElm(),
                        valuedElm(item),
                        nullElm(),
                        nullElm(),
                        nullElm(),
                      ];
                      totalPerHPP.totalIn += item.amount;
                      totalPerHPP.totalMoneyIn += item.amount * item.cogs;
                      totalPerHPP.totalFinal += item.amount;
                      totalPerHPP.totalMoneyFinal += item.amount * item.cogs;
                    }

                    return (
                      <tr key={item.id + idx}>
                        <td>{item.code}</td>
                        <td>
                          <RecordContextProvider value={item}>
                            <DateField
                              source="date"
                              sx={{
                                fontSize: "inherit",
                              }}
                            />
                          </RecordContextProvider>
                        </td>
                        {inOutOtherElm}
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={2} style={{ fontWeight: 700 }}>
                      Total
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalInitial"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalMoneyInitial"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalIn"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    {/* <td></td> */}
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalMoneyIn"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalOut"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    {/* <td></td> */}
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalMoneyOut"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalOther"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    {/* <td></td> */}
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalMoneyOther"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalFinal"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    {/* <td></td> */}
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={totalPerHPP}>
                        <NumberField
                          source="totalMoneyFinal"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: 700,
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                </tbody>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  ) : null;
};

export default InventoryCardExpandList;
