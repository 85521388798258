import * as React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  DateField,
  NumberField,
  RecordContextProvider,
  TextField,
  useGetOne,
  useGetRecordId,
  useListController,
  useResourceContext,
  useTranslate,
} from "react-admin";
import GeneralLedgerHeaderSort from "../sort/GeneralLedgerHeaderSort";

const GeneralLedgerHeader = ({ children }) => {
  const t = useTranslate();
  const id = useGetRecordId();
  const resource = useResourceContext();
  const cntx = useListController({ storeKey: resource });

  const aCopy = { ...cntx.filterValues };

  delete aCopy.chart_of_account_id;

  const { data, isLoading, error } = useGetOne("general-ledgers", {
    id:
      encodeURIComponent(id) +
      "?filter=" +
      encodeURIComponent(JSON.stringify(aCopy)),
  });

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <TableHead
      sx={{
        position: "sticky",
        top: 0,
        bgcolor: (theme) => theme.palette.background.default,
      }}
    >
      {!isLoading && (
        <RecordContextProvider value={data}>
          <TableRow>
            <TableCell sx={{ py: 3 }}>
              <TextField source="code" label="mine.tbl_header.code" />
            </TableCell>
            <TableCell colSpan={2}>
              <TextField source="name" label="mine.tbl_header.name" />
            </TableCell>
            <TableCell align="right">
              <NumberField source="previous" label="mine.label.previous" />
            </TableCell>
            <TableCell align="right">
              <NumberField source="debit" />
            </TableCell>
            <TableCell align="right">
              <NumberField source="credit" />
            </TableCell>
            <TableCell align="right">
              <NumberField source="current" label="Akhir" />
            </TableCell>
          </TableRow>
        </RecordContextProvider>
      )}
      <TableRow>
        {/* empty cell to account for the select row checkbox in the body */}
        {React.Children.map(children, (child) => {
          // TRANSLATE LABEL
          let label = child.props.source;
          if (child.props.label) {
            label = t(child.props.label);
          }
          // TRANSLATE LABEL
          return React.isValidElement(child) ? (
            <TableCell key={child.props.source} align={child.type.textAlign}>
              <GeneralLedgerHeaderSort
                source={child.props.source}
                label={label}
                align={child.type.textAlign}
              />
            </TableCell>
          ) : null;
        })}
      </TableRow>
    </TableHead>
  );
};

export default GeneralLedgerHeader;
