import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import { useQuery } from "react-query";
import {
  useNotify,
  SimpleForm,
  TextInput,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  useDataProvider,
  useUpdate,
  Edit,
  AutocompleteInput,
  minLength,
  ReferenceInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import DependOnAutocompleteInput from "../../common/DependOnAutocompleteInput";
import { toCurrency } from "../../../utils/formatter";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const validateCustomerType = [required(), minLength(36), maxLength(36)];
const validatePriceLimit = [required(), number()];
const validateNote = [maxLength(50)];

export default function PriceLimitEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const resource = useResourceContext(); // cuman nama resource
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  // GET BRAND LISTS
  // const {
  //   data: brands,
  //   isLoading: isLoadingBrands,
  //   error: brandError,
  // } = useQuery(["brands/select", "getListNoParams"], () =>
  //   dataProvider.getListNoParams("brands/select")
  // );
  // GET BRAND LISTS

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>Edit Price Limit</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <ReferenceInput
              label="Customer type"
              source="customer_type_id"
              reference="customer-types"
              perPage={10}
              sort={{ field: "created_at", order: "ASC" }}
            >
              <AutocompleteInput
                variant="outlined"
                // source="brand_id"
                isRequired
                // choices={isLoadingBrands ? [] : brands}
                fullWidth
                validate={validateCustomerType}
                optionValue="id"
                optionText="code"
              />
            </ReferenceInput>

            <TextInput
              variant="outlined"
              source="price"
              label="Price limit"
              fullWidth
              isRequired
              resettable
              validate={validatePriceLimit}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              {...parserFormatNumInput}
              // format={(v) => {
              //   if (v === "") return "";
              //   if (v === undefined) return undefined;
              //   const asStr = String(v).replaceAll(".", "").trim();
              //   return toCurrency(false).format(asStr);
              // }}
              // parse={(v) => {
              //   if (v === "") return "";
              //   const asStr = String(v).replaceAll(".", "").trim();
              //   return parseInt(asStr);
              // }}
            />
            <TextInput
              variant="outlined"
              source="note"
              fullWidth
              resettable
              validate={validateNote}
            />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
