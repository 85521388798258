import { memo } from "react";
import { AutocompleteInput, useDataProvider } from "react-admin";
import { useQuery } from "react-query";

const ProvinceInput = ({ provinceId, ...props }) => {
  const dataProvider = useDataProvider();
  // GET PROVINCES LISTS
  const {
    data: provinces,
    isLoading: isLoadingProvinces,
    error: provinceError,
  } = useQuery(["provinces", "provinceList"], () =>
    dataProvider.provinceList("provinces")
  );
  // GET PROVINCES LISTS

  return (
    <AutocompleteInput
      {...props}
      choices={isLoadingProvinces ? [] : provinces}
    />
  );
};
const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.provinceId === nextProps.provinceId &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
export default memo(ProvinceInput, propsAreEqual);
