import { AutocompleteInput, DateInput, ReferenceInput } from "react-admin";

const inventoryCardFilterList = (permissions) => {
  const defaultFilter = [
    <DateInput
      alwaysOn
      source="start_date"
      variant="outlined"
      label="mine.label.start_date"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      //FIXME: semua DateInput tidak bisa di tambah resettable
      // resettable
      variant="outlined"
      label="mine.label.end_date"
    />,
    <ReferenceInput
      alwaysOn
      source="start_code"
      reference="products"
      sort={{ field: "products.code", order: "ASC" }}
      perPage={10}
      enableGetChoices={({ code }) => {
        return !!code;
      }}
    >
      <AutocompleteInput
        resettable
        optionValue="code"
        optionText="code"
        variant="outlined"
        label="mine.label.start_code"
        debounce={500}
        filterToQuery={(searchText) => {
          return { code: searchText };
        }}
      />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn
      source="end_code"
      reference="products"
      sort={{ field: "products.code", order: "ASC" }}
      perPage={10}
      enableGetChoices={({ code }) => {
        return !!code;
      }}
    >
      <AutocompleteInput
        resettable
        optionValue="code"
        optionText="code"
        variant="outlined"
        label="mine.label.end_code"
        debounce={500}
        filterToQuery={(searchText) => {
          return { code: searchText };
        }}
      />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn
      source="size_id"
      reference="sizes"
      sort={{ field: "sizes.code", order: "ASC" }}
      perPage={10}
    >
      <AutocompleteInput
        resettable
        optionText="name"
        variant="outlined"
        label="mine.label.size"
        filterToQuery={(searchText) => {
          if (!searchText) {
            return undefined;
          }
          return { name: searchText };
        }}
      />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn
      source="quality_id"
      reference="qualities"
      sort={{ field: "qualities.code", order: "ASC" }}
      perPage={10}
    >
      <AutocompleteInput
        resettable
        optionText="code"
        variant="outlined"
        label="mine.label.quality"
        filterToQuery={(searchText) => {
          if (!searchText) {
            return undefined;
          }
          return { code: searchText };
        }}
      />
    </ReferenceInput>,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.unshift(
      // defaultFilter.splice(
      // 1,
      // 0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default inventoryCardFilterList;
