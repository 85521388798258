import {
  DateField,
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
  TextField,
} from "react-admin";
import Box from "@mui/material/Box";
import React from "react";

const CheckCashingExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <TabbedShowLayout.Tab label="mine.tbl_header.payment">
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>{t("mine.tbl_header.sale_code")}</th>
              <th>{t("mine.tbl_header.customer_debt_payment_code")}</th>
              <th>{t("mine.tbl_header.bank_name")}</th>
              <th style={{ textAlign: "right" }}>
                {t("mine.tbl_header.payment")}
              </th>
            </tr>
          </thead>
          <tbody>
            {record.customer_debt_payment.customer_debt_payment_items.map(
              (item, idx) => {
                return (
                  <tr key={item.id}>
                    <td>{item.sale.code}</td>
                    <td>
                      <RecordContextProvider
                        value={record.customer_debt_payment}
                      >
                        <TextField
                          source="code"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>
                      <RecordContextProvider
                        value={record.customer_debt_payment}
                      >
                        <TextField
                          source="bank_name"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="amount"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
      {record.transactions.length && (
        <TabbedShowLayout.Tab label={t("mine.tbl_header.journal")}>
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.tbl_header.coa")}</th>
                <th>{t("mine.tbl_header.coa_name")}</th>
                <th>{t("mine.tbl_header.description")}</th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.debit")}
                </th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.credit")}
                </th>
              </tr>
            </thead>
            <tbody>
              {record.transactions.map((item, idx) => {
                return (
                  <tr key={item.id}>
                    <td>{item.coa.code}</td>
                    <td>{item.coa.name}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="debit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="credit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      )}
      {!record.transactions.length && (
        <TabbedShowLayout.Tab label={t("mine.tbl_header.journal") + "*"}>
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.tbl_header.coa")}</th>
                <th>{t("mine.tbl_header.coa_name")}</th>
                {/* <th>{t("mine.tbl_header.description")}</th> */}
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.debit")}
                </th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.credit")}
                </th>
              </tr>
            </thead>
            <tbody>
              {record.customer_debt_payment.customer_debt_payment_items.map(
                (item, idx) => {
                  if (record.type === 1) {
                    // PENCAIRAN
                    let code, name;
                    // DAPATKAN AKUN DEBIT (BIASANYA NOTES RECEIVABLE - BANK)
                    // UNTUK DI TARO DI CREDIT
                    for (
                      let i = 0;
                      i <
                      record.customer_debt_payment
                        .customer_debt_payment_journals.length;
                      i++
                    ) {
                      const obj =
                        record.customer_debt_payment
                          .customer_debt_payment_journals[i];
                      if (obj.type === 1) {
                        // tipe debit
                        code = obj.chart_of_account.code;
                        name = obj.chart_of_account.name;
                        break;
                      }
                    }
                    return (
                      <React.Fragment key={item.id}>
                        <tr>
                          <td>{record.chart_of_account.code}</td>
                          <td>{record.chart_of_account.name}</td>
                          <td style={{ textAlign: "right" }}>
                            <RecordContextProvider value={item}>
                              <NumberField
                                source="amount"
                                sx={{
                                  fontSize: "inherit",
                                }}
                              />
                            </RecordContextProvider>
                          </td>
                          <td style={{ textAlign: "right" }}>-</td>
                        </tr>
                        <tr>
                          <td>{code}</td>
                          <td>{name}</td>
                          <td style={{ textAlign: "right" }}>-</td>
                          <td style={{ textAlign: "right" }}>
                            <RecordContextProvider value={item}>
                              <NumberField
                                source="amount"
                                sx={{
                                  fontSize: "inherit",
                                }}
                              />
                            </RecordContextProvider>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  } else {
                    return record.customer_debt_payment.customer_debt_payment_journals
                      .map((jnl, indx) => {
                        return (
                          <tr key={item.id + jnl.id}>
                            <td>{jnl.chart_of_account.code}</td>
                            <td>{jnl.chart_of_account.name}</td>
                            <td style={{ textAlign: "right" }}>
                              {jnl.credit ? (
                                <RecordContextProvider value={jnl}>
                                  <NumberField
                                    source="credit"
                                    sx={{
                                      fontSize: "inherit",
                                    }}
                                  />
                                </RecordContextProvider>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {jnl.debit ? (
                                <RecordContextProvider value={jnl}>
                                  <NumberField
                                    source="debit"
                                    sx={{
                                      fontSize: "inherit",
                                    }}
                                  />
                                </RecordContextProvider>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        );
                      })
                      .reverse();
                  }
                }
              )}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      )}
    </TabbedShowLayout>
  );
};

export default CheckCashingExpandList;
