import { useEffect } from "react";
import { TextInput, useDataProvider } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";

const TransportDiscountInput = ({
  dependOnTransportID,
  dependOnSaleOrderItemId,
  dependOnQty,
  ...props
}) => {
  const { getListNoParams } = useDataProvider();
  // const resource = useResourceContext();
  const { setValue } = useFormContext();
  const transportID = useWatch({ name: dependOnTransportID });
  const saleOrderItemId = useWatch({ name: dependOnSaleOrderItemId });
  const qty = useWatch({ name: dependOnQty });
  // const me = useWatch({ name: props.source });
  useEffect(() => {
    if (transportID && saleOrderItemId && qty) {
      getListNoParams(
        "transport-costs/cost/" +
          encodeURIComponent(transportID) +
          "/" +
          encodeURIComponent(saleOrderItemId) +
          "/" +
          qty
      ).then((res) => {
        setValue(props.source, res);
      });
    } else {
      setValue(props.source, 0); // TODO perlu di tes tadinya bukan 0 tapi undefined
    }
  }, [transportID, saleOrderItemId, qty]);

  useEffect(() => {
    return () => setValue(props.source, 0); // TODO perlu di tes tadinya bukan 0 tapi undefined
  }, []);
  return <TextInput {...props} />;
};

export default TransportDiscountInput;
