import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import {
  useCreate,
  useNotify,
  SimpleForm,
  Create,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  minLength,
  AutocompleteInput,
  TextInput,
  DateInput,
  useTranslate,
  ReferenceInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import { toCurrency } from "../../../utils/formatter";
import Box from "@mui/material/Box";
import TabForm from "../common/TabForm";
import currentDate from "../../../utils/currentDate";
import {
  filterToQuery,
  inputText,
  matchSuggestion,
  OptionRenderer,
} from "../../customerDebtPayments/common/CustomSelectOption";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";
import CodeNumberInput from "../../common/CodeNumberInput";

const equalOrGreaterThanCorrectionItem = (value, allValues, elm) => {
  let count = 0;
  for (let i = 0; i < allValues.factory_debt_correction_items.length; i++) {
    const elm = allValues.factory_debt_correction_items[i];
    count += elm.amount;
  }
  if (count > value) {
    return (
      "Not enough amount to accommodate all correction items (" +
      toCurrency(true).format(count) +
      ")"
    );
  }
  return undefined;
};

const validateNumber = [required(), minLength(13), maxLength(13)];
const validateDate = [required(), minLength(10), maxLength(10)];
const validateSupplier = [required(), minLength(36), maxLength(36)];
const validatePrice = [required(), number(), equalOrGreaterThanCorrectionItem];
const validateNote = [required()];

export default function FactoryDebtCorrectionAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const t = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      fullWidth={true}
      maxWidth={false}
    >
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>
              {t("mine.dialog.create_factory_debt_correction")}
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ alignSelf: "normal" }}>
            <CodeNumberInput
              open={open}
              dependOn="date"
              variant="outlined"
              source="code"
              label="mine.label.code"
              fullWidth
              validate={validateNumber}
              disabled
            />
            <DateInput
              variant="outlined"
              source="date"
              fullWidth
              isRequired
              validate={validateDate}
              defaultValue={currentDate()}
            />
            <ReferenceInput
              source="supplier_id"
              reference="suppliers"
              perPage={10}
              sort={{ field: "suppliers.code", order: "ASC" }}
            >
              <AutocompleteInput
                label="mine.label.supplier"
                variant="outlined"
                isRequired
                fullWidth
                validate={validateSupplier}
                optionValue="id"
                // optionText="code"
                optionText={<OptionRenderer />}
                inputText={inputText}
                matchSuggestion={matchSuggestion}
                filterToQuery={filterToQuery}
              />
            </ReferenceInput>
            <TextInput
              variant="outlined"
              source="total"
              label="Total Correction"
              validate={validatePrice}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              {...parserFormatNumInput}
            />
            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              multiline
              rows={3}
              fullWidth
              validate={validateNote}
            />
            <TabForm />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
