import { AutocompleteInput, NumberInput, ReferenceInput } from "react-admin";

const customerDebtMaxDayFilterList = (permissions) => {
  const defaultFilter = [
    <NumberInput
      alwaysOn
      source="total_day"
      label="mine.label.total_day"
      resettable
      variant="outlined"
    />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      0,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};
export default customerDebtMaxDayFilterList;
