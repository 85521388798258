import { memo, useEffect } from "react";
import { TextInput, useDataProvider, useResourceContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useMutation } from "react-query";

const CodeNumberInput = ({ open, dependOn, ...props }) => {
  const dataProvider = useDataProvider();
  const date = useWatch({ name: dependOn });
  const resource = useResourceContext();
  const { setValue } = useFormContext();
  // GET NEXT NUMBER
  const { data, mutate, isLoading, error } = useMutation((dt) =>
    dataProvider.getListNoParams(resource + "/next-number?date=" + dt)
  );
  // GET NEXT NUMBER

  useEffect(() => {
    if (open) {
      mutate(date);
    }
  }, [open, date]);
  useEffect(() => {
    if (!isLoading) {
      setValue(props.source, data);
    }
  }, [data]);
  return <TextInput {...props} />;
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.open === nextProps.open &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(CodeNumberInput, propsAreEqual);
