import Grid from "@mui/material/Grid";
import ProductInput from "../../common/ProductInput";
import QuantityInput from "../../common/QuantityInput";
import {
  maxLength,
  minLength,
  number,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext } from "react-hook-form";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";
import {
  OptionRendererProduct,
  inputTextProduct,
  matchSuggestionProduct,
} from "../../../common/OptionRendererProduct";

const validateCode = [required(), minLength(13), maxLength(13)];
const validateQuantity = [required(), number()];

const CstInput = ({
  code,
  quantity,
  // NAMA-NAMA SOURCE
  codeSource,
  quantitySource,
  nameSource,
  qualitySource,
  priceSource,
}) => {
  const { setValue, getValues } = useFormContext();

  const dataProvider = useDataProvider();

  const onCodeChangeHandler = async (code) => {
    if (code.length !== 13) return;
    try {
      const { brand, size, motif, density, color, quality, price } =
        // see: https://stackoverflow.com/a/332888
        await dataProvider.getListNoParams(
          "products/code-detail?code=" +
            encodeURIComponent(code) +
            "&date=" +
            encodeURIComponent(getValues("date"))
        );
      setValue(
        nameSource,
        motif.name + " " + density.name + " " + color.name + " " + quality.name
      );
      // setValue("brand", brand.name);
      // setValue("size", size.name);
      // setValue("motif", motif.name);
      // setValue("density", density.name);
      // setValue("color", color.name);
      setValue(qualitySource, quality.name);
      setValue(priceSource, price.buy_price);
    } catch (error) {}
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        "& .MuiGrid-item": { py: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={3}>
        <ProductInput
          fullWidth
          source={codeSource}
          variant="outlined"
          validate={validateCode}
          label="mine.label.product_code"
          optionValue="code"
          optionText={<OptionRendererProduct />}
          inputText={inputTextProduct}
          matchSuggestion={matchSuggestionProduct}
          // filterToQuery={filterToQuery}
          onChange={onCodeChangeHandler}
          dependOn="date"
          reset={[nameSource, qualitySource, priceSource]}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <TextInput
          variant="outlined"
          source={nameSource}
          label="mine.label.name"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <TextInput
          variant="outlined"
          source={qualitySource}
          label="mine.label.quality"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <TextInput
          variant="outlined"
          source={priceSource}
          label="mine.label.price"
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          helperText="harga beli sesuai tanggal yang dipilih"
          {...parserFormatNumInput}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <QuantityInput
          variant="outlined"
          source={quantitySource}
          label="mine.label.quantity"
          fullWidth
          validate={validateQuantity}
          quantity={quantity}
          helperText={false}
        />
      </Grid>
    </Grid>
  );
};

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <CstInput
      {...scopedFormData}
      codeSource={getSource("code")}
      quantitySource={getSource("quantity")}
      nameSource={getSource("name")}
      qualitySource={getSource("quality")}
      priceSource={getSource("price")}
    />
  );
};
export default Inputs;
