import { memo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import QuantityInput from "../../common/QuantityInput";
import {
  maxLength,
  minLength,
  minValue,
  number,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext, useWatch } from "react-hook-form";
// import DependOnAutocompleteInput from "../../../common/DependOnAutocompleteInput";
import SubtotalInput from "../../common/SubtotalInput";
import ProductDetail from "./ProductDetail";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";
import TransportDiscountInput from "../../common/TransportDiscountInput";
import { useMutation } from "react-query";
import SaleOrderItemReference from "../../common/SaleOrderItemReference";

const equalOrLessThan = (value, allValues, elm) => {
  const splite = elm.source.split(".");
  const orderQty = allValues[splite[0]][+splite[1]].quantity;
  if (value > orderQty) {
    return "Must be equal or less than " + orderQty + " (order quantity)";
  }
  return undefined;
};

const greaterThanZero = (value, allValues, elm) => {
  if (value === 0) {
    return undefined;
  }
  if (value < 0) {
    return { message: "ra.validation.minValue", args: { min: 1 } };
  }
  return undefined;
};

const validateSaleOrderItemId = [required(), minLength(36), maxLength(36)];
const validateDisc = [number(), greaterThanZero];
const validateQuantity = [required(), number(), minValue(1), equalOrLessThan];
const validatePrice = [required(), number(), minValue(1)];

const TransportDiscount = ({
  discSource,
  priceSource,
  spcDiscSource,
  subtotalSource,
  sentQuantitySource,
  saleOrderItemIdSource,
  transportDiscountSource,
}) => {
  const dataProvider = useDataProvider();
  const transportID = useWatch({ name: "transport_id" });
  const { data, mutate, isLoading, reset } = useMutation((transID) =>
    dataProvider.getListNoParams("transports/" + transID)
  );
  useEffect(() => {
    if (transportID && transportID.length === 36) {
      mutate(transportID);
    } else {
      reset();
    }
  }, [transportID]);
  return (
    <>
      <Grid item xs={12} lg={1.1}>
        <TransportDiscountInput
          className="transport-disc"
          variant="outlined"
          label="mine.label.transport_discount"
          source={transportDiscountSource}
          fullWidth
          disabled
          defaultValue={0}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          {...parserFormatNumInput}
          dependOnTransportID="transport_id"
          dependOnSaleOrderItemId={saleOrderItemIdSource}
          dependOnQty={sentQuantitySource}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1.2}>
        <SubtotalInput
          className="sub-total"
          variant="outlined"
          source={subtotalSource}
          fullWidth
          disabled
          defaultValue={0}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          {...parserFormatNumInput}
          dependOnPrice={priceSource}
          dependOnQty={sentQuantitySource}
          dependOnDisc={discSource}
          dependOnSpcDisc={spcDiscSource}
          dependOnTransportDisc={transportDiscountSource}
          helperText={false}
        />
      </Grid>
    </>
  );
};

const CstInput = ({
  code,
  quantity,
  // NAMA-NAMA SOURCE
  saleOrderItemIdSource,
  sentQuantitySource,
  quantitySource,
  nameSource,
  discSource,
  spcDiscSource,
  priceSource,
  subtotalSource,
  transportDiscountSource,
  formData,
  ...props
}) => {
  const { setValue } = useFormContext();

  const dataProvider = useDataProvider();

  const onSaleOrderItemIdChangeHandler = async (uuid) => {
    setValue(sentQuantitySource, "");
    if (uuid.length !== 36) {
      setValue(nameSource, "");
      setValue(quantitySource, "");
      setValue(priceSource, "");
    }
    if (uuid) {
      try {
        const { product, quantity, price } =
          // see: https://stackoverflow.com/a/332888
          await dataProvider.getListNoParams(
            "sale-order-items/" + encodeURIComponent(uuid)
          );
        setValue(nameSource, product.name);
        setValue(quantitySource, quantity);
        setValue(priceSource, price);
      } catch (error) {}
    }
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        "& .MuiGrid-item": { py: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={1.8}>
        <SaleOrderItemReference
          variant="outlined"
          source={saleOrderItemIdSource}
          fullWidth
          label="mine.label.product_code"
          validate={validateSaleOrderItemId}
          dependOn="sale_items[0].sale_order_item.sale_order_id"
          queryName="sale_order_id"
          reference="sale-order-items"
          onChange={onSaleOrderItemIdChangeHandler}
          helperText={false}
          defaultFilterAddition={{ "sales.approved": 1 }}
        />
        {/* <DependOnAutocompleteInput
          variant="outlined"
          source={saleOrderItemIdSource}
          label="mine.label.product_code"
          isRequired
          fullWidth
          validate={validateSaleOrderItemId}
          optionValue="id" // WARNING id nya sale order item bukan id product
          optionText="code"
          dependOn="sale_items[0].sale_order_item.sale_order_id"
          queryName="sale_order_id"
          resource="sale-order-items"
          onChange={onSaleOrderItemIdChangeHandler}
          helperText={false}
        /> */}
      </Grid>

      <Grid item xs={12} lg={2.5}>
        <ProductDetail
          label="mine.label.name"
          dependOn={saleOrderItemIdSource}
          nameSource={nameSource}
          quantitySource={quantitySource}
          priceSource={priceSource}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={0.9}>
        <TextInput
          variant="outlined"
          source={quantitySource}
          label="mine.label.order_quantity"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={0.9}>
        <QuantityInput
          variant="outlined"
          source={sentQuantitySource}
          label="mine.label.sent_quantity"
          fullWidth
          validate={validateQuantity}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1.2}>
        <TextInput
          variant="outlined"
          source={priceSource}
          validate={validatePrice}
          disabled
          fullWidth
          label="mine.label.price_per_box"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          {...parserFormatNumInput}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1.2}>
        <TextInput
          variant="outlined"
          source={discSource}
          validate={validateDisc}
          fullWidth
          label="mine.label.discount_per_box"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          {...parserFormatNumInput}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1.2}>
        <TextInput
          variant="outlined"
          source={spcDiscSource}
          validate={validateDisc}
          fullWidth
          label="mine.label.special_discount_per_box"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          {...parserFormatNumInput}
          helperText={false}
        />
      </Grid>
      <TransportDiscount
        {...{
          discSource,
          priceSource,
          spcDiscSource,
          subtotalSource,
          sentQuantitySource,
          saleOrderItemIdSource,
          transportDiscountSource,
        }}
      />
    </Grid>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.formData.transport_id === nextProps.formData.transport_id &&
    prevProps.quantity === nextProps.quantity &&
    prevProps.disc === nextProps.disc &&
    prevProps.spc_disc === nextProps.spc_disc &&
    prevProps.sale_order_item_id === nextProps.sale_order_item_id
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
const Beruk = memo(CstInput, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <CstInput
      {...scopedFormData}
      formData={formData}
      saleOrderItemIdSource={getSource("sale_order_item_id")}
      sentQuantitySource={getSource("sent_quantity")}
      quantitySource={getSource("quantity")}
      priceSource={getSource("price")}
      nameSource={getSource("name")}
      discSource={getSource("disc")}
      spcDiscSource={getSource("spc_disc")}
      subtotalSource={getSource("subtotal")}
      transportDiscountSource={getSource("transport_discount")}
    />
  );
};
export default Inputs;
