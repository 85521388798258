import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  BulkDeleteWithConfirmButton,
  usePermissions,
} from "react-admin";
import WarehouseAddDialog from "../../components/warehouse/create/WarehouseAddDialog";
import WarehouseEditDialog from "../../components/warehouse/edit/WarehouseEditDialog";
import warehouseFilterList from "../../components/warehouse/filter/warehouseFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";

const WarehouseList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={warehouseFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="name" label="mine.label.name" />
          <DateField
            source="created_at"
            label="mine.label.created_at"
            showTime
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      {openAdd ? (
        <WarehouseAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <WarehouseEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default WarehouseList;
