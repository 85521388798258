import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const inventoryPendingFilterList = (permissions) => {
  const defaultFilter = [
    <TextInput
      source="all"
      label="mine.filter.search_all"
      alwaysOn
      resettable
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      source="sale_order_code"
      label="mine.label.sale_order"
      resettable
      variant="outlined"
      alwaysOn
    />,
    <DateInput
      source="sale_order_date"
      label="mine.label.date"
      variant="outlined"
      alwaysOn
    />,
    <TextInput source="product" resettable variant="outlined" alwaysOn />,
    <SelectInput
      source="status"
      resettable
      variant="outlined"
      filterToQuery={(searchText) => ({ status: searchText })}
      alwaysOn
      choices={[
        { id: 1, name: "Pending" },
        { id: 2, name: "Done" },
        { id: 3, name: "Canceled" },
      ]}
    />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          // resettable // FIXME: semu autocomplete di dalem referenceinput tdk bisa di tambah resetable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
          filterToQuery={(searchText) => ({ company: searchText })}
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default inventoryPendingFilterList;
