import { TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const TotalInput = ({ ...props }) => (
  <TextInput
    variant="outlined"
    source="total"
    fullWidth
    disabled
    defaultValue={0}
    InputProps={{
      startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
      // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
    }}
    {...parserFormatNumInput}
    {...props}
  />
);

export default TotalInput;
