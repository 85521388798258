import { useState } from "react";
import {
  CreateButton,
  Button,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import { useQuery } from "react-query";
import AddIcon from "@mui/icons-material/Add";
import AddressAddDialog from "./AddressAddDialog";

const AddressList = () => {
  const [open, setOpen] = useState(false);

  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const { data, isLoading, error } = useQuery(
    ["user-address", "userAddressList", { username: record.username }],
    () =>
      dataProvider.userAddressList("user-address", {
        username: record.username,
      })
  );
  

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Button
        startIcon={<AddIcon />}
        label="Create"
        sx={{ width: "fit-content" }}
        onClick={handleClickOpen}
      />
      <AddressAddDialog open={open} setOpen={setOpen} />
    </>
  );
};

export default AddressList;
