import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useNotify,
  TextInput,
  NumberInput,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  useDataProvider,
  useCreate,
  Create,
  minLength,
  AutocompleteInput,
  BooleanInput,
  SimpleForm,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import Transition from "../../transition/Transition";
import DependOnAutocompleteInput from "../../common/DependOnAutocompleteInput";
import CustomerAddressForm from "./components/CustomerAddressForm";
import sanitizeData from "../../../utils/sanitizeData";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const validateCompany = [required(), minLength(36), maxLength(36)];
const validateName = [required(), maxLength(125)];
const validatePIC = [required(), maxLength(50)];
const LoanLimit = [required(), number()];
const LoanTenor = [required(), number()];
const LoanTenorTolerance = [required(), number()];

export default function CustomerAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const dataProvider = useDataProvider();
  const t = useTranslate();

  // GET COMPANY LISTS
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companyError,
  } = useQuery(["companies/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("companies/select")
  );
  // GET COMPANY LISTS

  // GET CUSTOMER TYPE LISTS
  const {
    data: customerTypes,
    isLoading: isLoadingCustomerTypes,
    error: customerTypesError,
  } = useQuery(["customer-types/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("customer-types/select")
  );
  // GET CUSTOMER TYPE LISTS

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: sanitizeData(values) },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      TransitionComponent={Transition}
    >
      <Create
        title={" "}
        sx={{
          "& .RaCreate-main": { mt: 0 },
          "& .RaCreate-card": { boxShadow: "none" },
        }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>
              {t("ra.action.create") + " " + t("mine.label.customer")}
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <AutocompleteInput
              variant="outlined"
              source="company_id"
              isRequired
              choices={isLoadingCompanies ? [] : companies}
              fullWidth
              validate={validateCompany}
            />
            <AutocompleteInput
              variant="outlined"
              source="customer_type_id"
              isRequired
              choices={isLoadingCustomerTypes ? [] : customerTypes}
              fullWidth
              validate={validateCompany}
            />

            <DependOnAutocompleteInput
              variant="outlined"
              source="sales_area_id"
              isRequired
              fullWidth
              validate={validateCompany}
              dependOn="company_id"
              queryName="company_id"
              resource="sales-areas"
            />

            <TextInput
              variant="outlined"
              source="name"
              fullWidth
              isRequired
              validate={validateName}
            />

            <TextInput
              variant="outlined"
              label="Person in charge"
              source="pic"
              fullWidth
              isRequired
              validate={validatePIC}
            />

            <TextInput
              variant="outlined"
              source="loan_limit"
              fullWidth
              isRequired
              validate={LoanLimit}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              {...parserFormatNumInput}
            />
            <NumberInput
              variant="outlined"
              source="loan_tenor"
              fullWidth
              isRequired
              validate={LoanTenor}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">Days</InputAdornment>
                ),
              }}
            />
            <NumberInput
              variant="outlined"
              source="loan_tenor_tolerance"
              fullWidth
              isRequired
              validate={LoanTenorTolerance}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">Days</InputAdornment>
                ),
              }}
            />

            <TextInput
              variant="outlined"
              source="note"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
            />

            <BooleanInput source="active" defaultValue={true} />

            <CustomerAddressForm />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
