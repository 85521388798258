import { useEffect, useState } from "react";
import {
  Button,
  Confirm,
  useRecordContext,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import { useMutation } from "react-query";

const ConfirmBtn = ({ title, content, resource, dataBody, confirmProps = {}, ...props }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  const { mutate, isLoading, error, isSuccess } = useMutation(() => {
    return dataProvider.update(resource, {
      id: record.id,
      data: dataBody ? dataBody : {},
    });
  });

  useEffect(() => {
    if (error) {
      if (
        error.message.startsWith("server") ||
        error.message.startsWith("ra.notification")
      ) {
        notify(error.message, {
          type: "error",
        });
      } else {
        if (error.message.includes("*")) {
          const messArg = error.message.split("*");
          notify("server." + messArg[0].split(" ").join("_"), {
            messageArgs: { code: messArg[1] },
            type: "error",
          });
        } else {
          notify("mine.notification." + error.message.split(" ").join("_"), {
            type: "error",
          });
        }
      }
    }
  }, [error]);
  useEffect(() => {
    if (isSuccess) {
      refresh();
      notify("ra.notification.updated", {
        type: "info",
        messageArgs: { smart_count: 1 },
      });
    }
  }, [isSuccess]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick} disabled={isLoading} {...props} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={translate(title)}
        content={content}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        {...confirmProps}
      />
    </>
  );
};

export default ConfirmBtn;
