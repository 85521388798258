import { memo } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useFormContext } from "react-hook-form";

const CustomerSelect = ({
  dependOn,
  source,
  reference,
  orderField,
  reset,
  ...props
}) => {
  const { getValues } = useFormContext();

  return (
    <ReferenceInput
      source={source}
      reference={reference}
      perPage={10}
      sort={{ field: reference + "." + orderField, order: "ASC" }}
      enableGetChoices={(filters) => {
        return !!filters.all;
      }}
    >
      <AutocompleteInput
        {...props}
        // TODO semua filterToQuery musti dihandle ketika searchText nya undefined seperti ini
        filterToQuery={(searchText) => {
          if (!searchText) {
            return { all: getValues(source) };
          }
          return { all: searchText };
        }}
      />
    </ReferenceInput>
  );
};
const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.optionText === nextProps.optionText &&
    prevProps.orderField === nextProps.orderField &&
    prevProps.reference === nextProps.reference &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
export default memo(CustomerSelect, propsAreEqual);
