import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import { useQuery } from "react-query";
import {
  useCreate,
  useNotify,
  SimpleForm,
  Create,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
  useDataProvider,
  minLength,
  AutocompleteInput,
  TextInput,
  DateInput,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import DependOnTextInput from "../../common/DependOnTextInput";
import PurchaseItemForm from "./components/PurchaseItemForm";
import currentDate from "../../../utils/currentDate";
import CodeNumberInput from "../../common/CodeNumberInput";

const validateNumber = [required(), minLength(13), maxLength(13)];
const validateDate = [required(), minLength(10), maxLength(10)];
const validateSupplier = [required(), minLength(36), maxLength(36)];
const validateDeliveryNote = [required(), maxLength(14)];
const validateNote = [required()];

export default function PurchaseAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const dataProvider = useDataProvider();
  const t = useTranslate();

  // GET SUPPLIER LISTS
  const {
    data: suppliers,
    isLoading: isLoadingSuppliers,
    error: supplierError,
  } = useQuery(["suppliers/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("suppliers/select")
  );
  // GET SUPPLIER LISTS

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      fullWidth={true}
      maxWidth={false}
    >
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>
              {t("mine.label.create") + " " + t("mine.expand_menu.purchase")}
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ alignSelf: "normal" }}>
            <CodeNumberInput
              open={open}
              dependOn="date"
              variant="outlined"
              source="code"
              label="mine.label.code"
              fullWidth
              validate={validateNumber}
              disabled
            />
            <DateInput
              variant="outlined"
              source="date"
              fullWidth
              isRequired
              validate={validateDate}
              defaultValue={currentDate()}
              helperText="beda dengan order, ini tidak mempengaruhi harga"
            />
            <AutocompleteInput
              variant="outlined"
              source="supplier_id"
              isRequired
              choices={isLoadingSuppliers ? [] : suppliers}
              fullWidth
              validate={validateSupplier}
            />

            <DependOnTextInput
              dependOn="supplier_id"
              resource="suppliers"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">Days</InputAdornment>
                ),
              }}
            />

            <TextInput
              variant="outlined"
              source="delivery_note"
              label="SJ Pabrik"
              fullWidth
              isRequired
              validate={validateDeliveryNote}
            />

            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              fullWidth
              multiline
              rows={3}
              validate={validateNote}
            />

            <PurchaseItemForm />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
