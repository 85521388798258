import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import {
  useUpdate,
  useNotify,
  SimpleForm,
  Edit,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  minLength,
  DateInput,
  TextInput,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";
import OptionReferer from "../common/OptionReferer";
import MotifReference from "../common/MotifReference";

const greaterThan = (attr) => (value, allValues) => {
  if (!allValues[attr] || allValues[attr] === "0001-01-01T00:00:00Z")
    return undefined;
  if (parseFloat(value) < parseFloat(allValues[attr])) {
    return "Must be greater than " + allValues[attr];
  }
  return undefined;
};
const lessThan = (attr) => (value, allValues) => {
  if (!allValues[attr] || allValues[attr] === "0001-01-01T00:00:00Z")
    return undefined;
  if (parseFloat(value) > parseFloat(allValues[attr])) {
    return "Must be less than " + allValues[attr];
  }
  return undefined;
};

const validateBrand = [required(), minLength(36), maxLength(36)];
const validateSymbol = [required(), maxLength(1)];
const validateBuyPrice = [required(), number(), lessThan("sell_price")];
const validateSellPrice = [required(), number(), greaterThan("buy_price")];
const validateStartDate = [
  required(),
  minLength(10),
  maxLength(10),
  lessThan("end_date"),
];
const validateEndDate = [
  required(),
  minLength(10),
  maxLength(10),
  greaterThan("start_date"),
];

export default function PriceEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const t = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        } else if (error.body.message) {
          notify(error.body.message, {
            type: "error",
          });
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{t("mine.dialog.edit_price")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <OptionReferer
              source="brand_id"
              reference="brands"
              label="mine.label.brand"
            />

            <OptionReferer
              source="size_id"
              reference="sizes"
              label="mine.label.size"
            />

            <MotifReference
              variant="outlined"
              source="motif_id"
              isRequired
              fullWidth
              validate={validateBrand}
              dependOn="brand_id"
              queryName="brand_id"
              reference="motifs"
            />

            {/* <DependOnAutocompleteInput
              variant="outlined"
              source="motif_id"
              isRequired
              fullWidth
              validate={validateBrand}
              dependOn="brand_id"
              queryName="brand_id"
              resource="motifs"
            /> */}

            <TextInput
              variant="outlined"
              source="symbol"
              label="mine.label.symbol"
              fullWidth
              resettable
              validate={validateSymbol}
            />

            <OptionReferer
              source="density_id"
              reference="densities"
              label="mine.label.density"
            />

            <OptionReferer
              source="color_id"
              reference="colors"
              label="mine.label.color"
            />

            <OptionReferer
              source="quality_id"
              reference="qualities"
              label="mine.label.quality"
            />

            {/* <OptionReferer
              source="kind_id"
              reference="kinds"
              label="mine.label.kind"
              keyAttr="created_at"
            /> */}

            <Grid
              container
              spacing={2}
              sx={{
                "& .MuiGrid-item": { pt: 0 },
                mt: 0,
              }}
            >
              <Grid item xs={12} lg={6}>
                <TextInput
                  variant="outlined"
                  source="buy_price"
                  label="mine.label.buy_price"
                  fullWidth
                  isRequired
                  resettable
                  validate={validateBuyPrice}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  {...parserFormatNumInput}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput
                  variant="outlined"
                  source="sell_price"
                  label="mine.label.sell_price"
                  fullWidth
                  resettable
                  validate={validateSellPrice}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  {...parserFormatNumInput}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DateInput
                  variant="outlined"
                  source="start_date"
                  label="mine.label.start_date"
                  fullWidth
                  validate={validateStartDate}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DateInput
                  variant="outlined"
                  source="end_date"
                  label="mine.label.end_date"
                  fullWidth
                  isRequired
                  validate={validateEndDate}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
