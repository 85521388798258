import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {
  useUpdate,
  useNotify,
  SimpleForm,
  Edit,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
  minLength,
  TextInput,
  DateInput,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import SaleReturnItemForm from "./components/SaleReturnItemForm";
import RadioGroupX from "../common/RadioGroupX";
import currentDate from "../../../utils/currentDate";
import {
  OptionRendererCustomer,
  inputTextCustomer,
  matchSuggestionCustomer,
  filterToQueryCustomer,
} from "../../common/OptionRendererCustomer";
import CodeNumberInput from "../../common/CodeNumberInput";

const validateNumber = [required(), minLength(13), maxLength(13)];
const validateDate = [required(), minLength(10), maxLength(10)];
const validateCustomer = [required(), minLength(36), maxLength(36)];
// const validateNote = [maxLength(100)];

export default function SaleReturnEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const translate = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          if (error.body.errors === "403") {
            // munculkan notifikasi erro ketika sudah approved
            notify("mine.notification.403_error", { type: "error" });
          } else {
            // The shape of the returned validation errors must match the shape of the form
            return error.body.errors;
          }
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      fullWidth={true}
      maxWidth={false}
    >
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>
              {translate("mine.dialog.edit_sale_return")}
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ alignSelf: "normal" }}>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <CodeNumberInput
              open={open}
              dependOn="date"
              variant="outlined"
              source="code"
              label="mine.label.code"
              fullWidth
              validate={validateNumber}
              disabled
            />
            <DateInput
              variant="outlined"
              source="date"
              label="mine.label.date"
              fullWidth
              isRequired
              validate={validateDate}
              defaultValue={currentDate()}
            />

            <ReferenceInput
              source="customer_id"
              reference="customers"
              perPage={10}
              sort={{ field: "customers.name", order: "ASC" }}
            >
              <AutocompleteInput
                variant="outlined"
                fullWidth
                validate={validateCustomer}
                label="mine.label.customer"
                // optionText="code"
                optionText={<OptionRendererCustomer />}
                inputText={inputTextCustomer}
                matchSuggestion={matchSuggestionCustomer}
                filterToQuery={filterToQueryCustomer}
              />
            </ReferenceInput>

            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              fullWidth
              multiline
              rows={3}
              // validate={validateNote}
            />

            <RadioGroupX />

            <SaleReturnItemForm />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
