import { useRecordContext } from "react-admin";

export const BadDebtOptionRenderer = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.name} {" (" + record.code + ")"}
    </span>
  );
};
export const badDebtInputText = (choice) => `${choice.name} (${choice.code})`;
export const badDebtMatchSuggestion = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return (
    choice.code.toLowerCase().includes(filter.toLowerCase()) ||
    choice.name.toLowerCase().includes(filter.toLowerCase())
  );
};
export const badDebtFilterToQuery = (searchText) => ({ all: searchText });
