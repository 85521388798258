import { useEffect } from "react";
import Grid from "@mui/material/Grid";
// import ProductInput from "../../common/ProductInput";
import QuantityInput from "../../common/QuantityInput";
import { useQuery } from "react-query";
import {
  AutocompleteInput,
  maxLength,
  minLength,
  number,
  ReferenceInput,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
// import { toCurrency } from "../../../../utils/formatter";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext, useWatch } from "react-hook-form";
import DependOnAutocompleteInput from "../../../common/DependOnAutocompleteInput";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";

const validatePurchaseOrder = [required(), minLength(36), maxLength(36)];
const validateLocation = [required(), minLength(36), maxLength(36)];
const validateQuantity = [required(), number()];

const filterToQuery = (searchText) => ({ code: searchText });

const CstInput = ({
  orderQuantity,
  receivedQuantity,
  // NAMA-NAMA SOURCE
  purchaseOrderIdSource,
  purchaseOrderItemIdSource,
  orderQuantitySource,
  receivedQuantitySource,
  nameSource,
  priceSource,
  locationSource,
}) => {
  const { setValue } = useFormContext();

  const dataProvider = useDataProvider();

  // GET WAREHOUSE LISTS
  const {
    data: location,
    isLoading: isLoadingLocation,
    error: locationError,
  } = useQuery(["warehouses/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("warehouses/select")
  );
  // GET WAREHOUSE LISTS

  const dependOnVal = useWatch({ name: purchaseOrderItemIdSource });
  useEffect(() => {
    if (dependOnVal === null) {
      return;
    } else if (dependOnVal) {
      (async () => {
        if (dependOnVal.length !== 36) return;
        try {
          const {
            brand,
            size,
            motif,
            density,
            color,
            quality,
            price,
            order_quantity,
          } =
            // see: https://stackoverflow.com/a/332888
            await dataProvider.getListNoParams(
              "purchases/code-detail?id=" + encodeURIComponent(dependOnVal)
            );
          setValue(
            nameSource,
            motif.name +
              " " +
              density.name +
              " " +
              color.name +
              " " +
              quality.name
          );
          // setValue("brand", brand.name);
          // setValue("size", size.name);
          // setValue("motif", motif.name);
          // setValue("density", density.name);
          // setValue("color", color.name);
          // setValue(qualitySource, quality.name);
          setValue(orderQuantitySource, order_quantity);
          setValue(priceSource, price.buy_price);
        } catch (error) {}
      })();
    }
  }, [dependOnVal]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        // "& .MuiGrid-item:nth-child(2)": { pt: 2 },
        "& .MuiGrid-item": { py: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={2}>
        <ReferenceInput
          source={purchaseOrderIdSource}
          reference="purchase-orders"
          perPage={10}
          sort={{ field: "created_at", order: "ASC" }}
          filter={{ is_complete: 0 }}
        >
          <AutocompleteInput
            helperText={false}
            label="mine.label.purchase_order"
            variant="outlined"
            // source="brand_id"
            // choices={isLoadingBrands ? [] : brands}
            fullWidth
            validate={validatePurchaseOrder}
            optionValue="id"
            optionText="code"
            filterToQuery={filterToQuery}
          />
        </ReferenceInput>
      </Grid>

      <Grid item xs={12} lg={2}>
        <DependOnAutocompleteInput
          variant="outlined"
          source={purchaseOrderItemIdSource}
          label="mine.label.product_code"
          fullWidth
          optionText="code"
          validate={validatePurchaseOrder}
          dependOn={purchaseOrderIdSource}
          queryName="purchase_order_id"
          resource="purchase-order-items"
          helperText={false}
        />
      </Grid>

      <Grid item xs={12} lg={2}>
        <QuantityInput
          variant="outlined"
          source={orderQuantitySource}
          label="mine.label.order_quantity"
          fullWidth
          disabled
          // validate={validateQuantity}
          quantity={orderQuantity}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <QuantityInput
          variant="outlined"
          source={receivedQuantitySource}
          label="mine.label.received_quantity"
          fullWidth
          validate={validateQuantity}
          quantity={receivedQuantity}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <AutocompleteInput
          variant="outlined"
          source={locationSource}
          label="mine.label.warehouse"
          isRequired
          choices={isLoadingLocation ? [] : location}
          fullWidth
          validate={validateLocation}
          optionText="name"
          helperText={false}
        />
      </Grid>

      <Grid item xs={12} lg={0} sx={{ display: "none" }}>
        <TextInput
          variant="outlined"
          source={nameSource}
          label="mine.label.name"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <TextInput
          variant="outlined"
          source={priceSource}
          label="mine.label.price"
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          helperText="mengikuti harga saat order"
          {...parserFormatNumInput}
        />
      </Grid>
    </Grid>
  );
};

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
}) => {
  return (
    <CstInput
      {...scopedFormData}
      purchaseOrderIdSource={getSource("purchase_order_item.purchase_order_id")}
      purchaseOrderItemIdSource={getSource("purchase_order_item_id")}
      orderQuantitySource={getSource("order_quantity")}
      receivedQuantitySource={getSource("received_quantity")}
      nameSource={getSource("name")}
      priceSource={getSource("price")}
      locationSource={getSource("warehouse_id")}
    />
  );
};
export default Inputs;
