import { memo } from "react";
import { NumberInput } from "react-admin";

const QuantityInput = ({ quantity, ...props }) => <NumberInput {...props} />;

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.quantity === nextProps.quantity &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(QuantityInput, propsAreEqual);
