import { AutocompleteInput, ReferenceInput, TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const filterToQuery = (searchText) => ({ all: searchText });

const permissionFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    source="v0"
    reference="roles"
    // perPage={20}
    sort={{ field: "casbin_rule.id", order: "ASC" }}
    alwaysOn
  >
    <AutocompleteInput
      label="mine.label.role"
      variant="outlined"
      fullWidth
      sx={{ display: "inline-flex" }}
      optionValue="v0"
      optionText="v1"
      filterToQuery={filterToQuery}
    />
  </ReferenceInput>,
  <TextInput
    source="v1"
    label="mine.label.permission"
    resettable
    variant="outlined"
    alwaysOn
  />,
];

export default permissionFilterList;
