import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import {
  Datagrid,
  NumberField,
  RecordContextProvider,
  TextField,
  WrapperField,
  useDataProvider,
  useTranslate,
} from "react-admin";
import { useMutation } from "react-query";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const TabForm = ({ schema, ...props }) => {
  const t = useTranslate();
  const [journalData, setJournalData] = useState([]);
  const [value, setValue] = useState(0);
  const dataProvider = useDataProvider();
  const [cdpi, coai, actionType] = useWatch({
    name: ["bad_debt_installment_id", "chart_of_account_id", "type"],
  });
  const { data, mutate } = useMutation((prm) => {
    return dataProvider.getListNoParams("bad-debt-installments/" + prm);
  });
  const {
    data: coaData,
    mutate: coaMutate,
    // isLoading: coaIsLoading,
  } = useMutation((prm) => {
    return dataProvider.getListNoParams("coa/" + prm);
  });
  useEffect(() => {
    if (cdpi !== undefined) {
      mutate(encodeURIComponent(cdpi));
    }
  }, [cdpi]);
  useEffect(() => {
    if (coai !== undefined) {
      coaMutate(encodeURIComponent(coai));
    }
  }, [coai]);
  useEffect(() => {
    if (data && coaData && actionType === 1) {
      // masuk sini artinya typenya pencairan
      let jrnl = [];
      let temp = {};
      for (let i = 0; i < data.bad_debt_installment_journals.length; i++) {
        const elm = data.bad_debt_installment_journals[i];
        if (elm.type === 1) {
          // 1 artinya jenisnya debit
          //dapatkan coa NOTES RECEIVABLE - BANK
          temp.code = elm.chart_of_account.code;
          temp.name = elm.chart_of_account.name;
          break;
        }
      }
      for (let i = 0; i < data.bad_debt_installment_items.length; i++) {
        const elm = data.bad_debt_installment_items[i];
        jrnl.push({
          code: coaData.code,
          name: coaData.name,
          debit: elm.amount,
          credit: "-",
        });
        jrnl.push({
          ...temp,
          debit: "-",
          credit: elm.amount,
        });
      }
      setJournalData(jrnl);
    } else if (data && (actionType === 2 || actionType === 3)) {
      // masuk sini artinya typenya selain pencairan
      let jrnl = [];
      for (let i = 0; i < data.bad_debt_installment_items.length; i++) {
        const elmI = data.bad_debt_installment_items[i];

        for (let i = 0; i < data.bad_debt_installment_journals.length; i++) {
          const elmJ = data.bad_debt_installment_journals[i];
          let deb, cre;
          if (elmJ.type === 1) {
            // type 1 artinya debit maka di balik yg diisi creditnya
            deb = "-";
            cre = elmI.amount;
          } else if (elmJ.type === 2) {
            // type 2 artinya credit maka di balik yg diisi debitnya
            deb = elmI.amount;
            cre = "-";
          }

          jrnl.push({
            code: elmJ.chart_of_account.code,
            name: elmJ.chart_of_account.name,
            debit: deb,
            credit: cre,
          });
        }
      }
      setJournalData(jrnl);
    } else if (!coaData) {
      setJournalData([]);
    }
  }, [data, coaData, actionType]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={t("mine.label.payment")}
            sx={value === 0 ? { bgcolor: "grey.200" } : {}}
          />
          <Tab
            label={t("mine.label.journal")}
            sx={value === 1 ? { bgcolor: "grey.200" } : {}}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Datagrid
          data={data ? data.bad_debt_installment_items : undefined}
          sort={{ field: "id", order: "ASC" }}
        >
          <TextField
            source="bad_debt_item.sale.code"
            label="mine.label.sales_number"
          />
          <WrapperField label="Bank name">
            <RecordContextProvider value={data}>
              <TextField source="bank_name" />
            </RecordContextProvider>
          </WrapperField>
          <NumberField source="amount" />
        </Datagrid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Datagrid data={journalData} sort={{ field: "id", order: "ASC" }}>
          <TextField source="code" />
          <TextField source="name" />
          <NumberField source="debit" />
          <NumberField source="credit" />
        </Datagrid>
      </TabPanel>
    </Box>
  );
};

export default TabForm;
