import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function CustomerAddressForm() {
  return (
    <ArrayInput
      source="customer_addresses"
      defaultValue={[
        {
          location_name: "",
          province_id: "",
          city_id: "",
          postal_code: "",
          detail: "",
          phone: "",
          fax: "",
        },
      ]}
    >
      <SimpleFormIterator getItemLabel={(index) => `#${index + 1}`}>
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
