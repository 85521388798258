import { TextInput, maxLength, minLength, required } from "react-admin";
import { useWatch } from "react-hook-form";

const validateDeliveryNote = [required(), minLength(10), maxLength(15)];

const DeliveryNote = (props) => {
  const dependOnType = useWatch({ name: "type" });
  return dependOnType === 2 ? (
    <TextInput
      variant="outlined"
      source="delivery_note"
      label="mine.label.ref_number"
      fullWidth
      validate={validateDeliveryNote}
      {...props}
    />
  ) : null;
};

export default DeliveryNote;
