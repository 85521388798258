import { choices, number, RadioButtonGroupInput, required } from "react-admin";

const validateType = [
  required(),
  number(),
  choices([1, 2], "Please choose one of the values"),
];

const RadioGroupX = ({ choices, ...props }) => {
  return (
    <RadioButtonGroupInput
      fullWidth
      source="type"
      label="mine.label.type"
      parse={(value) => (value === "" ? null : +value)}
      // format={(value) => (value == null ? "" : +value)}
      // defaultValue={1}
      choices={choices}
      validate={validateType}
      {...props}
    />
  );
};

export default RadioGroupX;
