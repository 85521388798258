import { memo, useEffect } from "react";
import {
  NumberInput,
  number,
  required,
  useCreate,
  useResourceContext,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { apiUrl, httpClient } from "../../../dataProvider";

// untuk menampilkan error saat blur krna validasi pake create()
// hanya muncul saat fokus(karena mode form nya onBlur)
const validateQuantityOnBlur = async (values, items, thisForm) => {
  let datax = {
    cogs_total_items: [],
  };
  for (let i = 0; i < items.mutation_inventory_write_off_items.length; i++) {
    const elm = items.mutation_inventory_write_off_items[i];
    if (elm.quantity) {
      datax.cogs_total_items.push({
        product_id: elm.product_id,
        quantity: elm.quantity,
      });
    }
  }
  // create(resource + "/cogs-total", { data: datax });
  const sources = thisForm.source.split("."); // index 1 adalah index item
  return httpClient(apiUrl + "/mutation-inventory-write-offs/cogs-total", {
    method: "POST",
    body: JSON.stringify(datax),
  })
    .then(() => undefined)
    .catch((e) => {
      return e.body.errors.cogs_total_items[+sources[1]].quantity;
    });
};

const validateQuantity = [required(), number(), validateQuantityOnBlur];

const QuantityInput = ({ quantity, formData, productSource, ...props }) => {
  const { setValue, setError, clearErrors } = useFormContext();
  const resource = useResourceContext();
  const [me, productId] = useWatch({ name: [props.source, productSource] });
  const [create, { data, isLoading, error }] = useCreate();

  useEffect(() => {
    if (me !== "") {
      let datax = {
        cogs_total_items: [],
      };
      for (
        let i = 0;
        i < formData.mutation_inventory_write_off_items.length;
        i++
      ) {
        const elm = formData.mutation_inventory_write_off_items[i];
        if (elm.quantity) {
          datax.cogs_total_items.push({
            product_id: elm.product_id,
            quantity: elm.quantity,
          });
        }
      }
      create(resource + "/cogs-total", { data: datax });
    }
  }, [me, resource, create, formData.mutation_inventory_write_off_items]);

  useEffect(() => {
    if (data) {
      setValue("total", data.total, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [data, setValue]);

  useEffect(() => {
    if (error && !isLoading) {
      setValue("total", undefined);
      for (let i = 0; i < error.body.errors.cogs_total_items.length; i++) {
        const obj = error.body.errors.cogs_total_items[i];
        if (!obj.quantity) continue;
        setError(
          props.source,
          {
            type: "manual",
            message: obj.quantity,
          },
          { shouldFocus: true }
        );
      }
    } else {
      clearErrors(props.source);
    }
  }, [error, isLoading, props.source, setValue, setError, clearErrors]);

  useEffect(() => {
    if (!productId) {
      setValue(props.source, "");
    }
  }, [productId]);

  return (
    <NumberInput
      validate={validateQuantity}
      {...props}
      inputProps={{ type: "text", inputMode: "numeric", pattern: "[0-9]*" }}
    />
  );
};

// const propsAreEqual = (prevProps, nextProps) => {
//   if (
//     prevProps.quantity === nextProps.quantity &&
//     prevProps.source === nextProps.source
//   ) {
//     // tidak perlu render ulang
//     return true;
//   }
//   // perlu dirender ulang
//   return false;
// };

export default memo(QuantityInput);
