import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreate,
  useNotify,
  SimpleForm,
  Create,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  minLength,
  TextInput,
  DateInput,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import SaleItemForm from "./components/SaleItemForm";
import QuantityInput from "../common/QuantityInput";
import TotalInput from "../common/TotalInput";
import InputAdornment from "@mui/material/InputAdornment";
import currentDate from "../../../utils/currentDate";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";
import sanitizeData from "../../../utils/sanitizeData";
import CodeNumberInput from "../../common/CodeNumberInput";
import { OptionRendererTransport, filterToQueryTransport, inputTextTransport, matchSuggestionTransport } from "../common/OptionRendererTransport";

const validateNumber = [required(), minLength(13), maxLength(13)];
const validateDate = [required(), minLength(10), maxLength(10)];
const validateCustomer = [required(), minLength(36), maxLength(36)];
const validateTotalTransport = [number()];
// const validateNote = [maxLength(120)];
const validatePackage = [minLength(36), maxLength(36)];

export default function SaleAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const translate = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: sanitizeData(values) },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body) {
          if (error.body.errors) {
            if (error.body.errors === "403") {
              // munculkan notifikasi erro ketika sudah approved
              notify("mine.notification.403_error", { type: "error" });
            } else {
              // The shape of the returned validation errors must match the shape of the form
              return error.body.errors;
            }
          } else if (error.status === 500) {
            notify(error.message, { type: "error" });
          }
        } else if (error.status === 500) {
          notify(error.message, { type: "error" });
        }
      }
    },
    [create]
  );

  const handleClose = (e, reason) => {
    setOpen(false);
    // if (reason !== "backdropClick") {
    // }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      fullWidth={true}
      maxWidth={false}
    >
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{translate("mine.dialog.create_sale")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ alignSelf: "normal" }}>
            <CodeNumberInput
              open={open}
              dependOn="date"
              variant="outlined"
              source="code"
              label="mine.label.code"
              fullWidth
              validate={validateNumber}
              disabled
            />

            <DateInput
              variant="outlined"
              source="date"
              label="mine.label.date"
              fullWidth
              isRequired
              validate={validateDate}
              defaultValue={currentDate()}
            />

            <ReferenceInput
              source="sale_order_id"
              reference="sale-orders"
              perPage={20}
              sort={{ field: "sale_orders.created_at", order: "ASC" }}
              filter={{ "inventory_pendings.status": 1 }}
            >
              <AutocompleteInput
                // label="mine.label.sale_order"
                variant="outlined"
                fullWidth
                validate={validateCustomer}
                optionText="code"
              />
            </ReferenceInput>

            <ReferenceInput
              source="transport_id"
              label="mine.label.transport"
              reference="transports"
              perPage={10}
              sort={{ field: "transports.created_at", order: "ASC" }}
            >
              <AutocompleteInput
                variant="outlined"
                fullWidth
                validate={validateCustomer}
                optionText={<OptionRendererTransport />}
                inputText={inputTextTransport}
                matchSuggestion={matchSuggestionTransport}
                filterToQuery={filterToQueryTransport}
                helperText="Sebelom release pastikan semua depo angkutan 'Ambil Sendiri' kodenya T000"
              />
            </ReferenceInput>

            <QuantityInput
              variant="outlined"
              source="total_transport"
              label="mine.label.total_vehicle"
              fullWidth
              validate={validateTotalTransport}
              helperText="mine.message.transport_according_to"
              defaultValue={1}
              disabled
              sx={{ display: "none" }}
            />

            <ReferenceInput
              source="promotion_id"
              reference="promotions"
              sort={{ field: "created_at", order: "ASC" }}
              filter={{ is_effective: true }}
            >
              <AutocompleteInput
                variant="outlined"
                label="mine.label.package"
                fullWidth
                validate={validatePackage}
                optionValue="id"
                optionText="name"
                filterToQuery={(searchText) => ({
                  name: searchText,
                })}
              />
            </ReferenceInput>

            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              fullWidth
              multiline
              rows={3}
              // validate={validateNote}
            />

            <SaleItemForm />

            <TotalInput
              variant="outlined"
              source="total"
              fullWidth
              disabled
              defaultValue={0}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
                // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
              }}
              {...parserFormatNumInput}
              helperText="Abaikan nilai yang tertera jika menggunakan 'Paket' promo, harga akan di hitung ulang saat save data"
            />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
