import { memo } from "react";
import { TextInput } from "react-admin";

const PhoneInput = ({ phone, ...props }) => (
  <TextInput {...props} />
);

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.phone === nextProps.phone &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(PhoneInput, propsAreEqual);
