import { useEffect } from "react";
import { TextInput } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";

const SubtotalInput = ({
  dependOnPrice,
  dependOnQty,
  dependOnDisc,
  dependOnSpcDisc,
  dependOnTransportDisc, // ini kya tdk berguna tp penting untuk mentrigger useEffect
  ...props
}) => {
  // const dataProvider = useDataProvider();
  const { setValue, getValues } = useFormContext();
  const dependQty = useWatch({ name: dependOnQty });
  const dependDisc = useWatch({ name: dependOnDisc });
  const dependSpcDisc = useWatch({ name: dependOnSpcDisc });
  const dependTransportDisc = useWatch({ name: dependOnTransportDisc });
  const me = useWatch({ name: props.source });

  useEffect(() => {
    if (dependQty) {
      let allDiscount = 0;
      if (dependDisc) {
        allDiscount += +dependDisc;
      }
      if (dependSpcDisc) {
        allDiscount += +dependSpcDisc;
      }
      const price = getValues(dependOnPrice);
      let deducted = 0;
      if (price) {
        deducted = +price - allDiscount;
        let subtotal = deducted * +dependQty;
        if (dependTransportDisc) {
          // karena nilai dependTransportDisc sudah dikali qty
          // maka dependTransportDisc digunakan untuk mengurangi subtotal
          // yang juga sdh di kali qty, dependTransportDisc bukan untuk mengurangi harga / box
          subtotal -= +dependTransportDisc;
        }
        setValue(props.source, subtotal);
      }
      // dua line ini di matikan dan dipindh kedalam if (price)
      // krna saat pertama form edit muncul kolom subtotal 0
      // const subtotal = deducted * +dependQty;
      // setValue(props.source, subtotal);
    } else {
      setValue(props.source, 0);
    }
  }, [
    dependQty,
    dependDisc,
    dependSpcDisc,
    dependTransportDisc,
    dependOnPrice,
  ]);

  useEffect(() => {
    var subT = document.querySelectorAll(".sub-total input");
    // var transDisc = document.querySelectorAll(".transport-disc input");
    if (me || subT.length > 1) {
      let total = 0;
      // let transportDisc = 0;
      // if (dependTransportDisc && !isNaN(dependTransportDisc)) {
      //   transportDisc = +dependTransportDisc;
      // }
      for (let i = 0; i < subT.length; i++) {
        const elm = subT[i];
        if (elm.value) {
          const digitOnly = elm.value.replace(/[^0-9.]/g, "");
          total += +digitOnly;
        }

        // if (transDisc[i].value) {
        //   const digitOnly = transDisc[i].value.replace(/[^0-9.]/g, "");
        //   total -= +digitOnly;
        // }
      }
      setValue("total", total);
    } else {
      if (me !== undefined) {
        setValue("total", 0);
      }
    }
  }, [me]);

  return <TextInput {...props} />;
};

export default SubtotalInput;
