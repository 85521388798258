import { useRecordContext } from "react-admin";

export const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{record.code}</span>
      <span>{record.order_qty - record.sold_qty}</span>
    </div>
  );
};
export const inputText = (choice) => choice.code;
export const matchSuggestion = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return choice.code.toLowerCase().includes(filter.toLowerCase());
};
export const filterToQuery = (searchText) => ({ all: searchText });
