import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {
  useNotify,
  SimpleForm,
  TextInput,
  NumberInput,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  useUpdate,
  Edit,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import Transition from "../../transition/Transition";
import TransportItemForm from "../components/TransportItemForm";
import sanitizeData from "../../../utils/sanitizeData";

const validateCode = [required(), maxLength(20)];
const validateName = [required(), maxLength(125)];
const validateBrand = [maxLength(50)];
const validateCapacity = [number()];
const validatePlate = [maxLength(20)];

export default function TransportEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const resource = useResourceContext(); // cuman nama resource
  const refresh = useRefresh();
  const t = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: sanitizeData(values) },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          if (error.body.errors === "403") {
            // munculkan notifikasi erro ketika sudah approved
            notify("mine.notification.403_error", { type: "error" });
          } else {
            // The shape of the returned validation errors must match the shape of the form
            return error.body.errors;
          }
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      TransitionComponent={Transition}
    >
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{t("mine.dialog.edit_transport")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <TextInput
              variant="outlined"
              source="code"
              label="mine.label.code"
              fullWidth
              isRequired
              validate={validateCode}
            />

            <TextInput
              variant="outlined"
              source="name"
              label="mine.label.name"
              fullWidth
              isRequired
              validate={validateName}
            />
            <TextInput
              variant="outlined"
              source="brand"
              label="mine.label.brand"
              fullWidth
              validate={validateBrand}
            />
            <TextInput
              variant="outlined"
              source="type"
              label="mine.label.type"
              fullWidth
              validate={validateBrand}
            />
            <TextInput
              variant="outlined"
              source="kind"
              label="mine.label.kind"
              fullWidth
              validate={validateBrand}
            />
            <NumberInput
              variant="outlined"
              source="capacity"
              label="mine.label.capacity"
              fullWidth
              validate={validateCapacity}
            />
            <TextInput
              variant="outlined"
              source="plate"
              label="mine.label.plate"
              fullWidth
              validate={validatePlate}
            />

            <TransportItemForm />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
