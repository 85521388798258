// import { useCallback } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  // useCreate,
  // useNotify,
  // useRedirect,
} from "react-admin";

const RoleCreate = () => {
  // const [create] = useCreate();
  // const notify = useNotify();
  // const redirect = useRedirect();
  // const save = useCallback(
  //   async (values) => {
  //     try {
  //       await create(
  //         "users",
  //         { data: values },
  //         {
  //           returnPromise: true,
  //           onSuccess: (data) => {
  //             notify("ra.notification.created", {
  //               messageArgs: { smart_count: 1 },
  //             });
  //             redirect("list", "users", data.id, data);
  //           },
  //         }
  //       );
  //     } catch (error) {
  //       if (error.body.errors) {
  //         // The shape of the returned validation errors must match the shape of the form
  //         return error.body.errors;
  //       }
  //     }
  //   },
  //   [create]
  // );
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput
          source="v1"
          label="mine.label.name"
          variant="outlined"
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default RoleCreate;
