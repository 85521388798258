import { useMemo, useState } from "react";
import {
  DateField,
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BulkDeleteWithConfirmButton,
  usePermissions,
} from "react-admin";
import SalesAreaAddDialog from "../../components/salesAreas/create/SalesAreaAddDialog";
import SalesAreaEditDialog from "../../components/salesAreas/edit/SalesAreaEditDialog";
import EditBtn from "../../components/editBtn/EditBtn";
import salesAreaFilterList from "../../components/salesAreas/filter/salesAreaFilterList";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import SalesAreaUploadDialog from "../../components/salesAreas/upload/SalesAreaUploadDialog";

const SalesAreaList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={salesAreaFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <TextField label="Area" source="name" />
          <DateField
            source="created_at"
            label="mine.label.created_at"
            showTime
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <SalesAreaAddDialog open={openAdd} setOpen={setOpenAdd} />
      <SalesAreaEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      {openUpload ? (
        <SalesAreaUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
    </>
  );
};

export default SalesAreaList;
