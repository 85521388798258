import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Typography from "@mui/material/Typography";
import Inputs from "./Inputs";

export default function TransportItemForm() {
  return (
    <ArrayInput
      source="transport_costs"
      label={
        <Typography variant="h6" sx={{ width: "100%", whiteSpace: "normal" }}>
          Saat ini isian dibawah hanya berfugsi untuk memberi diskon pada opsi
          "Ambil Sendiri"
        </Typography>
      }
      defaultValue={
        [
          // {
          //   size_id: "",
          //   cost: "",
          //   cost_type: "",
          // },
        ]
      }
    >
      <SimpleFormIterator
        fullWidth
        getItemLabel={(index) => `#${index + 1}`}
        sx={{
          "& .RaSimpleFormIterator-action, & .RaSimpleFormIterator-action > .button-reorder":
            {
              display: "flex",
              flexDirection: "column",
            },
          "& .RaSimpleFormIterator-line": {
            mt: 3,
          },
        }}
      >
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
