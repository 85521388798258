import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  BooleanField,
  BulkDeleteWithConfirmButton,
  usePermissions,
} from "react-admin";
import SalesmanAddDialog from "../../components/salesmen/create/SalesmanAddDialog";
import SalesmanEditDialog from "../../components/salesmen/edit/SalesmanEditDialog";
import salesmanFilterList from "../../components/salesmen/filter/salesmanFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import SalesmanUploadDialog from "../../components/salesmen/upload/SalesmanUploadDialog";

const SalesmanList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={salesmanFilterList(permissions)}
        queryOptions={{ meta: { sort: ["join_on DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <TextField source="name" label="mine.label.name" />
          <BooleanField
            source="active"
            label="mine.label.active"
            textAlign="center"
          />
          <DateField source="join_on" label="mine.label.join_on" />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      {openAdd ? (
        <SalesmanAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openUpload ? (
        <SalesmanUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
      {openEdit ? (
        <SalesmanEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default SalesmanList;
