import { useWatch } from "react-hook-form";
import {
  required,
  maxLength,
  minLength,
  TextInput,
  DateInput,
} from "react-admin";

const validateDate = [
  //required(),
  minLength(10),
  maxLength(10),
];
const validateNoCheque = [
  //required(),
  maxLength(20),
];

const GiroInputs = () => {
  const dependOn = useWatch({ name: "type" });
  //   useEffect(() => {
  //     if (dependOn) {
  //       (async () => {
  //         const data = await dataProvider.getListNoParams(
  //           "coa/class-for-select?company_id=" + dependOn
  //         );
  //         setChoices(data);
  //       })();
  //     }
  //   }, [dependOn]);
  return dependOn === 3 ? (
    <>
      <DateInput
        variant="outlined"
        source="effective_date"
        fullWidth
        isRequired
        validate={validateDate}
      />
      <TextInput
        variant="outlined"
        source="giro"
        fullWidth
        isRequired
        validate={validateNoCheque}
      />
      <TextInput
        variant="outlined"
        source="bank_name"
        fullWidth
        isRequired
        validate={validateNoCheque}
      />
    </>
  ) : null;
};
export default GiroInputs;
