import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  TextInput,
} from "react-admin";

const allTransactionJournalFilterList = (permissions) => {
  const defaultFilter = [
    <TextInput
      alwaysOn
      source="code"
      resettable
      variant="outlined"
      label="mine.label.code"
    />,
    <DateInput
      alwaysOn
      source="start_date"
      variant="outlined"
      label="mine.label.start_date"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      //FIXME: semua DateInput tidak bisa di tambah resettable
      // resettable
      variant="outlined"
      label="mine.label.end_date"
    />,
    <TextInput
      alwaysOn
      source="cus_sup"
      resettable
      variant="outlined"
      label="mine.label.cus_sup"
    />,
    <BooleanInput alwaysOn source="is_balance" label="mine.label.balance" />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default allTransactionJournalFilterList;
