import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const priceLimitFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    variant="outlined"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    alwaysOn
    source="customer_type_id"
    reference="customer-types"
    perPage={10}
    sort={{ field: "created_at", order: "ASC" }}
  >
    <AutocompleteInput
      variant="outlined"
      optionText="name"
      label="resources.customer_type.name"
    />
  </ReferenceInput>,
  <NumberInput
    source="price"
    label="Limit"
    variant="outlined"
    resettable
    alwaysOn
  />,
  <TextInput
    source="note"
    label="mine.label.note"
    variant="outlined"
    resettable
    alwaysOn
  />,
];

export default priceLimitFilterList;
