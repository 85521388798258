import {
  AutocompleteInput,
  ReferenceInput,
  maxLength,
  minLength,
  required,
} from "react-admin";
import {
  filterToQuery,
  inputText,
  matchSuggestion,
  OptionRenderer,
} from "../../customerDebtPayments/common/CustomSelectOption";
import { useWatch } from "react-hook-form";

const validateCOA = [required(), minLength(36), maxLength(36)];

const PickCOA = () => {
  const dependOnType = useWatch({ name: "type" });
  return dependOnType === 1 ? (
    <ReferenceInput
      source="chart_of_account_id"
      reference="coa"
      perPage={20}
      sort={{ field: "chart_of_accounts.code", order: "ASC" }}
      filter={{ all: "1102" }}
    >
      <AutocompleteInput
        label="mine.label.coa_code"
        variant="outlined"
        fullWidth
        sx={{ display: "inline-flex" }}
        validate={validateCOA}
        optionValue="id"
        // optionText="code"
        optionText={<OptionRenderer />}
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        filterToQuery={filterToQuery}
      />
    </ReferenceInput>
  ) : null;
};

export default PickCOA;
