// FIXME: semua form yg perlu sanitize pake code ini,
// hnya saja blom bisa handle child object
// kalo child array of object sudah bisa
// fungsinya untuk menghilangkan object key
// yg bernilai null atau empty string
const sanitizeData = (data) => {
  const sanitizedData = {};
  for (const key in data) {
    if (typeof data[key] === "string" && data[key].trim().length === 0)
      continue;
    if (data[key] === null) continue;
    // cek child
    if (Array.isArray(data[key])) {
      sanitizedData[key] = [];
      for (let i = 0; i < data[key].length; i++) {
        const elm = data[key][i];
        sanitizedData[key][i] = sanitizeData(elm);
      }
      // cek child
    } else {
      sanitizedData[key] = data[key];
    }
  }
  return sanitizedData;
};
export default sanitizeData;
