import Tooltip from "@mui/material/Tooltip";
import { cloneElement, forwardRef } from "react";
import { useRecordContext } from "react-admin";

// TooltipWrapper gets initial TooltipField props without Tooltip props
// and passes them to children (an input component in this example)
const TooltipWrapper = forwardRef(({ children, ...props }, ref) => (
  <div ref={ref}>{cloneElement(children, props)}</div>
));

// TooltipField gets explicit props and props added by SimpleForm
// and passes them together to the Tooltip.
const TooltipField = ({ children, source, ...props }) => {
  const record = useRecordContext();
  return (
    <Tooltip {...props} title={record[source]}>
      <TooltipWrapper>{children}</TooltipWrapper>
    </Tooltip>
  );
};

export default TooltipField;
