import {
  Button,
  ExportButton,
  FilterButton,
  SelectColumnsButton,
  TopToolbar,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const TableListActionsWithUpload = ({
  createHandler,
  exporter,
  uploadHandler,
}) => {
  return (
    <TopToolbar
      sx={{
        minHeight: "54px !important",
        mb: 1,
        "& .MuiButtonBase-root": {
          border: "0.1rem solid",
        },
      }}
    >
      <SelectColumnsButton />
      <FilterButton />
      {createHandler ? (
        <Button
          startIcon={<AddIcon />}
          label="ra.action.create"
          sx={{ width: "fit-content" }}
          onClick={createHandler}
        />
      ) : null}
      {exporter === false ? null : <ExportButton />}
      {uploadHandler ? (
        <Button
          startIcon={<UploadFileIcon />}
          label="mine.label.upload"
          sx={{ width: "fit-content" }}
          onClick={uploadHandler}
        />
      ) : null}
    </TopToolbar>
  );
};

export default TableListActionsWithUpload;
