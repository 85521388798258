import { SaveButton, Toolbar } from "react-admin";
import DeleteWithConfirm from "../deleteWithConfirms/DeleteWithConfirm";

const SaveDeleteBtns = ({ callback }) => {
  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <SaveButton />
      {callback && <DeleteWithConfirm callback={callback} />}
    </Toolbar>
  );
};

export default SaveDeleteBtns;
