import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  EditButton,
  DeleteWithConfirmButton,
  TopToolbar,
  CreateButton,
} from "react-admin";

const RoleList = () => {
  return (
    <List
      empty={false}
      actions={
        <TopToolbar
          sx={{
            minHeight: "54px !important",
            mb: 1,
            "& .MuiButtonBase-root": {
              border: "0.1rem solid",
            },
          }}
        >
          <CreateButton variant="outlined" sx={{ py: "4px" }} />
        </TopToolbar>
      }
      // filters={userFilterList}
      // queryOptions={{ meta: { sort: ["id ASC"] } }} // overide default sort
      sort={{ field: "casbin_rule.id", order: "ASC" }}
      // filter={{ ptype: "g" }}
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        sx={{ "& .actions-column": { width: "222px" } }}
      >
        <TextField source="v1" label="Name" />
        <WrapperField
          textAlign="center"
          label="Actions"
          headerClassName="actions-column"
        >
          <EditButton sx={{ mr: 1 }} />
          <DeleteWithConfirmButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export default RoleList;
