import { useEffect } from "react";
import { TextInput } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const SubTotal = ({ source, dependOn }) => {
  const [price, qty] = useWatch({ name: dependOn });

  const { setValue } = useFormContext();
  useEffect(() => {
    if (price && qty) {
      setValue(source, price * qty);
    } else {
      setValue(source, "");
    }
  }, [price, qty]);
  return (
    <TextInput
      variant="outlined"
      source={source}
      label="Subtotal"
      fullWidth
      InputProps={{
        startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
      }}
      helperText={false}
      {...parserFormatNumInput}
    />
  );
};
export default SubTotal;
