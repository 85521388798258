import { TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const kindFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    variant="outlined"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
];

export default kindFilterList;
