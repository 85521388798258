import { memo } from "react";
import { NumberInput } from "react-admin";

const PostalCodeInput = ({ postalCode, ...props }) => (
  <NumberInput {...props} />
);

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.postalCode === nextProps.postalCode &&
    prevProps.source === nextProps.source
    ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(PostalCodeInput, propsAreEqual);
