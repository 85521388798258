import { useEffect, useState } from "react";
import { AutocompleteInput, useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";

export default function ClassInput(props) {
  const [choices, setChoices] = useState([]);
  const dataProvider = useDataProvider();
  const dependOn = useWatch({ name: "company_id" });
  useEffect(() => {
    if (dependOn) {
      (async () => {
        const data = await dataProvider.getListNoParams(
          "coa/class-for-select?company_id=" + dependOn
        );
        setChoices(data);
      })();
    }
  }, [dependOn]);
  return (
    <AutocompleteInput
      onCreate={() => {
        const newCategoryName = prompt("Contoh penulisan: 2 - Liabilities");
        const split = newCategoryName.split("-");
        const num = +split[0].replace(/\D/g, "");
        const newCategory = {
          code: num,
          name: newCategoryName,
        };
        setChoices((prev) => [...prev, newCategory]);
        return newCategory;
      }}
      choices={choices}
      {...props}
    />
  );
}
