import { FunctionField, TextInput, maxLength, useTranslate } from "react-admin";

const ReasonInput = () => {
  const t = useTranslate();

  return (
    <>
      <FunctionField
        sx={{
          fontWeight: "500",
          textTransform: "uppercase",
          pb: "20px",
        }}
        render={(record) => (
          <>
            <span
              style={{
                backgroundColor: "#d32f2f",
                color: "#fff",
                padding: "1px 8px 0",
                borderRadius: "4px",
              }}
            >
              {t("mine.label.warning")}
            </span>
            {": "}
            <span>
              {t("mine.message.print_again", { count: record.count })}
            </span>
          </>
        )}
      />
      <TextInput disabled source="print_history_id" sx={{ display: "none" }} />
      <TextInput
        fullWidth
        source="reason"
        label="mine.label.reason"
        multiline
        rows={3}
        variant="outlined"
        validate={[maxLength(100)]}
      />
    </>
  );
};
export default ReasonInput;
