// const A = () => {
//   return " Welcome ";
// };
import * as React from "react";
import { Box, Card, CardActions, Button, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useTranslate } from "react-admin";

import publishArticleImage from "./welcome_illustration.svg";

const Welcome = () => {
  const translate = useTranslate();
  return (
    <Card
      sx={{
        background: (theme) =>
          `linear-gradient(45deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.light} 50%, ${theme.palette.primary.dark} 100%)`,
        color: (theme) => theme.palette.primary.contrastText,
        padding: "20px",
        marginTop: 2,
        marginBottom: "1em",
      }}
    >
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h5" component="h2" gutterBottom>
            {translate("mine.label.dashboard_title")}
          </Typography>
          <Box maxWidth="40em">
            <Typography variant="body1" component="p" gutterBottom>
              {translate("mine.label.dashboard_subtitle")}
            </Typography>
          </Box>
          <CardActions
            sx={{
              padding: { xs: 0, xl: null },
              flexWrap: { xs: "wrap", xl: null },
              "& a": {
                marginTop: { xs: "1em", xl: null },
                marginLeft: { xs: "0!important", xl: null },
                marginRight: { xs: "1em", xl: null },
              },
            }}
          >
            <Button
              variant="contained"
              href="https://wa.me/+6287782280505"
              target="_blank"
              startIcon={<WhatsAppIcon />}
            >
              {translate("mine.label.dashboard_contact_dp_support")}
            </Button>
            <Button
              variant="contained"
              href="https://wa.me/+6287782280505"
              target="_blank"
              startIcon={<WhatsAppIcon />}
            >
              {translate("mine.label.dashboard_contact_it")}
            </Button>
          </CardActions>
        </Box>
        <Box
          display={{ xs: "none", sm: "none", md: "block" }}
          sx={{
            background: `url(${publishArticleImage}) top right / cover`,
            marginLeft: "auto",
          }}
          width="16em"
          height="9em"
          overflow="hidden"
        />
      </Box>
    </Card>
  );
};

export default Welcome;
