import { Login } from "react-admin";
// import "./LoginPage.module.css"; // sengaja cuma di import biasa saja biar ga ribet
import classes from "./LoginPage.module.css";

const LoginPage = () => {
  return (
    <div className={classes.container}>
      <Login
        sx={{ backgroundPosition: "bottom" }}
        backgroundImage="https://s3-us-west-2.amazonaws.com/s.cdpn.io/221808/sky.jpg"
      ></Login>
      {Array.from(Array(90)).map((a, b) => (
        <div key={b} className={classes.circleContainer}>
          <div className={classes.circle}></div>
        </div>
      ))}
    </div>
  );
};
export default LoginPage;
