import { memo, useEffect, useState } from "react";
import { AutocompleteInput, useDataProvider } from "react-admin";

const CityInput = ({ provinceId, ...props }) => {
  const [cities, setCities] = useState([]);
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (provinceId) {
      (async () => {
        const data = await dataProvider.cityList({
          provinceId,
        });
        setCities(data);
      })();
    } else if (provinceId === null) {
      setCities([]);
    }
  }, [provinceId]);
  return <AutocompleteInput choices={cities} {...props} />;
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.provinceId === nextProps.provinceId &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(CityInput, propsAreEqual);
