import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const transportFilterList = (permissions) => {
  const defaultFilter = [
    <TextInput
      source="all"
      label="mine.filter.search_all"
      alwaysOn
      resettable
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      source="code"
      label="mine.label.code"
      variant="outlined"
      alwaysOn
    />,
    <TextInput
      source="name"
      label="mine.label.name"
      variant="outlined"
      alwaysOn
    />,
    <TextInput
      source="brand"
      label="mine.label.brand"
      variant="outlined"
      alwaysOn
    />,
    <TextInput
      source="type"
      label="mine.label.type"
      variant="outlined"
      alwaysOn
    />,
    <TextInput
      source="kind"
      label="mine.label.kind"
      variant="outlined"
      alwaysOn
    />,
    <NumberInput
      source="capacity"
      label="mine.label.capacity"
      variant="outlined"
      alwaysOn
    />,
    <TextInput
      source="plate"
      label="mine.label.plate"
      variant="outlined"
      alwaysOn
    />,
  ];

  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        label="mine.label.company"
        sort={{ field: "companies.alias", order: "ASC" }}
        resettable
      >
        <AutocompleteInput optionText="alias" variant="outlined" label="Depo" />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default transportFilterList;
