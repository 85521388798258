import {
  DateField,
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
} from "react-admin";
import Box from "@mui/material/Box";

const JournalExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <TabbedShowLayout.Tab
        label={
          t("mine.tbl_header.journal") + (record.transactions.length ? "" : "*")
        }
      >
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>{t("mine.tbl_header.coa")}</th>
              <th>{t("mine.tbl_header.coa_name")}</th>
              <th style={{ textAlign: "right" }}>
                {t("mine.tbl_header.debit")}
              </th>
              <th style={{ textAlign: "right" }}>
                {t("mine.tbl_header.credit")}
              </th>
            </tr>
          </thead>
          <tbody>
            {record.transactions.map((item, idx) => {
              return (
                <tr key={item.id}>
                  <td>{item.coa.code}</td>
                  <td>{item.coa.name}</td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="debit"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="credit"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
            {!record.transactions.length
              ? record.journal_items.map((item, idx) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.chart_of_account.code}</td>
                      <td>{item.chart_of_account.name}</td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={item}>
                          <NumberField
                            source="debit"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={item}>
                          <NumberField
                            source="credit"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
};

export default JournalExpandList;
