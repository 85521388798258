import { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useDataProvider,
  useUpdate,
  useNotify,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  Edit,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import CityInput from "../../common/CityInput";

const validateName = [required(), maxLength(100)];
const validateProvince = [required(), number()];
const validateDetail = [required(), maxLength(255)];
const validatePhone = [required(), maxLength(20)];
const validateFax = [maxLength(20)];
const validateBank = [maxLength(30)];
const validateBankAccName = [maxLength(50)];
const validateBankAccNumber = [maxLength(30)];

export default function CompanyEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  // GET PROVINCES LISTS
  const {
    data: provinces,
    isLoading: isLoadingProv,
    error,
  } = useQuery(["provinces", "provinceList"], () =>
    dataProvider.provinceList("provinces")
  );
  // GET PROVINCES LISTS

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>Edit Company</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <TextInput
              variant="outlined"
              source="name"
              label="PT Name"
              fullWidth
              isRequired
              validate={validateName}
            />

            <TextInput
              variant="outlined"
              source="alias"
              label="Depo Name"
              fullWidth
              isRequired
              validate={validateName}
            />

            <AutocompleteInput
              variant="outlined"
              source="province_id"
              isRequired
              choices={isLoadingProv ? [] : provinces}
              fullWidth
              validate={validateProvince}
            />

            <CityInput
              variant="outlined"
              source="city_id"
              isRequired
              fullWidth
              validate={validateProvince}
            />

            <TextInput
              variant="outlined"
              source="detail"
              label="Address"
              fullWidth
              isRequired
              multiline
              minRows={3}
              maxRows={3}
              validate={validateDetail}
            />

            <TextInput
              variant="outlined"
              source="phone"
              fullWidth
              isRequired
              validate={validatePhone}
            />
            <TextInput
              variant="outlined"
              source="fax"
              fullWidth
              validate={validateFax}
            />
            <TextInput
              variant="outlined"
              source="bank"
              fullWidth
              validate={validateBank}
            />
            <TextInput
              variant="outlined"
              source="bank_acc_name"
              label="Bank Account Name"
              fullWidth
              validate={validateBankAccName}
            />
            <TextInput
              variant="outlined"
              source="bank_acc_number"
              label="Bank Account Number"
              fullWidth
              validate={validateBankAccNumber}
            />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
