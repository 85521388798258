import { forwardRef, Fragment } from "react";
import Box from "@mui/material/Box";
import classes from "./PrintContent.module.css";
import Typography from "@mui/material/Typography";
import {
  DateField,
  NumberField,
  RecordContextProvider,
  usePermissions,
} from "react-admin";
import splitArrayToChunks from "../../utils/splitArrayToChunks";

const PrintContent = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  const { permissions, isLoading } = usePermissions();

  let totalMutation = 0;
  return isLoading ? null : (
    <Box
      component="div"
      sx={{
        display: "none",
      }}
    >
      <Box
        ref={ref}
        sx={{
          fontSize: "12px",
          py: 2,
          px: 2,
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 40px)",
        }}
      >
        <style type="text/css" media="print">
          {`@page {
              width: 215.9mm;
              height: 139.7mm;
              size: 8.5in 5.5in portrait;
              margin: 0;
            }
            html, body {
              width: 215.9mm;
              height: 100%;
            }
            .page-break {
              display: block;
              page-break-after: always;
            }
          `}
        </style>
        {splitArrayToChunks(props.record.journal_items, 7).map(
          (arr, i, ori) => {
            return (
              <Fragment key={i}>
                <Box sx={i > 0 ? { mt: 2 } : {}}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        mb: 0.5,
                        fontFamily: "'Source Code Pro', monospace",
                      }}
                    >
                      {props.record.company.alias}
                    </Typography>
                    <div style={{ fontSize: "10px" }}>
                      {i + 1}/{ori.length}
                    </div>
                  </div>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontSize: "18px",
                      mt: 0,
                      mb: 0.5,
                      lineHeight: 1,
                      fontFamily: "'Source Code Pro', monospace",
                    }}
                  >
                    BUKTI PENGELUARAN KAS/BANK
                  </Typography>
                  <Box
                    component="table"
                    className={classes.headerSj}
                    sx={{
                      "& th.no-wrap": {
                        whiteSpace: "nowrap",
                      },
                      "& th": {
                        verticalAlign: "top",
                      },
                    }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ width: "80%" }}></th>
                        <th className="no-wrap">Tanggal</th>
                        <th>:</th>
                        <th className="no-wrap">
                          <RecordContextProvider value={props.record}>
                            <DateField
                              source="date"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "normal",
                                fontSize: "12px",
                              }}
                            ></DateField>
                          </RecordContextProvider>
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ width: "80%" }}></th>
                        <th className="no-wrap">No. Voucher</th>
                        <th>:</th>
                        <th
                          className="no-wrap"
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "normal",
                          }}
                        >
                          {props.record.code}
                        </th>
                      </tr>
                      <tr>
                        <th style={{ height: "6px" }}></th>
                      </tr>
                      <tr>
                        <th className="no-wrap" style={{ marginTop: "6px" }}>
                          Keterangan
                        </th>
                        <th>:</th>
                        <th colSpan={4}>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "normal",
                            }}
                          >
                            {props.record.note}
                          </span>
                        </th>
                      </tr>
                    </thead>
                  </Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "13px",
                      mt: 1,
                      mb: 1,
                      fontFamily: "'Source Code Pro', monospace",
                    }}
                  >
                    Detail Jurnal
                  </Typography>
                </Box>
                <Box sx={{ height: "100%", flex: 1 }}>
                  <Box
                    component="table"
                    className={classes.tableSj}
                    sx={{
                      width: "100%",
                      // height: "100%",
                      "& th.no-wrap": {
                        whiteSpace: "nowrap",
                      },
                      "& th": {
                        verticalAlign: "top",
                      },
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "12%" }}>Acc. Code</th>
                        <th style={{ width: "50%" }}>Account Name</th>
                        <th style={{ width: "19%", textAlign: "right" }}>
                          Debits
                        </th>
                        <th style={{ width: "19%", textAlign: "right" }}>
                          Credits
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {arr.map((obj, idx) => {
                        totalMutation += obj.debit * 1;
                        return (
                          <tr key={obj.id}>
                            <td
                              className={classes.noWrap}
                              style={{
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                              }}
                            >
                              {obj.chart_of_account.code}
                            </td>
                            <td
                              style={{
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {obj.chart_of_account.name}
                              </p>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                              }}
                            >
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="debit"
                                  sx={{
                                    fontFamily: "'Source Code Pro', monospace",
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                              }}
                            >
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="credit"
                                  sx={{
                                    fontFamily: "'Source Code Pro', monospace",
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "none",
                          }}
                        >
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="total"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "inherit",
                                fontSize: "12px",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "none",
                          }}
                        >
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="total"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "inherit",
                                fontSize: "12px",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                      </tr>
                    </tfoot>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1.5,
                    fontSize: "10px",
                    textTransform: "capitalize",
                    fontFamily: "'Source Code Pro', monospace",
                  }}
                >
                  {"Terbilang: " + props.record.total_in_words + " Rupiah"}
                </Typography>
                {permissions.accounting ? (
                  <Box
                    className={classes.code}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                      // flex: 1,
                      // height: "100%",
                      // alignItems: "flex-end"
                    }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="inherit" sx={{ mb: 5 }}>
                        Disetujui Oleh,
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItem: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Box sx={{ mr: 1 }}>
                          <Typography variant="caption">
                            ( ...................... )
                          </Typography>
                          <Typography variant="inherit">COMMISIONER</Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption">
                            ( ...................... )
                          </Typography>
                          <Typography variant="inherit">DIRECTOR</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="inherit" sx={{ mb: 5 }}>
                        Diketahui Oleh,
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItem: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Box sx={{ mr: 1 }}>
                          <Typography variant="caption">
                            ( ...................... )
                          </Typography>
                          <Typography variant="inherit">MANAGER</Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption">
                            ( ...................... )
                          </Typography>
                          <Typography variant="inherit">FINANCE</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="inherit" sx={{ mb: 5 }}>
                        Dibayar Oleh,
                      </Typography>
                      <Box>
                        <Typography variant="caption">
                          ( ...................... )
                        </Typography>
                        <Typography variant="inherit">ACCOUNTING</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="inherit" sx={{ mb: 5 }}>
                        Penerima
                      </Typography>
                      <Box>
                        <Typography variant="caption">
                          ( ...................... )
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    component="table"
                    className={classes.tableSj}
                    sx={{
                      width: "100%",
                      height: "100px",
                      "& th.no-wrap": {
                        whiteSpace: "nowrap",
                      },
                      "& th": {
                        verticalAlign: "top",
                      },
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan={2}
                          style={{
                            textAlign: "center",
                            padding: "3px 0",
                            height: "10px",
                          }}
                        >
                          Mengetahui
                        </th>
                        <th
                          colSpan={2}
                          style={{
                            textAlign: "center",
                            padding: "3px 0",
                            height: "10px",
                          }}
                        >
                          Pelaksana
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "3px 0",
                            height: "10px",
                          }}
                        >
                          Penerima
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            lineHeight: 1,
                            padding: "3px 0",
                            height: "10px",
                            margin: 0,
                            width: "20%",
                          }}
                        >
                          KEPALA REGIONAL
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            lineHeight: 1,
                            padding: "3px 0",
                            height: "10px",
                            margin: 0,
                            width: "20%",
                          }}
                        >
                          KEPALA DEPO
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            lineHeight: 1,
                            padding: "3px 0",
                            height: "10px",
                            margin: 0,
                            width: "20%",
                          }}
                        >
                          AKUNTING
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            lineHeight: 1,
                            padding: "3px 0",
                            height: "10px",
                            margin: 0,
                            width: "20%",
                          }}
                        >
                          FINANCE
                        </td>
                        <td rowSpan={2} style={{ width: "20%" }}></td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ color: "#fff" }}>.</div>
                          <div style={{ color: "#fff" }}>.</div>
                          <div style={{ color: "#fff" }}>.</div>
                          <div style={{ color: "#fff" }}>.</div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Box>
                )}
                <div className="page-break" />
              </Fragment>
            );
          }
        )}
      </Box>
    </Box>
  );
});
export default PrintContent;
