import { useEffect, useState } from "react";
import { AutocompleteInput, ReferenceInput, maxLength, minLength } from "react-admin";
import { useWatch } from "react-hook-form";

const validateLocationNotRequired = {
  validate: [minLength(36), maxLength(36)],
};

const WarehouseReference = ({
  source,
  reference,
  orderBy,
  searchBy,
  dependOn,
  ...props
}) => {
  const [display, setDisplay] = useState("inline-flex");

  const dependOnVal = useWatch({ name: dependOn });
  useEffect(() => {
    if (dependOnVal) {
      if (dependOnVal == 1) {
        setDisplay("inline-flex");
      } else {
        setDisplay("none");
      }
    }
  }, [dependOnVal]);

  return (
    <ReferenceInput
      source={source}
      reference={reference}
      perPage={20}
      sort={{
        field: reference + "." + orderBy,
        order: "ASC",
      }}
    >
      <AutocompleteInput
        variant="outlined"
        fullWidth
        optionValue="id"
        optionText="name"
        filterToQuery={(searchText) => ({ [searchBy]: searchText })}
        {...props}
        sx={{
          display: display,
          "& .RaAutocompleteInput-textField": { minWidth: 0 },
        }}
        disabled={display === "none" ? true : false}
        {...(display === "none" ? validateLocationNotRequired : {})}
      />
    </ReferenceInput>
  );
};

export default WarehouseReference;
