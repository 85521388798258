import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreate,
  useNotify,
  SimpleForm,
  TextInput,
  Create,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
  BooleanInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";

const validateCode = [required(), maxLength(1)];
const validateName = [required(), maxLength(4)];
const validateActive = [required()];

export default function QualityAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>Create Quality</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput
              variant="outlined"
              source="name"
              fullWidth
              isRequired
              validate={validateName}
            />

            <TextInput
              variant="outlined"
              source="code"
              fullWidth
              isRequired
              validate={validateCode}
            />

            <BooleanInput
              variant="outlined"
              source="active"
              fullWidth
              isRequired
              validate={validateActive}
              defaultValue={true}
            />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
