import { useEffect, useState } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  useDataProvider,
} from "react-admin";
import { useWatch } from "react-hook-form";
import {
  OptionRenderer,
  filterToQuery,
  inputText,
  matchSuggestion,
} from "./OptionRenderer";

const SaleOrderItemReference = ({
  dependOn,
  queryName, // company_id
  source,
  reference,
  defaultFilterAddition = {},
  ...props
}) => {
  const dependOnVal = useWatch({ name: dependOn });

  return (
    <ReferenceInput
      source={source} // color_id
      reference={reference} //"colors"
      perPage={20}
      sort={{
        field: queryName + ".code",
        order: "ASC",
      }}
      filter={{ [queryName]: dependOnVal, ...defaultFilterAddition }}
      enableGetChoices={(filters) => {
        return !!dependOnVal;
      }}
    >
      <AutocompleteInput
        // label={label} // "mine.label.color"
        variant="outlined"
        fullWidth
        sx={{ display: "inline-flex" }}
        // validate={validateBrand}
        optionValue="id"
        // optionText="code"
        optionText={<OptionRenderer />}
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        filterToQuery={filterToQuery}
        {...props}
      />
    </ReferenceInput>
  );
};

export default SaleOrderItemReference;
