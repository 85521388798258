import { useRecordContext } from "react-admin";

export const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.code} - {record.name}
    </span>
  );
};
export const inputText = (choice) => `${choice.code} - ${choice.name}`;
export const matchSuggestion = (filter, choice) => {
  return (
    choice.code.toLowerCase().includes(filter.toLowerCase()) ||
    choice.name.toLowerCase().includes(filter.toLowerCase())
  );
};
export const filterToQuery = (searchText) => ({ all: searchText });
