import {
  DateField,
  NumberField,
  RecordContextProvider,
  TextField,
  useRecordContext,
} from "react-admin";

export const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.giro} {" ("}
      <span style={{ fontSize: 12 }}>
        <RecordContextProvider value={record}>
          <DateField
            source="effective_date"
            sx={{
              fontSize: "inherit",
            }}
          />
          <span> - </span>
          <TextField source="bank_name" />
          <span> - </span>
          <NumberField source="total" />
        </RecordContextProvider>
      </span>
      {")"}
    </span>
  );
};
export const inputText = (choice) =>
  `${choice.giro}`;
export const matchSuggestion = (filter, choice) => {
  if (choice.giro === undefined) {
    return false;
  }
  return (
    choice.giro.toLowerCase().includes(filter.toLowerCase()) ||
    choice.bank_name.toLowerCase().includes(filter.toLowerCase())
  );
};
export const filterToQuery = (searchText) => ({ all: searchText });
