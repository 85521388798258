import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  DateField,
  NumberField,
  useTheme,
  usePermissions,
  FunctionField,
  RecordContextProvider,
  Empty,
} from "react-admin";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import PurchaseReturnCardExpandList from "../../components/purchaseReturnCards/expand/PurchaseReturnCardExpandList";
import purchaseReturnCardFilterList from "../../components/purchaseReturnCards/filter/purchaseReturnCardFilterList";
import PurchaseReturnCardAsideList from "../../components/purchaseReturnCards/aside/PurchaseReturnCardAsideList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const PurchaseReturnCardList = ({
  withActions = true,
  withFilters = true,
  grandTotalResource = "purchase-return-cards",
  title = "mine.list_title.purchase_return",
}) => {
  const { permissions } = usePermissions();
  const [theme] = useTheme();

  const list = useMemo(() => {
    return (
      <>
        <List
          storeKey={false}
          resource="purchase-return-cards"
          title={title}
          empty={false}
          aside={
            <PurchaseReturnCardAsideList
              name="RETUR BELI TANPA DISKON"
              filter={{
                approved: 1,
                code: "RB-",
              }}
              withGrandTotal={false}
            />
          }
          actions={withActions ? <DownloadAction /> : false}
          filters={
            withFilters ? purchaseReturnCardFilterList(permissions) : undefined
          }
          filter={{
            approved: 1,
            code: "RB-",
          }}
          filterDefaultValues={{
            start_date: firstDate,
            end_date: lastDate,
          }}
          queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
          sx={{
            flexDirection: "column",
          }}
        >
          <DatagridConfigurable
            bulkActionButtons={false}
            expand={<PurchaseReturnCardExpandList />}
            sx={{
              "& .RaDatagrid-expandedPanel > td": {
                p: 0,
                bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
              },
            }}
          >
            {permissions && permissions.update_company ? (
              <TextField label="mine.label.company" source="company.alias" />
            ) : null}
            <TextField source="code" label="mine.label.code" />
            <DateField source="date" label="mine.label.date" />
            <TextField label="mine.label.supplier" source="supplier.name" />
            <FunctionField
              textAlign="right"
              label="Box"
              render={(record) => {
                let rec = { total_return: 0 };
                for (let i = 0; i < record.purchase_return_items.length; i++) {
                  rec.total_return +=
                    +record.purchase_return_items[i].return_quantity;
                }
                return (
                  <RecordContextProvider value={rec}>
                    <NumberField source="total_return" />
                  </RecordContextProvider>
                );
              }}
            />
            <NumberField source="total" />
            <TextField source="note" label="mine.label.note" emptyText="-" />
          </DatagridConfigurable>
        </List>
        <div style={{ margin: "22px 0 8px 0" }} /> {/* ================ */}
        <List
          storeKey={false}
          resource="purchase-return-cards"
          title=" "
          empty={false}
          aside={
            <PurchaseReturnCardAsideList
              name="RETUR BELI DENGAN DISKON 0.3%"
              filter={{
                approved: 1,
                code: "RT-",
              }}
              withGrandTotal={true}
              grandTotalResource={grandTotalResource}
            />
          }
          actions={false}
          filter={{
            approved: 1,
            code: "RT-",
          }}
          filterDefaultValues={{
            start_date: firstDate,
            end_date: lastDate,
          }}
          queryOptions={{
            meta: { sort: ["created_at DESC"] },
          }} // overide default sort
          sx={{
            flexDirection: "column",
          }}
        >
          <DatagridConfigurable
            bulkActionButtons={false}
            expand={<PurchaseReturnCardExpandList />}
            sx={{
              "& .RaDatagrid-expandedPanel > td": {
                p: 0,
                bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
              },
            }}
          >
            {permissions && permissions.update_company ? (
              <TextField label="mine.label.company" source="company.alias" />
            ) : null}
            <TextField source="code" label="mine.label.code" />
            <DateField source="date" label="mine.label.date" />
            <TextField label="mine.label.supplier" source="supplier.name" />
            <FunctionField
              textAlign="right"
              label="Box"
              render={(record) => {
                let rec = { total_return: 0 };
                for (let i = 0; i < record.purchase_return_items.length; i++) {
                  rec.total_return +=
                    +record.purchase_return_items[i].return_quantity;
                }
                return (
                  <RecordContextProvider value={rec}>
                    <NumberField source="total_return" />
                  </RecordContextProvider>
                );
              }}
            />
            <NumberField source="total" />
            <TextField source="note" label="mine.label.note" emptyText="-" />
          </DatagridConfigurable>
        </List>
      </>
    );
  }, [theme]);
  return list;
};

export default PurchaseReturnCardList;
