import { TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const customerTypeFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    alwaysOn
    source="name"
    label="mine.label.name"
    resettable
    variant="outlined"
  />,
  <TextInput
    alwaysOn
    source="code"
    label="mine.label.code"
    resettable
    variant="outlined"
  />,
];
export default customerTypeFilterList;
