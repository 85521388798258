import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  NumberField,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import PromotionAddDialog from "../../components/promotions/create/PromotionAddDialog";
import PromotionEditDialog from "../../components/promotions/edit/PromotionEditDialog";
import promotionFilterList from "../../components/promotions/filter/promotionFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";

const PromotionList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={<TableListActions createHandler={handleClickOpenAdd} />}
        filters={promotionFilterList}
        queryOptions={{ meta: { sort: ["created_at ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          <TextField source="name" />
          <TextField
            source="percentage"
            label="mine.label.percentage_in_decimal"
          />
          <DateField source="start_date" />
          <DateField source="end_date" />
          <WrapperField label="Actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <PromotionAddDialog open={openAdd} setOpen={setOpenAdd} />
      <PromotionEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default PromotionList;
