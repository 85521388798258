import Grid from "@mui/material/Grid";
import QuantityInput from "../../common/QuantityInput";
import {
  maxLength,
  minLength,
  minValue,
  number,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext } from "react-hook-form";
import StockPendingInput from "../../common/StockPendingInput";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";
import {
  // filterToQueryProduct,
  inputTextProduct,
  matchSuggestionProduct,
  OptionRendererProduct,
} from "../../../common/OptionRendererProduct";
import ProductInput from "../../../purchaseOrders/common/ProductInput";
import { memo, useCallback } from "react";
import SubTotal from "../../common/SubTotal";

const equalOrLessThan = (value, allValues, elm) => {
  const splite = elm.source.split(".");
  const stock = allValues[splite[0]][+splite[1]].stock;
  if (value > stock) {
    return "Must be equal or less than " + stock + " (stock)";
  }
  return undefined;
};

const validateCode = [required(), minLength(36), maxLength(36)];
const validateStock = [required(), number(), minValue(1)];
const validatePending = [required(), number()];
const validateQuantity = [required(), number(), minValue(1), equalOrLessThan];
const validatePrice = [required(), number(), minValue(1)];

const CstInput = ({
  quantity,
  // NAMA-NAMA SOURCE
  codeSource,
  quantitySource,
  nameSource,
  stockSource,
  pendingSource,
  priceSource,
  subtotalSource,
  formData,
  scopedFormData,
  ...props
}) => {
  const { setValue, getValues } = useFormContext();

  const dataProvider = useDataProvider();

  const onCodeChangeHandler = useCallback(async (code, objectData) => {
    if (code.length !== 36) {
      setValue(nameSource, "");
      setValue(stockSource, "");
      setValue(pendingSource, "");
      setValue(priceSource, "");
      setValue(quantitySource, "");
    } else {
      try {
        const { motif, density, color, quality, price, stock, pending } =
          // see: https://stackoverflow.com/a/332888
          await dataProvider.getListNoParams(
            "products/code-detail?id=" + encodeURIComponent(code)
          );
        setValue(
          nameSource,
          motif.name +
            " " +
            density.name +
            " " +
            color.name +
            " " +
            quality.name
        );
        setValue(stockSource, stock);
        setValue(pendingSource, pending);
        setValue(priceSource, price.sell_price);
      } catch (error) {}
    }
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        "& .MuiGrid-item": { py: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={3}>
        <ProductInput
          fullWidth
          source={codeSource}
          variant="outlined"
          validate={validateCode}
          label="mine.label.product_code"
          optionValue="id"
          // optionText="code"
          onChange={onCodeChangeHandler}
          dependOn="date"
          reset={[nameSource, stockSource, pendingSource, priceSource]}
          detailType="noZeroStock"
          sx={{ display: "inline-flex" }}
          optionText={<OptionRendererProduct />}
          inputText={inputTextProduct}
          matchSuggestion={matchSuggestionProduct}
          helperText={false}
          scopedFormData={scopedFormData}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextInput
          variant="outlined"
          source={nameSource}
          label="mine.label.name"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <StockPendingInput
          variant="outlined"
          source={stockSource}
          validate={validateStock}
          disabled
          fullWidth
          dependOnSource={codeSource}
          attr="stock"
          optionValueProduct="id"
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <StockPendingInput
          variant="outlined"
          source={pendingSource}
          validate={validatePending}
          disabled
          fullWidth
          dependOnSource={codeSource}
          attr="pending"
          optionValueProduct="id"
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <TextInput
          variant="outlined"
          source={priceSource}
          label="mine.label.price"
          validate={validatePrice}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            // endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          helperText="Harga jual defaultnya pakai harga terbaru"
          {...parserFormatNumInput}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <QuantityInput
          variant="outlined"
          source={quantitySource}
          label="mine.label.quantity"
          fullWidth
          validate={validateQuantity}
          quantity={quantity}
          formData={formData}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <SubTotal
          variant="outlined"
          source={subtotalSource}
          label="Subtotal"
          fullWidth
          dependOn={[priceSource, quantitySource]}
          helperText={false}
        />
      </Grid>
    </Grid>
  );
};

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <CstInput
      scopedFormData={scopedFormData}
      formData={formData}
      codeSource={getSource("product.id")}
      quantitySource={getSource("quantity")}
      nameSource={getSource("product.name")}
      stockSource={getSource("stock")}
      pendingSource={getSource("pending")}
      priceSource={getSource("price")}
      subtotalSource={getSource("subtotal")}
    />
  );
};
export default Inputs;
