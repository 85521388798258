import { useEffect, useState } from "react";
import {
  choices,
  number,
  RadioButtonGroupInput,
  required,
  useDataProvider,
} from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import { useMutation } from "react-query";

const validateType = [
  required(),
  number(),
  choices([1, 2], "Please choose one of the values"),
];

const RadioGroupX = ({ nextNumber }) => {
  const watchType = useWatch({ name: "type" });
  const [prevVal, setPrevVal] = useState(watchType);
  const { setValue, getValues } = useFormContext();
  const dataProvider = useDataProvider();

  // GET NEXT NUMBER
  const {
    data,
    mutate,
    isLoading: isLoadingNextNumber,
    error: nextNumberError,
  } = useMutation((dyn) =>
    dataProvider.getListNoParams(
      "purchase-returns/next-number?type=" + dyn[0] + "&date=" + dyn[1]
    )
  );
  // GET NEXT NUMBER

  useEffect(() => {
    if (prevVal !== watchType) {
      if (watchType) {
        mutate([watchType, getValues("date")]);
      }
      setPrevVal(watchType);
    }
  }, [prevVal, watchType, getValues]);

  useEffect(() => {
    if (data) {
      setValue("code", data);
    }
  }, [data]);
  return (
    <RadioButtonGroupInput
      source="type"
      parse={(value) => (value === "" ? null : +value)}
      // format={(value) => (value == null ? "" : +value)}
      defaultValue={1}
      choices={[
        { id: 1, name: "Purchase Return" },
        { id: 2, name: "Broke Receive" },
      ]}
      validate={validateType}
    />
  );
};

export default RadioGroupX;
