import Grid from "@mui/material/Grid";
import QuantityInput from "../../common/QuantityInput";
import {
  AutocompleteInput,
  maxLength,
  minLength,
  ReferenceInput,
  required,
} from "react-admin";
import CodeDetailInput from "../../common/CodeDetailInput";
import {
  inputTextProduct,
  matchSuggestionProduct,
  OptionRendererProduct,
} from "../../../common/OptionRendererProduct";
import ProductInput from "../../../purchaseOrders/common/ProductInput";
import { useFormContext } from "react-hook-form";

const uniqueItem = (value, allValues, elm) => {
  let count = 0;
  for (
    let i = 0;
    i < allValues.mutation_inventory_write_off_items.length;
    i++
  ) {
    const elm = allValues.mutation_inventory_write_off_items[i];
    if (value === elm.product_id) {
      if (count > 0) {
        return "Duplicate is not allowed";
      }
      count += 1;
    }
  }
  return undefined;
};

const validateCode = [required(), minLength(36), maxLength(36), uniqueItem];
const validateLocation = [required(), minLength(36), maxLength(36)];

const CstInput = ({
  quantity,
  formData,
  // NAMA-NAMA SOURCE
  idSource,
  quantitySource,
  nameSource,
  qualitySource,
  locationSource,
}) => {
  const { setValue } = useFormContext();
  const onCodeChangeHandler = async (code) => {
    if (code.length !== 36) {
      setValue(quantitySource, "");
      setValue(locationSource, "");
      setValue(nameSource, "");
      setValue(qualitySource, "");
    }
  };

  const exclude = {};
  const exclude_ids = [];
  for (let i = 0; i < formData.mutation_inventory_write_off_items.length; i++) {
    if (formData.mutation_inventory_write_off_items[i].product_id) {
      exclude_ids.push(
        formData.mutation_inventory_write_off_items[i].product_id
      );
    }
  }
  if (exclude_ids.length) {
    exclude["products.exclude_ids"] = exclude_ids;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        "& .MuiGrid-item": { py: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={3.2}>
        <ProductInput
          fullWidth
          source={idSource}
          variant="outlined"
          validate={validateCode}
          label="mine.label.product_code"
          // optionValue="code"
          // optionText="code"
          onChange={onCodeChangeHandler}
          dependOn="date"
          reset={[
            quantitySource,
            locationSource,
            nameSource,
            qualitySource,
            // priceSource,
          ]}
          detailType="noZeroRemain"
          defaultFilters={{ "products.active": 1 }}
          exclude={exclude}
          sx={{ display: "inline-flex" }}
          optionText={<OptionRendererProduct />}
          inputText={inputTextProduct}
          matchSuggestion={matchSuggestionProduct}
          helperText={false}
        />
        {/* <ReferenceInput
          source={idSource}
          reference="products"
          perPage={50}
          sort={{ field: "products.code", order: "ASC" }}
          // filter={{  }}
        >
          <AutocompleteInput
            variant="outlined"
            label="mine.label.product_code"
            fullWidth
            validate={validateCode}
            optionText="code"
            // optionText={<ProductOptionRenderer />}
            // inputText={productInputText}
            // matchSuggestion={productMatchSuggestion}
            filterToQuery={productFilterToQuery}
            helperText={false}
          />
        </ReferenceInput> */}
      </Grid>

      <Grid item xs={12} lg={1}>
        <QuantityInput
          variant="outlined"
          source={quantitySource}
          label="mine.label.quantity"
          fullWidth
          quantity={quantity}
          formData={formData}
          productSource={idSource}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1.4}>
        <ReferenceInput
          source={locationSource}
          reference="warehouses"
          perPage={10}
          sort={{ field: "warehouses.created_at", order: "ASC" }}
          // filter={{ hasInstallments: true, "bad_debts.type": "2" }}
        >
          <AutocompleteInput
            variant="outlined"
            label="mine.label.warehouse"
            fullWidth
            validate={validateLocation}
            optionText="name"
            helperText={false}
            sx={{ "&>.RaAutocompleteInput-textField": { minWidth: "auto" } }}
          />
        </ReferenceInput>
      </Grid>

      <CodeDetailInput
        nameSource={nameSource}
        qualitySource={qualitySource}
        dependOn={idSource}
      />
    </Grid>
  );
};

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <CstInput
      {...scopedFormData}
      formData={formData}
      idSource={getSource("product_id")}
      quantitySource={getSource("quantity")}
      nameSource={getSource("name")}
      qualitySource={getSource("quality")}
      locationSource={getSource("warehouse_id")}
    />
  );
};
export default Inputs;
