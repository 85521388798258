import { useEffect } from "react";
import { TextInput } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";

// dependOn berupa array
const JournalAmount = ({ dependOn, ...props }) => {
  const [total, coaID, debitCredit] = useWatch({ name: dependOn });
  const { setValue } = useFormContext();
  useEffect(() => {
    if (coaID) {
      // dependOn nilainya ["total", ""] untuk selain index 0
      // jadi coaID akan bernilai undefined,
      // saat indexnya 0 maka coaID akan berisi id COA
      // misal salah satunya adalah id Account Receivable
      setValue(props.source, total);
    }
  }, [total, coaID]);
  useEffect(() => {
    if (debitCredit != 0) {
      // jika lawannya tidak nol
      // maka set diri sendiri jadi nol
      setValue(props.source, 0);
    }
  }, [debitCredit]);
  return <TextInput {...props} />;
};
export default JournalAmount;
