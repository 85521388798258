import {
  useResourceContext,
  useDataProvider,
  Error,
  Labeled,
  RecordContextProvider,
  NumberField,
  useListController,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";

const BalanceSheetAsideList = ({ defaultFilter }) => {
  const resource = useResourceContext();
  const cntx = useListController({ storeKey: resource });

  let ifCtx = defaultFilter;
  if (Object.keys(cntx.filterValues).length) {
    ifCtx = cntx.filterValues;
  }

  const resourceFilter =
    resource + "/summary?filter=" + encodeURIComponent(JSON.stringify(ifCtx));

  const dataProvider = useDataProvider();
  const { data, isLoading, error } = useQuery(
    [resourceFilter, "getListNoParams"],
    () => dataProvider.getListNoParams(resourceFilter)
  );

  if (error) return <Error />;

  return (
    <Card
      sx={{
        mt: 1,
        position: "sticky",
        bottom: 0,
        alignSelf: "stretch",
      }}
    >
      <CardContent sx={{ pb: "16px !important" }}>
        <RecordContextProvider value={data}>
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between", "& > span": { p: 0 } }}
          >
            {Object.keys(!isLoading && data ? data : {}).map((source) => {
              return (
                <Labeled
                  key={source}
                  sx={{ py: 1, textTransform: "uppercase" }}
                >
                  <NumberField source={source} fontSize={16} />
                </Labeled>
              );
            })}
          </Stack>
        </RecordContextProvider>
      </CardContent>
    </Card>
  );
};
export default BalanceSheetAsideList;
