import { useCallback, useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  useResourceContext,
  BooleanField,
  useRefresh,
  useNotify,
  useCreate,
  Pagination,
} from "react-admin";
import closeBookFilterList from "../../components/closeBooks/filter/closeBookFilterList";
import CreateOnlyAction from "../../components/tableListActions/CreateOnlyAction";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import BookIcon from "@mui/icons-material/Book";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CloseBookUploadDialog from "../../components/closeBooks/upload/CloseBookUploadDialog";
import CloseOpenBulkAction from "../../components/closeBooks/common/CloseOpenBulkAction";

const Aside = () => {
  // const t = useTranslate();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5></h5>
    </div>
  );
};

const currMonthNum = new Date().getMonth() + 1;
const currYearNum = new Date().getFullYear();

const CloseBookList = () => {
  const [showAside, setShowAside] = useState(false);
  const notify = useNotify();
  const [create] = useCreate();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const [openUpload, setOpenUpload] = useState(false);

  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleClickCreate = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: {} },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const rowClickHandler = (id, basePath, record) => {
    // console.log("Lets", id, basePath, record);
    setShowAside((prev) => !prev);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={
          <CreateOnlyAction
            createHandler={handleClickCreate}
            uploadHandler={handleClickOpenUpload}
          />
        }
        perPage={12}
        pagination={<Pagination rowsPerPageOptions={[12, 60, 96]} />}
        exporter={false}
        filters={closeBookFilterList}
        queryOptions={{
          meta: { sort: ["close_books.year DESC", "close_books.month ASC"] },
        }} // overide default sort
      >
        <DatagridConfigurable
          rowClick={rowClickHandler}
          bulkActionButtons={<CloseOpenBulkAction />}
          // bulkActionButtons={false}
          sx={{
            "& th": {
              textTransform: "capitalize",
            },
          }}
        >
          <WrapperField label="mine.label.period">
            <TextField source="month_name" />
            {"-"}
            <TextField source="year" />
          </WrapperField>
          <DateField source="start_date" label="mine.label.start_date" />
          <DateField source="end_date" label="mine.label.end_date" />
          <DateField
            source="closed_at"
            label="mine.label.closed_date"
            emptyText="-"
          />
          <BooleanField
            source="closed_at"
            label="mine.label.closed"
            textAlign="center"
            looseValue={true}
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return !record.closed_at ? (
                  record.year < currYearNum || record.month < currMonthNum ? (
                    <ConfirmBtn
                      title={"mine.message.close_book"}
                      content="mine.message.close_book_content"
                      label="mine.label.close"
                      resource={resource + "/close"}
                      startIcon={<BookIcon />}
                      color="error"
                      sx={{
                        mx: 1,
                      }}
                    />
                  ) : (
                    "-"
                  )
                ) : (
                  <ConfirmBtn
                    title={"mine.message.open_book"}
                    content="mine.message.open_book_content"
                    label="mine.label.open"
                    resource={resource + "/open"}
                    startIcon={<AutoStoriesIcon />}
                    sx={{
                      mx: 1,
                    }}
                  />
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [showAside]
  );
  return (
    <>
      {list}
      {openUpload ? (
        <CloseBookUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
    </>
  );
};

export default CloseBookList;
