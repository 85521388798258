import { useRecordContext } from "react-admin";

export const CustomerOptionRenderer = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.customer.name} ({record.customer.code})
    </span>
  );
};
export const customerInputText = (choice) =>
  `${choice.customer.name} (${choice.customer.code})`;
export const customerMatchSuggestion = (filter, choice) => {
  return (
    choice.customer.name.toLowerCase().includes(filter.toLowerCase()) ||
    choice.customer.code.toLowerCase().includes(filter.toLowerCase())
  );
};
export const customerFilterToQuery = (searchText) => ({
  customer: searchText,
});
