import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import JournalInputs from "./JournalInputs";

export default function JournalItemForm() {
  return (
    <ArrayInput
      source="journal_items"
      defaultValue={[
        {
          chart_of_account_id: "",
          debit: 0,
          credit: 0,
        },
        {
          chart_of_account_id: "",
          debit: 0,
          credit: 0,
        },
      ]}
    >
      <SimpleFormIterator
        fullWidth={true}
        inline={true}
        disableReordering
        sx={{
          "& .RaSimpleFormIterator-form": {
            width: "100%",
          },
          "& .RaSimpleFormIterator-line": {
            borderBottom: "none",
          },
        }}
        // getItemLabel={(index) => `#${index + 1}`}
      >
        <FormDataConsumer>{JournalInputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
