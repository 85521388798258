import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  FunctionField,
  BulkDeleteWithConfirmButton,
  useTheme,
  useTranslate,
  usePermissions,
} from "react-admin";
import SaleOrderAddDialog from "../../components/saleOrders/create/SaleOrderAddDialog";
import SaleOrderEditDialog from "../../components/saleOrders/edit/SaleOrderEditDialog";
import saleOrderFilterList from "../../components/saleOrders/filter/saleOrderFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import SaleOrderExpandList from "../../components/saleOrders/expand/SaleOrderExpandList";
import EditBtn from "../../components/editBtn/EditBtn";

const Aside = () => {
  const t = useTranslate();
  const menu = t("resources.inventory_pending.name").split(" |||| ");
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5>
        Saat SO dibuat maka produk sejumlah quantity yang dipesan akan tertahan
        tidak bisa diambil untuk pesanan lain sampai SO tersebut dihapus atau
        dibuat SJ nya dan dikirim sebagian, maka sebagian lagi bisa dibatalkan
        melalui menu {menu[1] ? menu[1] : menu[0]}. SO tidak dapat dihapus
        ketika sudah dibuat SJ nya meskipun SJ tersebut belum di approve, jika
        ingin menghapus SO maka perlu hapus dahulu SJ yang belum approved
        tersebut
      </h5>
    </div>
  );
};

const SaleOrderList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showAside, setShowAside] = useState(false);
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => (e) => {
    e.stopPropagation();
    setRecordId(recId);
    setOpenEdit(true);
  };
  const rowClickHandler = (id, basePath, record) => {
    // console.log("Lets", id, basePath, record);
    setShowAside((prev) => !prev);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.sale_order"
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={saleOrderFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          rowClick={rowClickHandler}
          expand={<SaleOrderExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <DateField source="date" label="mine.label.date" />
          <FunctionField
            label="mine.label.customer"
            render={(record) =>
              `${record.customer.name} (${record.customer.code})`
            }
          />
          <FunctionField
            label="Salesman"
            render={(record) =>
              `${record.salesman.name} (${record.salesman.code})`
            }
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => {
                return (
                  !record.approved && (
                    <>
                      <EditBtn
                        editHandler={handleClickOpenEdit(record.id)}
                        // label={t("mine.label.edit")}
                        variant="outlined"
                        sx={{
                          width: "fit-content",
                          mr: 1,
                          // "& > .MuiButton-startIcon": {
                          //   mr: 0,
                          // },
                        }}
                      />
                      {/* <DeleteButton
                        label=""
                        variant="outlined"
                        sx={{
                          "& > .MuiButton-startIcon": {
                            mr: 0,
                          },
                        }}
                      /> */}
                    </>
                  )
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [showAside, theme]
  );
  return (
    <>
      {list}
      <SaleOrderAddDialog open={openAdd} setOpen={setOpenAdd} />
      <SaleOrderEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default SaleOrderList;
