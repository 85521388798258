import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
} from "react-admin";

const accountPayableCardFilterList = [
  <DateInput
    alwaysOn
    source="start_date"
    variant="outlined"
    label="mine.label.start_date"
  />,
  <DateInput
    alwaysOn
    source="end_date"
    //FIXME: semua DateInput tidak bisa di tambah resettable
    // resettable
    variant="outlined"
    label="mine.label.end_date"
  />,
  <ReferenceInput
    alwaysOn
    source="start_created_at"
    reference="suppliers"
    sort={{ field: "suppliers.created_at", order: "ASC" }}
    perPage={10}
  >
    <AutocompleteInput
      resettable
      optionValue="created_at"
      optionText="code"
      variant="outlined"
      label="mine.label.start_code"
    />
  </ReferenceInput>,
  <ReferenceInput
    alwaysOn
    source="end_created_at"
    reference="suppliers"
    sort={{ field: "suppliers.created_at", order: "ASC" }}
    perPage={10}
  >
    <AutocompleteInput
      resettable
      optionValue="created_at"
      optionText="code"
      variant="outlined"
      label="mine.label.end_code"
      // filterToQuery={(search) => ({ created_at: search })}
    />
  </ReferenceInput>,
  <TextInput
    alwaysOn
    resettable
    source="name"
    variant="outlined"
    label="mine.label.name"
  />,
];

export default accountPayableCardFilterList;
