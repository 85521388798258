import * as React from "react";
import ReactToPrint from "react-to-print";
import PrintContent from "./PrintContent";
import { Button } from "react-admin";
import PrintIcon from "@mui/icons-material/Print";

const DeliveryNote = ({ record, tabTitle }) => {
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return (
      <Button
        startIcon={<PrintIcon />}
        label="Print"
        sx={{ width: "fit-content" }}
      />
    ); // eslint-disable-line max-len
  }, []);

  return (
    <div style={{ display: "inline-flex", verticalAlign: "middle" }}>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle={tabTitle}
        removeAfterPrint
        trigger={reactToPrintTrigger}
        onBeforeGetContent={() => {
          const currentDate = new Date().toLocaleString();
          document
            .querySelectorAll(".current-date-time")
            .forEach((elm) => (elm.textContent = currentDate));
        }}
      />
      <PrintContent ref={componentRef} record={record} />
    </div>
  );
};

export default DeliveryNote;
