import { memo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import QuantityInput from "../../common/QuantityInput";
import {
  AutocompleteInput,
  maxLength,
  minLength,
  number,
  ReferenceInput,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext, useWatch } from "react-hook-form";
import DependOnAutocompleteInput from "../../../common/DependOnAutocompleteInput";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";
import PurchaseCodeDepend from "../../common/PurchaseCodeDepend";

const equalLessThan = (a, b, c) => {
  const gv = c.source;
  const bv = +gv.split(".")[1];
  if (a > b.purchase_return_items[bv].received_quantity) {
    return "Must be equal or less than received_quantity";
  }
  return undefined;
};
const validatePurchaseOrder = [required(), minLength(36), maxLength(36)];
const validateLocation = [required(), minLength(36), maxLength(36)];
const returnQuantityValidation = [required(), number(), equalLessThan];

const CstInput = ({
  returnQuantity,
  receivedQuantity,
  // NAMA-NAMA SOURCE
  purchaseIdSource,
  purchaseItemIdSource,
  returnQuantitySource,
  receivedQuantitySource,
  nameSource,
  priceSource,
  locationSource,
}) => {
  const { setValue } = useFormContext();

  const dataProvider = useDataProvider();

  const dependOnVal = useWatch({ name: purchaseItemIdSource });
  useEffect(() => {
    if (dependOnVal === null) {
      return;
    } else if (dependOnVal) {
      (async () => {
        if (dependOnVal.length !== 36) return;
        try {
          const {
            brand,
            size,
            motif,
            density,
            color,
            quality,
            price,
            received_quantity,
            warehouse_id,
          } =
            // see: https://stackoverflow.com/a/332888
            await dataProvider.getListNoParams(
              "purchase-returns/code-detail?id=" +
                encodeURIComponent(dependOnVal)
            );
          setValue(
            nameSource,
            motif.name +
              " " +
              density.name +
              " " +
              color.name +
              " " +
              quality.name
          );
          // setValue("brand", brand.name);
          // setValue("size", size.name);
          // setValue("motif", motif.name);
          // setValue("density", density.name);
          // setValue("color", color.name);
          setValue(receivedQuantitySource, received_quantity);
          setValue(priceSource, price.buy_price);
          setValue(locationSource, warehouse_id);
        } catch (error) {}
      })();
    }
  }, [dependOnVal]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        // "& .MuiGrid-item:nth-child(2)": { pt: 2 },
        "& .MuiGrid-item": { py: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={2}>
        <PurchaseCodeDepend
          source={purchaseIdSource}
          validate={validatePurchaseOrder}
          dependOn="supplier_id"
        />
      </Grid>

      <Grid item xs={12} lg={2}>
        <DependOnAutocompleteInput
          variant="outlined"
          source={purchaseItemIdSource}
          label="mine.label.product_code"
          fullWidth
          optionText="code"
          validate={validatePurchaseOrder}
          dependOn={purchaseIdSource}
          queryName="purchase_id"
          resource="purchase-items"
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <QuantityInput
          variant="outlined"
          source={receivedQuantitySource}
          label="mine.label.received_quantity"
          fullWidth
          disabled
          // validate={validateQuantity}
          quantity={returnQuantity}
          helperText="Stok bisa berbeda"
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <QuantityInput
          variant="outlined"
          source={returnQuantitySource}
          label="mine.label.return_quantity"
          fullWidth
          validate={returnQuantityValidation}
          quantity={returnQuantity}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <ReferenceInput source={locationSource} reference="warehouses">
          <AutocompleteInput
            variant="outlined"
            label="mine.label.warehouse"
            disabled
            optionText="name"
            validate={validateLocation}
            helperText={false}
            sx={{ "& .RaAutocompleteInput-textField": { minWidth: 0 } }}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextInput
          variant="outlined"
          source={nameSource}
          label="mine.label.name"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <TextInput
          variant="outlined"
          source={priceSource}
          label="mine.label.price"
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          helperText="sesuai harga beli saat order"
          {...parserFormatNumInput}
        />
      </Grid>
    </Grid>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.purchase_id === nextProps.purchase_id &&
    prevProps.purchase_item_id === nextProps.purchase_item_id &&
    prevProps.name === nextProps.name &&
    prevProps.price === nextProps.price &&
    prevProps.return_quantity === nextProps.return_quantity &&
    prevProps.received_quantity === nextProps.received_quantity
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
const Dudupap = memo(CstInput, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <Dudupap
      {...scopedFormData}
      purchaseIdSource={getSource("purchase_id")}
      purchaseItemIdSource={getSource("purchase_item_id")}
      returnQuantitySource={getSource("return_quantity")}
      receivedQuantitySource={getSource("received_quantity")}
      nameSource={getSource("name")}
      priceSource={getSource("price")}
      locationSource={getSource("warehouse_id")}
    />
  );
};
export default Inputs;
