import { useEffect } from "react";
import { NumberInput } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";

const MaxStock = ({ dependOn, ...props }) => {
  const minStock = useWatch({ name: dependOn });
  const { setValue, getValues } = useFormContext();
  useEffect(() => {
    if (minStock) {
      const me = getValues(props.source);
      if (me < minStock) {
        setValue(props.source, minStock);
      }
    }
  }, [minStock]);
  return <NumberInput variant="outlined" fullWidth {...props} />;
};

export default MaxStock;
