import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useWatch } from "react-hook-form";
import {
  OptionRenderer,
  filterToQuery,
  inputText,
  matchSuggestion,
} from "../../factoryDebtPayments/common/OptionRenderer";

const PurchaseOrderReference = ({
  formData,
  dependOn,
  queryName, // company_id
  source,
  resource,
  reference,
  defaultFilters = {},
  ...props
}) => {
  const dependOnVal = useWatch({ name: dependOn });

  const exclude = { exclude_ids: [] };
  for (let i = 0; i < formData.factory_debt_correction_items.length; i++) {
    if (formData.factory_debt_correction_items[i].purchase_order_id) {
      exclude.exclude_ids.push(
        formData.factory_debt_correction_items[i].purchase_order_id
      );
    }
  }

  return (
    <ReferenceInput
      source={source} // color_id
      reference={reference} //"colors"
      perPage={20}
      sort={{
        field: resource + ".code",
        order: "ASC",
      }}
      filter={{
        [queryName]: dependOnVal,
        ...(exclude.exclude_ids.length ? exclude : {}),
        ...defaultFilters,
      }}
      enableGetChoices={(filters) => {
        return !!dependOnVal || !!filters.all;
      }}
    >
      <AutocompleteInput
        // label={label} // "mine.label.color"
        variant="outlined"
        fullWidth
        sx={{ display: "inline-flex" }}
        // validate={validateBrand}
        optionValue="id"
        // optionText="code"
        optionText={<OptionRenderer />}
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        filterToQuery={filterToQuery}
        {...props}
      />
    </ReferenceInput>
  );
};

export default PurchaseOrderReference;
