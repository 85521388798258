import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Confirm,
  useDataProvider,
  useNotify,
  useResourceContext,
  useTranslate,
} from "react-admin";
import { useMutation } from "react-query";
import PrintIcon from "@mui/icons-material/Print";
import ReasonForm from "./reason/Form";

const PrintBtn = ({
  recordId,
  setPrintRecord,
  setResetPrintContent,
  ...props
}) => {
  const resource = useResourceContext();
  const [open, setOpen] = useState(false);
  const [reasonForm, setReasonForm] = useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const t = useTranslate();

  const { mutate, data, isLoading, error, reset, status } = useMutation(
    (formDataReason) => {
      return dataProvider.simplePost(
        resource + "/print/" + encodeURIComponent(recordId),
        { data: formDataReason }
      );
    }
  );

  useEffect(() => {
    if (status === "success" || status === "error") {
      setResetPrintContent(() => reset);
    }
  }, [status]);

  useEffect(() => {
    if (error) {
      notify("mine.notification." + error.message.split(" ").join("_"), {
        type: "error",
      });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (data.id && !open) {
        // masuk sini saat sudah submit reason, lalu jalankan mutate
        // lalu dapat data content printnya ditandai dengan adanya key id
        setPrintRecord(data);
        setReasonForm(undefined);
      } else if (data.id && open) {
        // masuk sini saat pertama kali klik confirm, dicek ke server
        // hasilnya ini adalah pertama kali request print untuk SJ tersebut
        // maka server langsung response dengan data content print
        setPrintRecord(data);
        setOpen(false);
      } else if (data.print_history_id && open) {
        setReasonForm(data);
        setPrintRecord({}); // bailikin ke initial value, wajib object kosong untuk cek key id di dependency useeffect DeliveryNote.js
      } else if (data.print_history_id && !open) {
        // saat click cancel atau batal di form reason
        setTimeout(() => {
          setPrintRecord({}); // bailikin ke initial value, wajib object kosong untuk cek key id di dependency useeffect DeliveryNote.js
          reset();
          setReasonForm(undefined);
        }, 200);
      }
    }
  }, [data, open]);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    if (status === "idle") {
      // masuk sini artinya ini klik confirm pertama
      mutate();
    } else {
      // masuk sini artinya ini klik confirm kedua
      // SUBMIT FORM
      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      document.querySelector("button.sbt-btn-rsn").dispatchEvent(clickEvent);
      // SUBMIT FORM
    }
  };

  const save = useCallback((values) => {
    mutate(values);
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        label="Print"
        disabled={isLoading}
        variant="outlined"
        startIcon={<PrintIcon />}
        sx={{
          mx: 1,
          "& > .MuiButton-startIcon": {
            // mr: 0,
          },
        }}
        {...props.btnProps}
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={t("mine.dialog.print_delivery_note")}
        content={
          reasonForm ? (
            <ReasonForm reasonForm={reasonForm} save={save} />
          ) : (
            "mine.message.print_content"
          )
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        sx={reasonForm ? { "& .MuiDialogContent-root": { pb: 0 } } : undefined}
        {...props.dialogProps}
      />
    </>
  );
};

export default PrintBtn;
