import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import CustomerTypeAddDialog from "../../components/customerTypes/create/CustomerTypeAddDialog";
import CustomerTypeEditDialog from "../../components/customerTypes/edit/CustomerTypeEditDialog";
import customerTypeFilterList from "../../components/customerTypes/filter/customerTypeFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";

const CustomerTypeList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={customerTypeFilterList}
        queryOptions={{ meta: { sort: ["code ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          <TextField source="name" label="mine.label.name" />
          <TextField source="code" label="mine.label.code" />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <CustomerTypeAddDialog open={openAdd} setOpen={setOpenAdd} />
      <CustomerTypeEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default CustomerTypeList;
