import {
  maxLength,
  minLength,
  required,
  TextInput,
  useDataProvider,
  useTranslate,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";

const validateCode = [required(), minLength(13), maxLength(13)];
const validateName = [required()];

export default function DisabledInput(props) {
  const { setValue, getValues } = useFormContext();

  const t = useTranslate();
  const dataProvider = useDataProvider();

  const onCodeBlurHandler = async (e) => {
    const code = e.target.value;
    if (code.length !== 13) return;
    try {
      const { brand, size, motif, density, color, quality } =
        await dataProvider.getListNoParams(
          "products/code-detail?code=" + encodeURIComponent(code)
        );
      setValue(
        "name",
        motif.name + " " + density.name + " " + color.name + " " + quality.name
      );
      setValue("brand_name", brand.name);
      setValue("size_name", size.name);
      setValue("motif_name", motif.name);
      setValue("density_name", density.name);
      setValue("color_name", color.name);
      setValue("quality_name", quality.name);
    } catch (error) {}
  };

  useEffect(() => {
    const code = getValues("code");
    if (code) {
      onCodeBlurHandler({ target: { value: code } });
    }
  }, []);

  return (
    <>
      <TextInput
        variant="outlined"
        source="code"
        label="mine.label.code"
        fullWidth
        isRequired
        validate={validateCode}
        onBlur={onCodeBlurHandler}
        helperText={t("mine.label.13_chars")}
      />

      <TextInput
        variant="outlined"
        source="name"
        label="mine.label.name"
        fullWidth
        isRequired
        validate={validateName}
        disabled
      />
      <Grid container spacing={0} rowSpacing={0}>
        <Grid item xs={12} md={5.75}>
          <TextInput
            variant="outlined"
            source="brand_name"
            label="mine.label.brand"
            fullWidth
            isRequired
            validate={validateName}
            disabled
          />
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={12} md={5.75}>
          <TextInput
            variant="outlined"
            source="size_name"
            label="mine.label.size"
            fullWidth
            isRequired
            validate={validateName}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={5.75}>
          <TextInput
            variant="outlined"
            source="motif_name"
            label="mine.label.motif"
            fullWidth
            isRequired
            validate={validateName}
            disabled
          />
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={12} md={5.75}>
          <TextInput
            variant="outlined"
            source="density_name"
            label="mine.label.density"
            fullWidth
            isRequired
            validate={validateName}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={5.75}>
          <TextInput
            variant="outlined"
            source="color_name"
            label="mine.label.color"
            fullWidth
            isRequired
            validate={validateName}
            disabled
          />
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={12} md={5.75}>
          <TextInput
            variant="outlined"
            source="quality_name"
            label="mine.label.quality"
            fullWidth
            isRequired
            validate={validateName}
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
}
