import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BooleanField,
  FunctionField,
  BulkDeleteWithConfirmButton,
  useTheme,
  usePermissions,
} from "react-admin";
import CustomerAddDialog from "../../components/customers/create/CustomerAddDialog";
import CustomerEditDialog from "../../components/customers/edit/CustomerEditDialog";
import customerFilterList from "../../components/customers/filter/customerFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import CustomerExpandList from "../../components/customers/expand/CustomerExpandList";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import CustomerUploadDialog from "../../components/customers/upload/CustomerUploadDialog";

const CustomerList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={customerFilterList(permissions)}
        queryOptions={{ meta: { sort: ["company_id ASC", "created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<CustomerExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="name" label="mine.label.name" />
          <TextField source="code" label="mine.label.code" />
          {/* <TextField label="Type" source="customer_type.name" /> */}
          {/* <TextField label="Area" source="sales_area.name" /> */}
          <TextField source="pic" label="mine.label.pic" />
          <FunctionField
            textAlign="right"
            label="mine.label.debt"
            render={(record) => {
              // tadinya begini: karena customer_debts di order DESC
              // jd ambil index 0 untuk ambil remain terbaru
              // karena customer_debt_items di order ASC
              // jd musti ambil index paling akhir
              // TODO perlu di tes lebih lanjut
              if (record.customer_debts) {
                let src = { debt: 0 };
                for (let i = 0, n = record.customer_debts.length; i < n; i++) {
                  src.debt += +record.customer_debts[i].remain;
                }
                return <NumberField record={src} source="debt" />;
              }
              return "0";
            }}
          />
          <NumberField source="loan_limit" label="mine.label.loan_limit" />
          <NumberField source="loan_tenor" label="mine.label.loan_tenor" />
          <NumberField
            source="loan_tenor_tolerance"
            label="mine.label.loan_tenor_tolerance"
          />
          <BooleanField
            source="active"
            textAlign="center"
            label="mine.label.active"
          />
          <WrapperField textAlign="center" label="mine.tbl_header.actions">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      <CustomerAddDialog open={openAdd} setOpen={setOpenAdd} />
      <CustomerEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      {openUpload ? (
        <CustomerUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
    </>
  );
};

export default CustomerList;
