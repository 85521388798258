import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import { useQuery } from "react-query";
import {
  useCreate,
  useNotify,
  SimpleForm,
  Create,
  useResourceContext,
  required,
  number,
  maxLength,
  useRefresh,
  useDataProvider,
  minLength,
  AutocompleteInput,
  TextInput,
  FormDataConsumer,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const isBrandSelected = (value, allValues) => {
  if (!value && allValues.category === "brands") {
    return "Required";
  }
  return undefined;
};
const isSizeSelected = (value, allValues) => {
  if (!value && allValues.category === "sizes") {
    return "Required";
  }
  return undefined;
};

const validateCompany = [required(), minLength(36), maxLength(36)];
const validateBrand = [minLength(36), maxLength(36), isBrandSelected];
const validatePrice = [required(), number()];

export default function BrandPriceAdditionAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const dataProvider = useDataProvider();
  const t = useTranslate()

  // GET COMPANY LISTS
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companyError,
  } = useQuery(["companies/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("companies/select")
  );
  // GET COMPANY LISTS
  // GET BRAND LISTS
  const {
    data: brands,
    isLoading: isLoadingBrands,
    error: brandError,
  } = useQuery(["brands/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("brands/select")
  );
  // GET BRAND LISTS

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>
              {t("mine.dialog.create_brand_price_addition")}
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <AutocompleteInput
              variant="outlined"
              source="company_id"
              isRequired
              choices={isLoadingCompanies ? [] : companies}
              fullWidth
              validate={validateCompany}
            />

            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <AutocompleteInput
                    variant="outlined"
                    source="brand_id"
                    isRequired
                    choices={isLoadingBrands ? [] : brands}
                    fullWidth
                    validate={validateBrand}
                    sx={{ display: "inline-flex" }}
                  />
                );
              }}
            </FormDataConsumer>

            <TextInput
              variant="outlined"
              source="price"
              fullWidth
              isRequired
              resettable
              validate={validatePrice}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
              }}
              {...parserFormatNumInput}
              // format={(v) => {
              //   if (v === "") return "";
              //   if (v === undefined) return undefined;
              //   const asStr = String(v).replaceAll(".", "").trim();
              //   return toCurrency(false).format(asStr);
              // }}
              // parse={(v) => {
              //   if (v === "") return "";
              //   const asStr = String(v).replaceAll(".", "").trim();
              //   return parseInt(asStr);
              // }}
            />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
