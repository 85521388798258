import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useWatch } from "react-hook-form";

const PurchaseCodeDepend = ({ source, validate, dependOn }) => {
  const dependOnVal = useWatch({ name: dependOn });
  return (
    <ReferenceInput
      source={source}
      reference="purchases"
      sort={{ field: "purchases.date", order: "ASC" }}
      filter={{
        "purchases.approved": 1,
        "Supplier.id": dependOnVal,
      }}
      perPage={5}
      enableGetChoices={() => !!dependOnVal}
    >
      <AutocompleteInput
        variant="outlined"
        label="mine.label.purchase_number"
        fullWidth
        validate={validate}
        optionText="code"
        helperText={false}
        filterToQuery={(search) => ({ "purchases.code": search })}
      />
    </ReferenceInput>
  );
};
export default PurchaseCodeDepend;
