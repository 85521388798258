import { useRecordContext } from "react-admin";

export const OptionRendererKind = () => {
  const record = useRecordContext();
  return <span>{record.name}</span>;
};
export const inputTextKind = (choice) => choice.name;
export const matchSuggestionKind = (filter, choice) => {
  if (choice.name === undefined) {
    return false;
  }
  return choice.name.toLowerCase().includes(filter.toLowerCase());
};
export const filterToQueryKind = (searchText) => ({ all: searchText });
