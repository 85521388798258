import { TextInput } from "react-admin";
import { useEffect } from "react";
import { useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";

const ProductDetail = ({
  dependOn,
  nameSource,
  quantitySource,
  priceSource,
  ...props
}) => {
  const { setValue } = useFormContext();

  const dataProvider = useDataProvider();
  const dependOnVal = useWatch({ name: dependOn });
  useEffect(() => {
    if (dependOnVal === null) {
      setValue(nameSource, "");
      setValue(quantitySource, "");
      setValue(priceSource, "");
    } else if (dependOnVal) {
      (async () => {
        const { product, quantity, price } = await dataProvider.getListNoParams(
          "sale-order-items/" + encodeURIComponent(dependOnVal)
        );
        setValue(nameSource, product.name);
        setValue(quantitySource, quantity);
        setValue(priceSource, price);
      })();
    }
  }, [dependOnVal]);

  return (
    <TextInput
      variant="outlined"
      source={nameSource}
      disabled
      fullWidth
      {...props}
    />
  );
};

export default ProductDetail;
