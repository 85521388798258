import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

// const OptionRenderer = () => {
//   const record = useRecordContext();
//   return (
//     <span>
//       {record.code} {" - "} {record.name}
//     </span>
//   );
// };
const inputText = (choice) => choice.code;
const matchSuggestion = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return choice.code.toLowerCase().includes(filter.toLowerCase());
};
const filterToQuery = (searchText) => ({ all: searchText });

const generalLedgerFilterList = (permissions) => {
  const defaultFilter = [
    <DateInput
      alwaysOn
      source="start_date"
      variant="outlined"
      label="mine.label.start_date"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      variant="outlined"
      label="mine.label.end_date"
    />,
    <ReferenceInput
      alwaysOn
      source="start_code"
      reference="coa/start-end-code"
      perPage={1000}
      sort={{ field: "chart_of_accounts.code", order: "ASC" }}
      // filter={{ start_code: "code" }}
    >
      <AutocompleteInput
        optionText="code"
        optionValue="increment"
        variant="outlined"
        label="mine.label.start_code"
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        filterToQuery={(q) => ({ "chart_of_accounts.code": q })}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        clearOnBlur
      />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn
      source="end_code"
      reference="coa/start-end-code"
      perPage={1000}
      sort={{ field: "chart_of_accounts.code", order: "ASC" }}
      // filter={{ start_code: "code" }}
    >
      <AutocompleteInput
        optionText="code"
        optionValue="increment"
        variant="outlined"
        label="mine.label.end_code"
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        filterToQuery={(q) => ({ "chart_of_accounts.code": q })}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        clearOnBlur
      />
    </ReferenceInput>,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default generalLedgerFilterList;
