import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  useTranslate,
  DateField,
} from "react-admin";
import Box from "@mui/material/Box";

const AccountReceivableCardExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <Box
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <Box
        component="table"
        sx={{
          width: "100%",
          // bgcolor: "rgba(0,0,0,.04)",
          fontSize: "smaller",
          borderCollapse: "collapse",
          "& th, td": {
            padding: "6px 8px",
            border: "1px solid #dddddd",
          },
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "25%" }}>{t("mine.label.code")}</th>
            <th style={{ width: "25%" }}>{t("mine.label.date")}</th>
            <th style={{ textAlign: "right", width: "25%" }}>
              {t("mine.label.debit")}
            </th>
            <th style={{ textAlign: "right", width: "25%" }}>
              {t("mine.label.credit")}
            </th>
          </tr>
        </thead>
        <tbody>
          {record.customer_debt_items.map((item, idx) => {
            return (
              <tr key={item.id + idx}>
                <td>{item.code}</td>
                <td>
                  <RecordContextProvider value={item}>
                    <DateField
                      source="date"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="debit"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={item}>
                    <NumberField
                      source="credit"
                      sx={{
                        fontSize: "inherit",
                      }}
                    />
                  </RecordContextProvider>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Box>
    </Box>
  );
};

export default AccountReceivableCardExpandList;
