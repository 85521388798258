import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import JournalInputs from "./JournalInputs";

export default function FactoryDebtCorrectionJournalForm() {
  return (
    <ArrayInput
      source="factory_debt_correction_journals"
      defaultValue={[
        {
          chart_of_account_id: "", // "e924b90a-f5fd-438f-bb15-83755f98a0dd", // FIXME: sesuaikan dgn ID Account Payable - CJFI
          debit: 0,
          credit: 0,
        },
        {
          chart_of_account_id: "",
          debit: 0,
          credit: 0,
        },
      ]}
    >
      <SimpleFormIterator
        fullWidth={true}
        inline={true}
        disableReordering
        sx={{
          "& .RaSimpleFormIterator-form": {
            width: "100%",
            },
          "& .RaSimpleFormIterator-line": {
            borderBottom: "none",
          },
        }}
        // getItemLabel={(index) => `#${index + 1}`}
      >
        <FormDataConsumer>{JournalInputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
