import { AutocompleteInput, BooleanInput, TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import generateListOfYears from "../../../utils/generateListOfYears";

// const years = generateListOfYears(2023, new Date().getFullYear() + 10).map(
//   (year) => ({ id: year, name: year })
// );

const closeBookFilterList = [
  <TextInput
    variant="outlined"
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <AutocompleteInput
    source="month"
    variant="outlined"
    label="mine.label.month"
    alwaysOn
    choices={[
      { id: "1", name: "Jan" },
      { id: "2", name: "Feb" },
      { id: "3", name: "Mar" },
      { id: "4", name: "Apr" },
      { id: "5", name: "Mei" },
      { id: "6", name: "Jun" },
      { id: "7", name: "Jul" },
      { id: "8", name: "Aug" },
      { id: "9", name: "Sep" },
      { id: "10", name: "Oct" },
      { id: "11", name: "Nov" },
      { id: "12", name: "Dec" },
    ]}
  />,
  <TextInput
    variant="outlined"
    source="year"
    label="mine.label.year"
    alwaysOn
    resettable
  />,
  <BooleanInput source="closed" alwaysOn label="mine.label.closed" />,
];

export default closeBookFilterList;
