import { SimpleForm, TextInput, Edit, useRecordContext } from "react-admin";

const PostTitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? `"${record.v1}"` : ""}</span>;
};

const RoleEdit = () => {
  return (
    <Edit mutationMode="pessimistic" title={<PostTitle />}>
      <SimpleForm>
        <TextInput disabled source="id" sx={{ display: "none" }} />
        <TextInput
          source="v1"
          label="mine.label.name"
          variant="outlined"
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default RoleEdit;
