import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  FunctionField,
  WithRecord,
  BulkDeleteWithConfirmButton,
  usePermissions,
} from "react-admin";
import CoAAddDialog from "../../components/coa/create/CoAAddDialog";
import CoAEditDialog from "../../components/coa/edit/CoAEditDialog";
import coaFilterList from "../../components/coa/filter/coaFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import CoAUploadDialog from "../../components/coa/upload/CoAUploadDialog";

const CoAList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={coaFilterList(permissions)}
        queryOptions={{ meta: { sort: ["class ASC", "code ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          {permissions && permissions.update_company ? (
            <TextField label="Company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <TextField source="name" label="mine.label.name" />
          <NumberField source="class" label="mine.label.class" />
          <FunctionField
            label="mine.label.nature"
            render={(record) => {
              switch (record.nature) {
                case "D":
                  return "DEBIT";
                case "C":
                  return "CREDIT";
                default:
                  return "-";
              }
            }}
          />
          <FunctionField
            label="mine.label.parent"
            render={(record) => {
              switch (record.parent) {
                case 0:
                case null:
                  return "-";
                default:
                  return record.parent;
              }
            }}
          />
          <NumberField source="level" />
          <FunctionField
            label="mine.label.type"
            render={(record) => {
              switch (record.type) {
                case "G":
                  return "GENERAL";
                case "D":
                  return "DETAIL";
                default:
                  return "-";
              }
            }}
          />
          <TextField source="note" label="mine.label.note" emptyText="-" />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      {openAdd ? <CoAAddDialog open={openAdd} setOpen={setOpenAdd} /> : null}
      {openEdit ? (
        <CoAEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
      {openUpload ? (
        <CoAUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
    </>
  );
};

export default CoAList;
