import { useRecordContext } from "react-admin";
import Chip from "@mui/material/Chip";

function StockPendingRemain({ record }) {
  return (
    <>
      <span title="stok">kode: {record.code}</span>
      <span style={{ margin: "0 2px" }}>|</span>
      <span title="pending">plat: {record.plate}</span>
    </>
  );
}

export const OptionRendererTransport = () => {
  const record = useRecordContext();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{record.name}</span>
      <div>
        <Chip
          label={record.code}
          size="small"
          color="warning"
          sx={{
            mr: 1,
          }}
        />
        <Chip
          label={record.plate}
          size="small"
          color="warning"
          // sx={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   fontSize: "11px",
          // }}
        />
      </div>
    </div>
  );
};
export const inputTextTransport = (choice) =>
  `${choice.name} - ${choice.code} - ${choice.plate}`;
export const matchSuggestionTransport = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return (
    choice.name.toLowerCase().includes(filter.toLowerCase()) ||
    choice.code.toLowerCase().includes(filter.toLowerCase()) ||
    choice.plate.toLowerCase().includes(filter.toLowerCase())
  );
};

/*
TODO semua yg pake fungsi ini perlu ganti pake cara

filterToQuery={(searchText) => {
          if (!searchText) {
            return { all: getValues(source) };
          }
          return { all: searchText };
        }}

seperti di E:\Sugeng\react-admin\src\components\purchaseOrders\common\ProductInput.js
 */
export const filterToQueryTransport = (searchText) => ({ all: searchText });
