import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function PurchaseReturnItemForm() {
  return (
    <ArrayInput
      source="purchase_return_items"
      defaultValue={[
        {
          // kalo tdk terlist disini input field
          // di form lain sama2 terupdate
          // saat salah satunya di rubah
          purchase_id: "",
          purchase_item_id: "",
          return_quantity: "",
          name: "", // disabled
          received_quantity: "", // disabled
          price: "", // disabled
          warehouse_id: "",
        },
      ]}
    >
      <SimpleFormIterator
        disableReordering
        getItemLabel={(index) => `#${index + 1}`}
        fullWidth={true}
        sx={{
          "& .RaSimpleFormIterator-line": {
            border: "none",
          },
        }}
      >
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
