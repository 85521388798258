import { useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  NumberField,
} from "react-admin";
import accountPayableCardFilterList from "../../components/accountPayableCards/filter/accountPayableCardFilterList";
import AccountPayableCardExpandList from "../../components/accountPayableCards/expand/AccountPayableCardExpandList";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5></h5>
    </div>
  );
};

const AccountPayableCardList = () => {
  const [showAside, setShowAside] = useState(false);
  const [theme] = useTheme();

  const rowClickHandler = (id, basePath, record) => {
    setShowAside((prev) => !prev);
  };

  return (
    <List
      title="mine.list_title.account_payable_card"
      empty={false}
      aside={showAside ? <Aside /> : null}
      actions={<DownloadAction />}
      filters={accountPayableCardFilterList}
      sort={{ field: "suppliers.created_at", order: "ASC" }}
      filterDefaultValues={{
        start_date: firstDate,
        end_date: lastDate,
      }}
      // queryOptions={{ meta: { sort: ["suppliers.created_at ASC"] } }} // overide default sort
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rowClick={rowClickHandler}
        expand={<AccountPayableCardExpandList />}
        isRowExpandable={(row) => !!row.company_debt_items.length}
        sx={{
          "& .RaDatagrid-expandedPanel > td": {
            p: 0,
            bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
          },
        }}
      >
        <TextField source="code" label="mine.label.supplier_code" />
        <TextField source="name" label="mine.label.supplier_name" />
        <NumberField source="initial" label="mine.label.initial_balance" />
        <NumberField source="debit" label="mine.label.debit" />
        <NumberField source="credit" label="mine.label.credit" />
        <NumberField source="final" label="mine.label.final_balance" />
      </DatagridConfigurable>
    </List>
  );
};

export default AccountPayableCardList;
