export default function errorSubmitHandler(error, notify) {
  switch (error.status) {
    case 500: // FIXME: harusnya dari server status codenya 400 atau 422
      if (error.body.errors) {
        return error.body.errors;
      }
      break;
    case 403:
      notify(error.body.message, { type: "error" });
      break;
    default:
      if (error.body.errors) {
        return error.body.errors;
      }
      break;
  }
}
