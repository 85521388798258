import Stack from "@mui/material/Stack";
import BookIcon from "@mui/icons-material/Book";
import BulkConfirmBtn from "../../confirmBtn/BulkConfirmBtn";
import { useListContext } from "react-admin";

const CloseOpenBulkAction = () => {
  const { data, selectedIds } = useListContext();

  let countOpen = 0;
  let countClose = 0;

  for (let i = 0; i < selectedIds.length; i++) {
    for (let idx = 0; idx < data.length; idx++) {
      if (selectedIds[i] === data[idx].id) {
        if (data[idx].closed_at) {
          countClose++;
        } else {
          countOpen++;
        }
      }
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      <BulkConfirmBtn
        selectedIds={selectedIds}
        title={"mine.message.open_book"}
        content="mine.message.open_book_content"
        label="mine.label.open"
        urlPath="/open-many"
        startIcon={<BookIcon />}
        hide={countOpen === 0}
        sx={{
          mx: 1,
        }}
      />
      <BulkConfirmBtn
        selectedIds={selectedIds}
        title={"mine.message.close_book"}
        content="mine.message.close_book_content"
        label="mine.label.close"
        urlPath="/close-many"
        startIcon={<BookIcon />}
        color="error"
        hide={countClose === 0}
        sx={{
          mx: 1,
        }}
      />
    </Stack>
  );
};
export default CloseOpenBulkAction;
