import { AutocompleteInput, ReferenceInput, TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const productFilterList = (permissions) => {
  const defaultFilter = [
    <TextInput
      source="all"
      label="mine.filter.search_all"
      alwaysOn
      resettable
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      alwaysOn
      source="code"
      resettable
      variant="outlined"
      label="mine.label.code"
    />,
    <TextInput
      alwaysOn
      source="name"
      resettable
      variant="outlined"
      label="mine.label.name"
    />,
    <ReferenceInput alwaysOn source="kind_id" reference="kinds">
      <AutocompleteInput
        optionText="name"
        resettable
        variant="outlined"
        label="mine.label.kind"
      />
    </ReferenceInput>,
    <ReferenceInput alwaysOn source="warehouse_id" reference="warehouses">
      <AutocompleteInput
        optionText="name"
        resettable
        variant="outlined"
        label="mine.label.warehouse"
      />
    </ReferenceInput>,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};
export default productFilterList;
