import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function BadDebtCorrectionItemForm({ setAmountSource }) {
  return (
    <ArrayInput
      source="bad_debt_correction_items"
      defaultValue={[
        {
          bad_debt_item_id: "",
          "bad_debt_item.sale.customer_debt.remain": "", // disabled
          amount: "",
        },
      ]}
    >
      <SimpleFormIterator
        disableReordering
        // getItemLabel={(index) => `#${index + 1}`}
        sx={{
          "& .RaSimpleFormIterator-form": {
            flex: 1,
          },
          "& .RaSimpleFormIterator-line": {
            borderBottom: "none",
          },
        }}
      >
        <FormDataConsumer>
          {(props) => Inputs({ ...props, setAmountSource })}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
