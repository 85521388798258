import { useEffect, useState } from "react";
import {
  Button,
  Confirm,
  useRecordContext,
  useDelete,
  useResourceContext,
  useNotify,
} from "react-admin";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteWithConfirm = ({ callback }) => {
  const record = useRecordContext();
  const resource = useResourceContext(); // cuman nama resource
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const [remove, { data, isLoading, isSuccess, error, isError }] = useDelete(
    resource,
    {
      id: record && record.id,
    }
  );

  useEffect(() => {
    if (isError) {
      notify("mine.notification." + error.message.split(" ").join("_"), {
        type: "error",
      });
    }
  }, [error, isError]);

  useEffect(() => {
    if (isSuccess) {
      notify("ra.notification.deleted", {
        messageArgs: { smart_count: data.data },
      });
    }
  }, [data, isSuccess]);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    remove();
    setOpen(false);
    callback();
  };

  return (
    <>
      <Button
        label="ra.action.delete"
        startIcon={<DeleteIcon />}
        color="error"
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="ra.message.delete_title"
        translateOptions={{ id: record && record.id, name: resource }}
        content="ra.message.delete_content"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        confirm="ra.action.confirm"
        cancel="ra.action.cancel"
      />
    </>
  );
};

export default DeleteWithConfirm;
