import { AutocompleteInput, DateInput, ReferenceInput } from "react-admin";

const incomeStatementFilterList = (permissions) => {
  const defaultFilter = [
    <DateInput
      alwaysOn
      source="start_date"
      variant="outlined"
      label="mine.label.start_date"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      //FIXME: semua DateInput tidak bisa di tambah resettable
      // resettable
      variant="outlined"
      label="mine.label.end_date"
    />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.unshift(
      // defaultFilter.splice(
      // 1,
      // 0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default incomeStatementFilterList;
