import { useRecordContext } from "react-admin";

export const OptionRendererCustomer = () => {
  const record = useRecordContext();
  return (
    <span
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>{record.name}</span>
      <span>{record.code}</span>
    </span>
  );
};
export const inputTextCustomer = (choice) => `${choice.code} - ${choice.name}`;
export const matchSuggestionCustomer = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return (
    choice.code.toLowerCase().includes(filter.toLowerCase()) ||
    choice.name.toLowerCase().includes(filter.toLowerCase())
  );
};

/*
TODO semua yg pake fungsi ini perlu ganti pake cara

filterToQuery={(searchText) => {
          if (!searchText) {
            return { all: getValues(source) };
          }
          return { all: searchText };
        }}

seperti di E:\Sugeng\react-admin\src\components\purchaseOrders\common\ProductInput.js
 */
export const filterToQueryCustomer = (searchText) => ({ all: searchText });
