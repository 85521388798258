import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";

const priceFilterList = [
  <TextInput
    variant="outlined"
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="code"
    label="mine.label.code"
    alwaysOn
    resettable
    variant="outlined"
  />,
  <ReferenceInput
    alwaysOn
    source="quality_id"
    reference="qualities"
    label="mine.label.quality"
    sort={{ field: "qualities.code", order: "ASC" }}
  >
    <AutocompleteInput
      optionText="code"
      variant="outlined"
      label="mine.label.quality"
    />
  </ReferenceInput>,
  <TextInput
    variant="outlined"
    source="buy_price"
    label="mine.label.buy_price"
    alwaysOn
    resettable
    InputProps={{
      startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
    }}
    {...parserFormatNumInput}
  />,
  <TextInput
    variant="outlined"
    source="sell_price"
    label="mine.label.sell_price"
    alwaysOn
    resettable
    InputProps={{
      startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
    }}
    {...parserFormatNumInput}
  />,
  <DateInput
    variant="outlined"
    source="start_date"
    label="mine.label.start_date"
    alwaysOn
  />,
  <DateInput
    variant="outlined"
    source="end_date"
    label="mine.label.end_date"
    alwaysOn
  />,
];

export default priceFilterList;
