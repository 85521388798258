import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  EditButton,
  DeleteWithConfirmButton,
  CreateButton,
  TopToolbar,
} from "react-admin";
import permissionFilterList from "../../components/permissions/filter/permissionFilterList";

const PermissionList = () => {
  return (
    <List
      empty={false}
      actions={
        <TopToolbar
          sx={{
            minHeight: "54px !important",
            mb: 1,
            "& .MuiButtonBase-root": {
              border: "0.1rem solid",
            },
          }}
        >
          <CreateButton variant="outlined" sx={{ py: "4px" }} />
        </TopToolbar>
      }
      // queryOptions={{ meta: { sort: ["id ASC"] } }} // overide default sort
      filters={permissionFilterList}
      sort={{ field: "casbin_rule.v1", order: "ASC" }}
      filter={{ ptype: "p" }}
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        sx={{ "& .actions-column": { width: "222px" } }}
      >
        <TextField source="v0" label="Role" />
        <TextField source="v1" label="Permission" />
        <WrapperField
          textAlign="center"
          label="Actions"
          headerClassName="actions-column"
        >
          <EditButton sx={{ mr: 1 }} />
          <DeleteWithConfirmButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export default PermissionList;
