import { useRecordContext } from "react-admin";
import Chip from "@mui/material/Chip";

function StockPendingRemain({ record }) {
  return (
    <>
      <span title="stok">stok: {record.stock}</span>
      <span style={{ margin: "0 2px" }}>|</span>
      <span title="pending">pending: {record.pending}</span>
      <span style={{ margin: "0 2px" }}>|</span>
      <span title="sisa">sisa: {record.remain}</span>
    </>
  );
}

export const OptionRendererProduct = () => {
  const record = useRecordContext();
  // const amount = record.inventories.length ? record.inventories[0].amount : 0;
  let blur = {};
  if (record.remain == 0) {
    blur = { opacity: 0.6 }; // { color: "rgba(0,0,0,.4)" };
  }
  return (
    <span
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        ...blur,
      }}
    >
      <span>{record.code}</span>
      <Chip
        label={<StockPendingRemain record={record} />}
        size="small"
        color="warning"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "11px",
        }}
      />
    </span>
  );
};
export const inputTextProduct = (choice) => {
  return choice.code;
};
export const matchSuggestionProduct = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return (
    // choice.code.toLowerCase().includes(filter.toLowerCase()) ||
    choice.code.toLowerCase().includes(filter.toLowerCase())
  );
};

/*
TODO semua yg pake fungsi ini perlu ganti pake cara

filterToQuery={(searchText) => {
          if (!searchText) {
            return { all: getValues(source) };
          }
          return { all: searchText };
        }}

seperti di E:\Sugeng\react-admin\src\components\purchaseOrders\common\ProductInput.js
 */
// export const filterToQueryProduct = (searchText) => ({ all: searchText });
