import { AutocompleteInput, ReferenceInput } from "react-admin";

const WarehouseReference = ({
  source,
  reference,
  orderBy,
  searchBy,
  ...props
}) => {
  return (
    <ReferenceInput
      source={source}
      reference={reference}
      perPage={20}
      sort={{
        field: reference + "." + orderBy,
        order: "ASC",
      }}
    >
      <AutocompleteInput
        variant="outlined"
        fullWidth
        sx={{ display: "inline-flex" }}
        optionValue="id"
        optionText="name"
        filterToQuery={(searchText) => ({ [searchBy]: searchText })}
        {...props}
      />
    </ReferenceInput>
  );
};

export default WarehouseReference;
