import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useNotify,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  NumberInput,
  useResourceContext,
  required,
  number,
  maxLength,
  choices,
  useRefresh,
  useDataProvider,
  useUpdate,
  Edit,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import ClassInput from "../common/ClassInput";
import ParentInput from "../common/ParentInput";

const validateCompany = [required()];
const validateCode = [required(), number()];
const validateName = [required(), maxLength(125)];
const validateClass = [required(), number()];
const validateNature = [
  required(),
  choices(["D", "C"], "Please choose one of the values"),
];
const validateParent = [number()];
const validateType = [
  required(),
  choices(["G", "D"], "Please choose one of the values"),
];
const validateNote = [maxLength(255)];

export default function CoAEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const resource = useResourceContext(); // cuman nama resource
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  // GET COMPANY LISTS
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companyError,
  } = useQuery(["companies/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("companies/select")
  );
  // GET COMPANY LISTS

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>Edit Chart of Account</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <SelectInput
              variant="outlined"
              source="company_id"
              isRequired
              choices={isLoadingCompanies ? [] : companies}
              fullWidth
              validate={validateCompany}
            />

            <TextInput
              variant="outlined"
              source="code"
              fullWidth
              isRequired
              validate={validateCode}
            />

            <TextInput
              variant="outlined"
              source="name"
              fullWidth
              isRequired
              validate={validateName}
            />

            <ClassInput
              variant="outlined"
              source="class"
              optionValue="code"
              fullWidth
              isRequired
              validate={validateClass}
            />

            <SelectInput
              variant="outlined"
              source="nature"
              choices={[
                { id: "D", name: "Debit" },
                { id: "C", name: "Credit" },
              ]}
              fullWidth
              isRequired
              validate={validateNature}
            />

            <ParentInput
              variant="outlined"
              source="parent"
              optionValue="code"
              fullWidth
              validate={validateParent}
            />

            <SelectInput
              variant="outlined"
              source="type"
              choices={[
                { id: "G", name: "General" },
                { id: "D", name: "Detail" },
              ]}
              fullWidth
              isRequired
              validate={validateType}
            />

            <TextInput
              variant="outlined"
              source="note"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              validate={validateNote}
            />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
