import Grid from "@mui/material/Grid";
import {
  number,
  required,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";
import OptionReferer from "../../common/OptionReferer";

const greaterThan = (attr) => (value, allValues) => {
  if (!allValues[attr] || allValues[attr] === "0001-01-01T00:00:00Z")
    return undefined;
  if (parseFloat(value) < parseFloat(allValues[attr])) {
    return "Must be greater than " + allValues[attr];
  }
  return undefined;
};
const lessThan = (attr) => (value, allValues) => {
  if (!allValues[attr] || allValues[attr] === "0001-01-01T00:00:00Z")
    return undefined;
  if (parseFloat(value) > parseFloat(allValues[attr])) {
    return "Must be less than " + allValues[attr];
  }
  return undefined;
};
const validateBuyPrice = [required(), number(), lessThan("sell_price")];
const validateSellPrice = [required(), number(), greaterThan("buy_price")];

const CstInput = ({ qualityId, buyPrice, sellPrice }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12}>
        <OptionReferer
          source={qualityId}
          reference="qualities"
          label="mine.label.quality"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextInput
          variant="outlined"
          source={buyPrice}
          label="mine.label.buy_price"
          fullWidth
          resettable
          validate={validateBuyPrice}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextInput
          variant="outlined"
          source={sellPrice}
          label="mine.label.sell_price"
          fullWidth
          resettable
          validate={validateSellPrice}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
        />
      </Grid>
    </Grid>
  );
};

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <CstInput
      {...scopedFormData}
      qualityId={getSource("quality_id")}
      buyPrice={getSource("buy_price")}
      sellPrice={getSource("sell_price")}
    />
  );
};
export default Inputs;
