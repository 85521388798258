import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BooleanField,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import DensityAddDialog from "../../components/densities/create/DensityAddDialog";
import DensityEditDialog from "../../components/densities/edit/DensityEditDialog";
import densityFilterList from "../../components/densities/filter/densityFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";

const DensityList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={densityFilterList}
        queryOptions={{ meta: { sort: ["code ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          <TextField source="name" label="mine.label.name" />
          <TextField source="code" label="mine.label.code" />
          <BooleanField
            source="active"
            label="mine.label.active"
            textAlign="center"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <DensityAddDialog open={openAdd} setOpen={setOpenAdd} />
      <DensityEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default DensityList;
