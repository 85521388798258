import { useEffect, useState } from "react";
import { AutocompleteInput, useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";

export default function ParentInput(props) {
  const [choices, setChoices] = useState([]);
  const dataProvider = useDataProvider();
  const dependOn = useWatch({ name: "class" });
  useEffect(() => {
    if (dependOn) {
      (async () => {
        const data = await dataProvider.getListNoParams(
          "coa/parent-for-select?class=" + dependOn
        );
        setChoices(data);
      })();
    }
  }, [dependOn]);
  return <AutocompleteInput choices={choices} {...props} />;
}
