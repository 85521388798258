import { memo } from "react";
import Stack from "@mui/material/Stack";
import {
  choices,
  number,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  minLength,
  maxLength,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import parserFormatNumInput from "../../../utils/parserFormatNumInput";
import {
  OptionRenderer,
  filterToQuery,
  inputText,
  matchSuggestion,
} from "../../common/OptionRenderer";

const equalOrLessThan = (value, allValues, elm) => {
  const splite = elm.source.split(".");
  const orderQty = allValues[splite[0]][+splite[1]].quantity;
  if (value > orderQty) {
    return "Must be equal or less than " + orderQty + " (order quantity)";
  }
  return undefined;
};

const greaterThanZero = (value, allValues, elm) => {
  if (value === 0) {
    return undefined;
  }
  if (value < 0) {
    return { message: "ra.validation.minValue", args: { min: 1 } };
  }
  return undefined;
};

const validateSize = [minLength(36), maxLength(36)];
const validateCapacity = [number()];
const validateCostType = [
  number(),
  choices([1, 2, 3], "Please choose one of the values"),
];

const CstInput = ({ sizeIdSource, costSource, costTypeSource, ...props }) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <ReferenceInput
        source={sizeIdSource}
        reference="sizes"
        perPage={20}
        sort={{ field: "sizes.code", order: "ASC" }}
      >
        <AutocompleteInput
          label="mine.label.size"
          variant="outlined"
          fullWidth
          autoFocus={true}
          sx={{ display: "inline-flex" }}
          validate={validateSize}
          optionValue="id"
          // optionText="code"
          optionText={<OptionRenderer />}
          inputText={inputText}
          matchSuggestion={matchSuggestion}
          filterToQuery={filterToQuery}
        />
      </ReferenceInput>
      <TextInput
        autoFocus={false}
        variant="outlined"
        source={costSource}
        label="mine.label.cost"
        fullWidth
        validate={validateCapacity}
        InputProps={{
          startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
        }}
        {...parserFormatNumInput}
        helperText="Untyuk memberi diskon isi dalam bentuk minus"
      />
      <SelectInput
        autoFocus={false}
        variant="outlined"
        source={costTypeSource}
        label="mine.label.cost_type"
        choices={[
          { id: 1, name: "Harga/Mobil" },
          { id: 2, name: "Harga/Kg" },
          { id: 3, name: "Harga/Box" },
        ]}
        fullWidth
        resettable
        validate={validateCostType}
        helperText="Saat ini yang berfungsi hanya harga/box, dan itu wajib dipilih kalau mau ada diskon 'Ambil Sendiri'"
      />
    </Stack>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.size_id === nextProps.size_id &&
    prevProps.cost === nextProps.cost &&
    prevProps.cost_type === nextProps.cost_type
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
const Beruk = memo(CstInput, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <Beruk
      {...scopedFormData}
      sizeIdSource={getSource("size_id")}
      costSource={getSource("cost")}
      costTypeSource={getSource("cost_type")}
    />
  );
};
export default Inputs;
