import { memo, useEffect } from "react";
import { TextInput, useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";

const StockPendingInput = ({
  dependOnSource,
  attr,
  optionValueProduct,
  ...props
}) => {
  const dataProvider = useDataProvider();
  const { setValue } = useFormContext();
  const dependOn = useWatch({ name: dependOnSource });
  useEffect(() => {
    if (dependOn) {
      (async () => {
        const data = await dataProvider.getListNoParams(
          "products/code-detail?" +
            optionValueProduct +
            "=" +
            encodeURIComponent(dependOn)
        );
        setValue(props.source, data[attr]);
      })();
    }
  }, [dependOn, attr]);
  return <TextInput {...props} />;
};
const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.attr === nextProps.attr &&
    prevProps.optionValueProduct === nextProps.optionValueProduct &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
export default memo(StockPendingInput, propsAreEqual);
