import { memo, useEffect } from "react";
import { NumberInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const QuantityInput = ({ quantity, formData, ...props }) => {
  const { setValue } = useFormContext();
  const me = useWatch({ name: props.source });

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < formData.mutation_repacking_items.length; i++) {
      const elm = formData.mutation_repacking_items[i];
      if (elm.quantity) {
        total += elm.price * elm.quantity;
      }
    }
    setValue("total", total);
  }, [me, formData.mutation_repacking_items.length]);

  return (
    <NumberInput
      {...props}
      inputProps={{ type: "text", inputMode: "numeric", pattern: "[0-9]*" }}
    />
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.quantity === nextProps.quantity &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(QuantityInput, propsAreEqual);
