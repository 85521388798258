// import { memo } from "react";
import Grid from "@mui/material/Grid";
import {
  AutocompleteInput,
  maxLength,
  minLength,
  number,
  ReferenceInput,
  required,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import {
  filterToQuery,
  inputText,
  matchSuggestion,
  OptionRenderer,
} from "../../../customerDebtPayments/common/CustomSelectOption";
import JournalAmount from "../../../customerDebtPayments/common/JournalAmount";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";

const validateCode = [required(), minLength(36), maxLength(36)];
const validateAmount = [required(), number()];

const JournalInputs = ({ idSource, debitSource, creditSource }) => {
  // const [trType, journalType] = useWatch({ name: ["type", typeSource] });
  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        "& .MuiGrid-item": { py: 1 },
        mt: 0,
      }}
    >
      <Grid item xs={6}>
        <ReferenceInput
          source={idSource}
          reference="coa"
          perPage={10}
          sort={{ field: "code", order: "ASC" }}
        >
          <AutocompleteInput
            label="mine.label.account"
            variant="outlined"
            fullWidth
            validate={validateCode}
            optionValue="id"
            // optionText="code"
            optionText={<OptionRenderer />}
            inputText={inputText}
            matchSuggestion={matchSuggestion}
            filterToQuery={filterToQuery}
            helperText={false}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <JournalAmount
          helperText={false}
          label="mine.label.debit"
          variant="outlined"
          source={debitSource}
          validate={validateAmount}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
          dependOn={["total", "", creditSource]}
        />
      </Grid>
      <Grid item xs={3}>
        <JournalAmount
          helperText={false}
          label="mine.label.credit"
          variant="outlined"
          source={creditSource}
          validate={validateAmount}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
          dependOn={["total", "", debitSource]}
        />
      </Grid>
    </Grid>
  );
};

// const propsAreEqual = (prevProps, nextProps) => {
//   if (
//     prevProps.debit === nextProps.debit &&
//     prevProps.credit === nextProps.credit &&
//     prevProps.code === nextProps.code
//   ) {
//     // tidak perlu render ulang
//     return true;
//   }
//   // perlu dirender ulang
//   return false;
// };
// const Beruk = memo(JournalInputs, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <JournalInputs
      {...scopedFormData}
      idSource={getSource("chart_of_account_id")}
      debitSource={getSource("debit")}
      creditSource={getSource("credit")}
    />
  );
};
export default Inputs;
