import { memo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import QuantityInput from "../../common/QuantityInput";
import {
  maxLength,
  minLength,
  minValue,
  number,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext, useWatch } from "react-hook-form";
import DependOnAutocompleteInput from "../../../common/DependOnAutocompleteInput";
import SaleDependOnCustomer from "../../common/SaleDependOnCustomer";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";
import WarehouseReference from "../../common/WarehouseReference";

const equalLessThan = (a, b, c) => {
  const gv = c.source;
  const bv = +gv.split(".")[1];
  if (a > b.sale_return_items[bv].sent_quantity) {
    return "Must be equal or less than sent_quantity";
  }
  return undefined;
};
const validateSaleOrder = [required(), minLength(36), maxLength(36)];
const validateLocation = [required(), minLength(36), maxLength(36)];
const returnQuantityValidation = [
  required(),
  number(),
  minValue(0),
  equalLessThan,
];

const CstInput = ({
  returnQuantity,
  sentQuantity,
  // NAMA-NAMA SOURCE
  saleIdSource,
  saleItemIdSource,
  returnQuantitySource,
  sentQuantitySource,
  nameSource,
  priceSource,
  locationSource,
}) => {
  const { setValue } = useFormContext();

  const dataProvider = useDataProvider();

  const dependOnVal = useWatch({ name: saleItemIdSource });
  useEffect(() => {
    if (dependOnVal === null) {
      return;
    } else if (dependOnVal) {
      (async () => {
        if (dependOnVal.length !== 36) return;
        try {
          const { motif, density, color, quality, price, sent_quantity } =
            // see: https://stackoverflow.com/a/332888
            await dataProvider.getListNoParams(
              "sale-returns/code-detail?id=" + encodeURIComponent(dependOnVal)
            );
          setValue(
            nameSource,
            motif.name +
              " " +
              density.name +
              " " +
              color.name +
              " " +
              quality.name
          );
          setValue(sentQuantitySource, sent_quantity);
          setValue(priceSource, price.sell_price);
        } catch (error) {}
      })();
    }
  }, [dependOnVal]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        // "& .MuiGrid-item:nth-child(2)": { pt: 2 },
        "& .MuiGrid-item": { py: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={2.2}>
        <SaleDependOnCustomer
          variant="outlined"
          source={saleIdSource}
          label="mine.label.sale_code"
          isRequired
          fullWidth
          validate={validateSaleOrder}
          optionText="code"
        />
      </Grid>

      <Grid item xs={12} lg={1.7}>
        <DependOnAutocompleteInput
          variant="outlined"
          source={saleItemIdSource}
          label="mine.label.sale_item"
          isRequired
          fullWidth
          optionText="code"
          validate={validateSaleOrder}
          dependOn={saleIdSource}
          queryName="sale_id"
          resource="sale-items"
          helperText={false}
        />
      </Grid>

      <Grid item xs={12} lg={2.9}>
        <TextInput
          variant="outlined"
          source={nameSource}
          label="mine.label.name"
          disabled
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1.7}>
        <TextInput
          variant="outlined"
          source={priceSource}
          label="mine.label.price"
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">/Box</InputAdornment>,
          }}
          {...parserFormatNumInput}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1.2}>
        <QuantityInput
          variant="outlined"
          source={sentQuantitySource}
          label="mine.label.sent_quantity"
          fullWidth
          disabled
          // validate={validateQuantity}
          quantity={returnQuantity}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={1.2}>
        <QuantityInput
          variant="outlined"
          source={returnQuantitySource}
          label="mine.label.return_quantity"
          fullWidth
          validate={returnQuantityValidation}
          quantity={returnQuantity}
          helperText="Bisa diisi 0 untuk SJ utama/gratis"
        />
      </Grid>
      <Grid item xs={12} lg={1}>
        <WarehouseReference
          variant="outlined"
          source={locationSource}
          label="mine.label.warehouse"
          fullWidth
          validate={validateLocation}
          reference="warehouses"
          orderBy="created_at"
          searchBy="name"
          dependOn="type"
          helperText={false}
        />
      </Grid>
    </Grid>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.formData.customer_id === nextProps.formData.customer_id &&
    prevProps.sale_id === nextProps.sale_id &&
    prevProps.sale_item_id === nextProps.sale_item_id &&
    prevProps.return_quantity === nextProps.return_quantity &&
    prevProps.warehouse_id === nextProps.warehouse_id &&
    prevProps.saleIdSource === nextProps.saleIdSource
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
const Dudupap = memo(CstInput, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <Dudupap
      {...scopedFormData}
      formData={formData}
      saleIdSource={getSource("sale_id")}
      saleItemIdSource={getSource("sale_item_id")}
      returnQuantitySource={getSource("return_quantity")}
      sentQuantitySource={getSource("sent_quantity")}
      nameSource={getSource("name")}
      priceSource={getSource("price")}
      locationSource={getSource("warehouse_id")}
    />
  );
};
export default Inputs;
