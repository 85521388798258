import { memo } from "react";
import { NumberInput } from "react-admin";

const QuantityInput = ({ quantity, ...props }) => (
  <NumberInput
    {...props}
    inputProps={{ type: "text", inputMode: "numeric", pattern: "[0-9]*" }}
  />
);

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.quantity === nextProps.quantity &&
    prevProps.source === nextProps.source
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(QuantityInput, propsAreEqual);
