import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  number,
  required,
} from "react-admin";
import {
  OptionRendererPermission,
  filterToQueryPermission,
  inputTextPermission,
  matchSuggestionPermission,
} from "../../components/common/OptionRendererPermission";

const validateRole = [required()];

const PermissionCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <ReferenceInput
          source="v0"
          reference="roles"
          perPage={20}
          sort={{ field: "casbin_rule.id", order: "ASC" }}
          // filter={{ all: "1102" }}
        >
          <AutocompleteInput
            label="mine.label.role"
            variant="outlined"
            fullWidth
            sx={{ display: "inline-flex" }}
            validate={validateRole}
            optionValue="v0"
            // optionText="code"
            optionText={<OptionRendererPermission />}
            inputText={inputTextPermission}
            matchSuggestion={matchSuggestionPermission}
            filterToQuery={filterToQueryPermission}
          />
        </ReferenceInput>
        <TextInput
          source="v1"
          label="mine.label.permission"
          variant="outlined"
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default PermissionCreate;
