import { useRecordContext } from "react-admin";

export const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.code} {" - "} {record.name}
    </span>
  );
};
export const inputText = (choice) => `${choice.code} - ${choice.name}`;
export const matchSuggestion = (filter, choice) => {
  if (choice.code === undefined) {
    return false;
  }
  return (
    choice.code.toLowerCase().includes(filter.toLowerCase()) ||
    choice.name.toLowerCase().includes(filter.toLowerCase())
  );
};

/*
TODO semua yg pake fungsi ini perlu ganti pake cara

filterToQuery={(searchText) => {
          if (!searchText) {
            return { all: getValues(source) };
          }
          return { all: searchText };
        }}

seperti di E:\Sugeng\react-admin\src\components\purchaseOrders\common\ProductInput.js
 */
export const filterToQuery = (searchText) => ({ all: searchText });
