export default function getFirstAndLastDateOfCurrentMonth(date) {
  if (!date) {
    date = new Date();
  }
  const y = date.getFullYear(),
    m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);

  const firstDateOffset = firstDay.getTimezoneOffset();
  const theFirstDate = new Date(
    firstDay.getTime() - firstDateOffset * 60 * 1000
  );

  const lastDateOffset = lastDay.getTimezoneOffset();
  const theLastDate = new Date(lastDay.getTime() - lastDateOffset * 60 * 1000);

  return [
    theFirstDate.toISOString().slice(0, 10),
    theLastDate.toISOString().slice(0, 10),
  ];
}
