import { useRecordContext } from "react-admin";

export const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <span
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{record.name}</span>
      <span>{record.code}</span>
    </span>
  );
};
export const inputText = (choice) => `${choice.code} - ${choice.name}`;
export const matchSuggestion = (filter, choice) => {
  return (
    choice.code.toLowerCase().includes(filter.toLowerCase()) ||
    choice.name.toLowerCase().includes(filter.toLowerCase())
  );
};
export const filterToQuery = (searchText) => ({ all: searchText });
