import {
  AutocompleteInput,
  ReferenceInput,
  maxLength,
  minLength,
  required,
} from "react-admin";
import {
  OptionRenderer,
  filterToQuery,
  inputText,
  matchSuggestion,
  OptionRendererKinds,
  matchSuggestionKinds,
  inputTextKinds,
} from "./OptionRenderer";

const validateBrand = [required(), minLength(36), maxLength(36)];

const OptionReferer = ({ source, reference, label, keyAttr, ...props }) => {
  return (
    <ReferenceInput
      source={source} // color_id
      reference={reference} //"colors"
      perPage={20}
      sort={{
        field: reference + "." + (keyAttr ? keyAttr : "code"),
        order: "ASC",
      }}
      // filter={{ all: "1102" }}
    >
      <AutocompleteInput
        label={label} // "mine.label.color"
        variant="outlined"
        fullWidth
        sx={{ display: "inline-flex" }}
        validate={validateBrand}
        optionValue="id"
        // optionText="code"
        optionText={
          keyAttr === "symbol" ? <OptionRendererKinds /> : <OptionRenderer />
        }
        inputText={keyAttr === "symbol" ? inputTextKinds : inputText}
        matchSuggestion={
          keyAttr === "symbol" ? matchSuggestionKinds : matchSuggestion
        }
        filterToQuery={filterToQuery}
        {...props}
      />
    </ReferenceInput>
  );
};
export default OptionReferer;
