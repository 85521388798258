import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BulkDeleteWithConfirmButton,
  usePermissions,
} from "react-admin";
import ProductAddDialog from "../../components/products/create/ProductAddDialog";
import ProductEditDialog from "../../components/products/edit/ProductEditDialog";
import productFilterList from "../../components/products/filter/productFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActionsWithUpload from "../../components/tableListActions/TableListActionsWithUpload";
import ProductUploadDialog from "../../components/products/upload/ProductUploadDialog";

const ProductList = () => {
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActionsWithUpload
            createHandler={handleClickOpenAdd}
            uploadHandler={handleClickOpenUpload}
            exporter={false}
          />
        }
        filters={productFilterList(permissions)}
        queryOptions={{ meta: { sort: ["code ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.tbl_header.company" />
          ) : null}
          <TextField source="code" label="mine.tbl_header.code" />
          <TextField source="name" label="mine.tbl_header.name" />
          <TextField source="kind.name" label="mine.tbl_header.kind" />
          <TextField
            source="warehouse.name"
            label="mine.tbl_header.warehouse"
          />
          <NumberField source="total_in" label="mine.tbl_header.total_in" />
          <NumberField source="total_out" label="mine.tbl_header.total_out" />
          <NumberField source="stock" label="mine.tbl_header.stock" />
          {/* <WrapperField textAlign="right" label="mine.tbl_header.stock">
            <WithRecord
              render={(record) => {
                let total = { amount: 0 };
                for (let i = 0; i < record.inventories.length; i++) {
                  const obj = record.inventories[i];
                  total.amount += obj.amount;
                }
                return (
                  <NumberField
                    sx={{ width: "100%" }}
                    record={total}
                    source="amount"
                  />
                );
              }}
            />
          </WrapperField> */}
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <ProductAddDialog open={openAdd} setOpen={setOpenAdd} />
      <ProductEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      {openUpload ? (
        <ProductUploadDialog open={openUpload} setOpen={setOpenUpload} />
      ) : null}
    </>
  );
};

export default ProductList;
