import React from "react";
import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
  TextField,
} from "react-admin";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const AllTransactionJournalExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
      <Card sx={{ m: 3 }}>
        {/* <CardContent> */}
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.tbl_header.coa")}</th>
                <th>{t("mine.tbl_header.coa_name")}</th>
                <th>{t("mine.tbl_header.description")}</th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.debit")}
                </th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.credit")}
                </th>
              </tr>
            </thead>
            <tbody>
              {record.transactions.map((item, idx) => {
                return (
                  <tr key={item.id + idx}>
                    <td>{item.coa.code}</td>
                    <td>{item.coa.name}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="debit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="credit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        {/* </CardContent> */}
      </Card>
      
  );
};

export default AllTransactionJournalExpandList;
