import { memo } from "react";
import { TextInput } from "react-admin";

const FaxInput = ({ fax, ...props }) => <TextInput {...props} />;

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.fax === nextProps.fax &&
    prevProps.source === nextProps.source
    ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(FaxInput, propsAreEqual);
