import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {
  useUpdate,
  useNotify,
  SimpleForm,
  Edit,
  useResourceContext,
  required,
  number,
  useRefresh,
  TextInput,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import sanitizeData from "../../../utils/sanitizeData";

const validateNumber = [required(), number()];

export default function CustomerDebtMaxDayEditDialog({
  open,
  setOpen,
  recordId,
}) {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const translate = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: sanitizeData(values) },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          if (error.body.errors === "403") {
            // munculkan notifikasi erro ketika sudah approved
            notify("mine.notification.403_error", { type: "error" });
          } else {
            // The shape of the returned validation errors must match the shape of the form
            return error.body.errors;
          }
        } else if (error.body.message) {
          notify(error.body.message, { type: "error" });
        }
      }
    },
    [update]
  );

  const handleClose = (e, reason) => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>
              {translate("mine.dialog.edit_customer_debt_max_day")}
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <TextInput
              variant="outlined"
              source="total_day"
              label="mine.label.total_day"
              fullWidth
              validate={validateNumber}
              helperText="Jika sudah melewati total hari, SJ akan muncul di form Bad Debt untuk dipilih"
            />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
