import { choices, number, RadioButtonGroupInput, required } from "react-admin";

const validateType = [
  required(),
  number(),
  choices([1, 2], "Please choose one of the values"),
];

const RadioGroupX = ({ nextNumber }) => {
  return (
    <RadioButtonGroupInput
      source="type"
      parse={(value) => (value === "" ? null : +value)}
      // format={(value) => (value == null ? "" : +value)}
      defaultValue={1}
      choices={[
        { id: 1, name: "mine.radio_btn.return_warehouse" },
        { id: 2, name: "mine.radio_btn.return_broken" },
      ]}
      validate={validateType}
    />
  );
};

export default RadioGroupX;
