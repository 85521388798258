import Grid from "@mui/material/Grid";
import {
  maxLength,
  minLength,
  number,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import { toCurrency } from "../../../../utils/formatter";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext } from "react-hook-form";
import DependOnAutocompleteInput from "../../../common/DependOnAutocompleteInput";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";

const equalOrLessThanDebt = (value, allValues, elm) => {
  const splite = elm.source.split(".");
  const debt = allValues[splite[0]][+splite[1]].debt;
  if (+value > +debt) {
    return (
      "Must be equal or less than " + toCurrency(false).format(debt) + " (debt)"
    );
  }
  return undefined;
};
const equalOrLessThanTotal = (value, allValues, elm) => {
  let remain = allValues.total;
  let count = 0;
  const splite = elm.source.split(".");
  let skipProcessLast = 0;
  for (let i = 0; i < allValues.bad_debt_installment_items.length; i++) {
    const elm = allValues.bad_debt_installment_items[i];
    if (+splite[1] === i) {
      skipProcessLast = elm.amount;
      continue;
    }
    count += +elm.amount;
    if (+count > +allValues.total) {
      return (
        "Must be equal or less than " +
        toCurrency(false).format(remain) +
        " (remaining of total payment)"
      );
    }
    remain -= +elm.amount;
  }
  // kalau yg di validasi index ke 0 sdangkan index sdh ada smpai 5
  // maka skip index 0 dptkan dulu sum total pay, lalu tambahkan dgn
  // pay index 0
  count += +skipProcessLast;
  if (+count > +allValues.total) {
    return (
      "Must be equal or less than " +
      toCurrency(false).format(remain) +
      " (remaining of total payment)"
    );
  }
  return undefined;
};

const validateCode = [required(), minLength(36), maxLength(36)];
const validateAmount = [
  required(),
  number(),
  equalOrLessThanDebt,
  equalOrLessThanTotal,
];

const CstInput = ({
  // NAMA-NAMA SOURCE
  badDebtItemIdSource,
  debtSource,
  amountSource,
}) => {
  const { setValue } = useFormContext();

  const dataProvider = useDataProvider();

  const onCodeChangeHandler = async (id) => {
    if (id.length !== 36) return;
    try {
      const { sale } =
        // see: https://stackoverflow.com/a/332888
        await dataProvider.getListNoParams(
          "bad-debt-items/" + encodeURIComponent(id)
        );
      setValue(debtSource, sale.customer_debt.remain);
    } catch (error) {}
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        "& .MuiGrid-item": { py: 1 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={6}>
        <DependOnAutocompleteInput
          variant="outlined"
          source={badDebtItemIdSource}
          label="mine.label.sale_code"
          fullWidth
          validate={validateCode}
          optionValue="id"
          optionText="sale.code"
          dependOn="bad_debt_installment_items[0].bad_debt_item.bad_debt_id"
          queryName="bad_debt_id"
          resource="bad-debt-items/"
          onChange={onCodeChangeHandler}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextInput
          variant="outlined"
          source={debtSource}
          label="mine.label.remaining_debt"
          helperText={false}
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextInput
          variant="outlined"
          source={amountSource}
          label="mine.label.payment"
          helperText={false}
          fullWidth
          validate={validateAmount}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
        />
      </Grid>
    </Grid>
  );
};

// const propsAreEqual = (prevProps, nextProps) => {
//   if (
//     prevProps.quantity === nextProps.quantity &&
//     prevProps.code === nextProps.code
//   ) {
//     // tidak perlu render ulang
//     return true;
//   }
//   // perlu dirender ulang
//   return false;
// };
// const Beruk = memo(CstInput, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <CstInput
      {...scopedFormData}
      badDebtItemIdSource={getSource("bad_debt_item_id")}
      debtSource={getSource("bad_debt_item.sale.customer_debt.remain")}
      amountSource={getSource("amount")}
    />
  );
};
export default Inputs;
